import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Clear, Search } from '@material-ui/icons';
import { components } from 'react-select';

function inputComponent({ inputRef, ...props }) {
  return (
    <div
      ref={inputRef}
      {...props}
      style={{
        paddingTop: 3,
        paddingBottom: 3,
        minHeight: 31,
        display: 'flex',
        flexWrap: 'nowrap',
        textIndent: 0,
      }}
    />
  );
}

const styles = {
  Option: (props) => (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  ),
  Placeholder: (props) => (
    <Typography
      color="inherit"
      component="div"
      style={{
        position: 'absolute',
        left: 10,
        fontSize: 15.4,
      }}
    >
      {props.children}
    </Typography>
  ),
  Menu: (props) => {
    const header = Boolean(
      props.options.length && props.selectProps.menuHeader,
    );
    return (
      <Paper
        square
        {...props.innerProps}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          marginTop: -8,
          zIndex: 10001,
        }}
      >
        {header && props.selectProps.menuHeader}
        {props.children}
      </Paper>
    );
  },
  SingleValue: (props) => (
    <Typography
      noWrap
      component="div"
      style={{ fontSize: 15.4, maxWidth: 'calc(100% - 10px)' }}
    >
      {props.children}
    </Typography>
  ),
  Control: (props) => (
    <TextField
      fullWidth
      margin={props.selectProps.textFieldProps.required ? 'normal' : 'dense'}
      InputProps={{
        startAdornment: (
          <Search
            fontSize="small"
            style={{
              position: 'relative',
              left: 6,
              opacity: 0.5,
            }}
          />
        ),
        inputComponent: inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  ),
  DropdownIndicator: () => null,
  ClearIndicator: (props) => (
    <components.ClearIndicator {...props}>
      <Clear
        fontSize="small"
        style={{
          cursor: 'pointer',
          color: 'white',
        }}
      />
    </components.ClearIndicator>
  ),
  IndicatorSeparator: () => null,
};

export const selectStyles = {
  input: (base) => ({
    ...base,
    color: 'white',
    '& input': {
      fontFamily: '"IBM Plex Sans", "Roboto"',
    },
  }),
  noOptionsMessage: (base) => ({
    ...base,
    color: 'white',
    fontSize: 14,
    fontFamily: '"IBM Plex Sans", "Roboto"',
  }),
  loadingMessage: (base) => ({
    ...base,
    color: 'white',
    fontSize: 14,
    fontFamily: '"IBM Plex Sans", "Roboto"',
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: '3px 8px',
  }),
  loadingIndicator: (base) => ({
    ...base,
    '& > span': {
      color: 'white',
      backgroundColor: 'white',
    },
  }),
};

export default styles;
