import { flatten, isEmpty, map, find } from 'lodash';
import { computed, observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import ApiManagement from '../pages/api-management/api-management';
import Compliance from '../pages/compliance/compliance';
import DeskManagement from '../pages/desk-management/desk-management';
import BitcomGroup from '../pages/product-management/bitcom-group';
import BybitGroup from '../pages/product-management/bybit-group';
import RibbonGroup from '../pages/product-management/ribbon-group';
import ThetanutsGroup from '../pages/product-management/thetanuts-group';
import OptionsDeribit from '../pages/product-management/options-deribit';
import DerivativesSettings from '../pages/settings/derivatives-settings';

import Login from '../pages/login/login';

export const DEPRECATED_STATS_ROUTE = '/derivatives-stats';

const PRODUCTS = [
  {
    title: 'Deribit',
    venue: 'DBT',
    authGuard: true,
    Component: OptionsDeribit,
    routeProps: {
      path: '/options-deribit',
      exact: true,
    },
  },
  {
    title: 'Bit.com',
    venue: 'BIT',
    authGuard: true,
    Component: BitcomGroup,
    routeProps: {
      path: '/bitcom-group',
      exact: true,
    },
  },
  {
    title: 'Bybit',
    venue: 'BYB',
    authGuard: true,
    Component: BybitGroup,
    routeProps: {
      path: '/bybit-group',
      exact: true,
    },
  },
  {
    title: 'Ribbon',
    venue: 'RBN',
    authGuard: true,
    Component: RibbonGroup,
    routeProps: {
      path: '/ribbon-group',
      exact: true,
    },
  },
  {
    title: 'Thetanuts',
    venue: 'TTN',
    authGuard: true,
    Component: ThetanutsGroup,
    routeProps: {
      path: '/thetanuts-group',
      exact: true,
    },
  },
];

const SETTINGS = [
  {
    title: 'Derivatives',
    authGuard: true,
    Component: DerivativesSettings,
    routeProps: {
      path: '/derivatives-settings',
      exact: true,
    },
  },
];

class Routes {
  asyncRoutes = ['/api', '/options-deribit', '/bitcom-group'];
  fetched = false;
  list = [
    {
      title: 'Login',
      Component: Login,
      authGuard: false,
      routeProps: {
        path: '/',
        exact: true,
      },
    },
    {
      title: 'Desk Management',
      Component: DeskManagement,
      authGuard: true,
      routeProps: {
        path: '/desk-management',
        exact: true,
      },
    },
    {
      title: 'API',
      Component: ApiManagement,
      authGuard: true,
      routeProps: {
        path: '/api',
        exact: true,
      },
    },
    {
      title: 'Compliance',
      Component: Compliance,
      authGuard: true,
      routeProps: {
        path: '/compliance',
        exact: true,
      },
    },
    {
      title: 'Derivatives (Listed)',
      authGuard: true,
      items: [],
    },
    {
      title: 'Settings',
      authGuard: true,
      items: SETTINGS,
    },
  ];

  setProductsSubRoutes = (apiProducts) => {
    this.products.push(
      ...PRODUCTS.filter((item) =>
        find(apiProducts.products, { venue: item.venue }),
      ),
    );
    this.fetched = true;
  };

  constructor() {
    makeObservable(this, {
      fetched: observable,
      list: observable,
      setProductsSubRoutes: action,
      guarded: computed,
      notGuarded: computed,
      productsRoute: computed,
      products: computed,
      flattenedRoutes: computed,
      reset: action,
    });
  }

  get guarded() {
    return this.list.filter(
      (i) => i.authGuard && (!i.items || (i.items && !isEmpty(i.items))),
    );
  }

  get notGuarded() {
    return this.list.filter((i) => !i.authGuard);
  }

  get productsRoute() {
    return this.list.find((i) => i.title.startsWith('Derivatives'));
  }

  get products() {
    return this.productsRoute.items;
  }

  get flattenedRoutes() {
    return flatten(map(this.list, (route) => route.items || route));
  }

  reset = () => {
    this.products.splice(0);
    this.fetched = false;
  };
}

export const routes = new Routes();
window.routes = routes;

export default createContext(routes);
