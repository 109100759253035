const palette = {
  primary: {
    main: '#7fabec',
    contrastText: '#fff',
  },
  secondary: {
    main: '#7ed321',
    contrastText: '#fff',
  },
  error: {
    main: '#ff2d55',
    contrastText: '#fff',
  },
  warning: {
    main: '#ffc107',
    contrastText: '#fff',
  },
  background: {
    default: '#071527',
    paper: '#182332',
    paperContrast: 'rgba(255, 255, 255, 0.05)',
  },
  text: {
    primary: '#fff',
    secondary: 'rgba(255, 255, 255, 0.5)',
    dark: 'rgba(255, 255, 255, 0.25)',
  },
};

export default palette;
