import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { MoreVert, ReportProblemOutlined } from '@material-ui/icons';
import ParadigmConfirmModal from '../../../material/confirmation-modal';
import ParadigmMenu from '../../../material/menu';
import entitiesContext from '../../../stores/entities-store';

const TableAction = ({ source, setEditingEntity }) => {
  const [anchor, setAnchor] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState(false);
  const { removeEntity } = React.useContext(entitiesContext);

  const openMenu = ({ currentTarget }) => setAnchor(currentTarget);
  const closeMenu = () => setAnchor(null);

  return (
    <>
      <IconButton align="right" color="inherit" onClick={openMenu}>
        <MoreVert fontSize="small" />
      </IconButton>
      <ParadigmMenu
        options={[
          {
            title: 'Edit',
            onClick: () => setEditingEntity(source),
          },
          {
            title: 'Remove',
            onClick: () => setConfirmation(true),
          },
        ]}
        anchor={anchor}
        onClose={closeMenu}
      />
      {confirmation && (
        <ParadigmConfirmModal
          icon={<ReportProblemOutlined color="error" />}
          title="Removing Entity"
          message={`Removing entity "${source.name}"`}
          onReject={() => setConfirmation(false)}
          onConfirm={() => removeEntity(source)}
        />
      )}
    </>
  );
};

export default TableAction;
