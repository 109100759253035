import Grid from '@material-ui/core/Grid';
import React from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { Field, Form, ErrorMessage as BaseErrorMessage } from 'formik';
import ParadigmButton from '../../../../material/button';
import ParadigmInput from '../../../../material/input';
import { ModalActionsWrapper } from '../../../../material/modal';
import ParadigmSelect from '../../../../material/select';
import COUNTRIES, { COUNTRY_LABEL_MAP } from '../../../../resources/countries';
import authContext from '#/stores/auth-store';

const EditEntityForm = ({ isSubmitting, onClose, values, dirty, ...rest }) => {
  const auth = React.useContext(authContext);

  return (
    <Form noValidate data-testid="entity-form">
      <Box>
        <Field
          name="name"
          label={auth.isBusiness ? 'Name' : 'Full Name'}
          component={ParadigmInput}
          placeholder={
            auth.isBusiness ? 'Enter entity name' : 'Enter full name'
          }
          autoFocus
          required
        />

        <Field
          name="mailing_address.country"
          label="Country"
          value={COUNTRY_LABEL_MAP[values.mailing_address?.country]}
          onChange={(data) =>
            rest.setFieldValue('mailing_address.country', data?.value)
          }
          component={ParadigmSelect}
          data={COUNTRIES}
          required
          aria-label="Country"
        />
        <ErrorMessage name="mailing_address.country" />

        <Field
          name="mailing_address.address1"
          label="Address 1"
          component={ParadigmInput}
          placeholder="71 Robinson Road"
          required
        />
        <ErrorMessage name="mailing_address.address1" />

        <Field
          name="mailing_address.address2"
          label="Address 2"
          component={ParadigmInput}
          placeholder="#15-119"
        />
        <ErrorMessage name="mailing_address.address2" />

        <Field
          name="mailing_address.city"
          label="City"
          component={ParadigmInput}
          required
          placeholder="Singapore"
        />
        <ErrorMessage name="mailing_address.city" />

        <Grid container spacing={2}>
          <Grid item xs>
            <Field
              name="mailing_address.province"
              label="State/Province/Region"
              component={ParadigmInput}
              placeholder="SG"
              required
            />
            <ErrorMessage name="mailing_address.province" />
          </Grid>
          <Grid item xs>
            <Field
              name="mailing_address.zip_code"
              label="ZIP/Postal Code"
              component={ParadigmInput}
              placeholder="068895"
              required
            />
            <ErrorMessage name="mailing_address.zip_code" />
          </Grid>
        </Grid>
      </Box>
      <ModalActionsWrapper>
        <ParadigmButton
          type="button"
          secondary
          text="Cancel"
          onClick={onClose}
        />
        <ParadigmButton
          type="submit"
          busy={isSubmitting}
          disabled={!dirty}
          text="Save"
        />
      </ModalActionsWrapper>
    </Form>
  );
};

const ErrorMessage = styled(BaseErrorMessage).attrs({ component: 'span' })`
  color: #ea135e;
  font-size: 12px;
  font-weight: 500;
`;

export default EditEntityForm;
