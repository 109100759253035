import * as PropTypes from 'prop-types';
import React from 'react';
import SidebarItem from './sidebar-item';

const SidebarList = (props) =>
  props.list.map((item) => (
    <SidebarItem item={item} key={item.title} toggle={props.toggle} />
  ));

SidebarList.propTypes = {
  list: PropTypes.array,
  toggle: PropTypes.func,
};

export default SidebarList;
