import React from 'react';
import { Observer } from 'mobx-react-lite';
import { ErrorMessage, Field } from 'formik';
import ErrorMessageRender from '#/components/ErrorMessage';

import ParadigmInput from '../../../../material/input';
import { useEntities } from '../../account/components/add-keys/use-entities';

const AddKeyFormFields = ({ source }) => {
  const { entityOptions } = useEntities(source);

  return (
    <Observer>
      {() => (
        <>
          <Field
            name="name"
            label="Name"
            component={ParadigmInput}
            placeholder="Name"
            required
            autoFocus
          />
          <ErrorMessage
            name="name"
            render={(message) => <ErrorMessageRender message={message} />}
          />

          <Field
            name="entity"
            label="Entity"
            placeholder="Entity"
            component={ParadigmInput}
            select
            required
          >
            {entityOptions}
          </Field>
          <ErrorMessage
            name="entity"
            render={(message) => <ErrorMessageRender message={message} />}
          />
          <Field
            name="access_key"
            label="API Key"
            component={ParadigmInput}
            required
            placeholder="Enter you API key"
          />
          <ErrorMessage
            name="access_key"
            render={(message) => <ErrorMessageRender message={message} />}
          />
          <Field
            name="access_secret"
            label="API Secret"
            component={ParadigmInput}
            required
            placeholder="Enter you API secret"
          />
          <ErrorMessage
            name="access_secret"
            render={(message) => <ErrorMessageRender message={message} />}
          />
        </>
      )}
    </Observer>
  );
};

export default AddKeyFormFields;
