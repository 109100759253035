import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { observer, Observer } from 'mobx-react-lite';
import PageSubTitle from '../../../components/page-sub-title/page-sub-title';
import ParadigmInput from '../../../material/input';
import { Spot } from '../../../stores/spot-store';
import EditManualSettings from './components/edit-manual-settings/edit-manual-settings';
import { map, filter } from 'lodash';
import PairSize from './components/pair-size/pair-size';
import authContext from '../../../stores/auth-store';

const useStyles = makeStyles({
  left: {
    width: 180,
  },
});

export function filterEnabledProducts(configs) {
  return filter(configs, 'isEnabled');
}

const Rfq = (props) => {
  const { source } = props;

  let maxSize = 'Max Size';
  let editTooltip = 'Max size will be capped at the lower notional value';
  let maxSizeError = 'Max base size is required';
  if (!(source instanceof Spot)) {
    maxSize += ' (Leg 1)';
    editTooltip = 'Max Size set in terms of Leg 1 (Base Leg)';
    maxSizeError = 'Max size for Base Leg (Leg 1) cannot be bank';
  }

  const classes = useStyles(props);
  const auth = React.useContext(authContext);
  const [pair, setPair] = React.useState(source.defaultPair);

  React.useEffect(() => {
    if (source.defaultPair && source.defaultPair.id) {
      setPair(source.defaultPair);
    }
  }, [source.defaultPair]);

  return (
    <Observer>
      {() => (
        <Grid container alignItems="center" spacing={4} data-testid="rfq-page">
          <Grid item container direction="column" spacing={3}>
            <Grid item container alignItems="center">
              <Grid item className={classes.left}>
                <PageSubTitle text="RFQ SETTINGS" />
              </Grid>
            </Grid>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item className={classes.left}>
                <Typography color="textSecondary">Product</Typography>
              </Grid>
              <Grid item>
                <ParadigmInput
                  select
                  value={pair.id}
                  onChange={({ target: { value } }) =>
                    setPair(source.rfq[value])
                  }
                  placeholder="Loading..."
                  disabled={!source.ready}
                  style={{ minWidth: 283 }}
                  margin="none"
                >
                  {map(filterEnabledProducts(source.rfq), (product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.pair}
                    </MenuItem>
                  ))}
                </ParadigmInput>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
          <Grid item container direction="column" spacing={3}>
            <Grid item container alignItems="center">
              <Grid item className={classes.left}>
                <PageSubTitle text="MANUAL RFQ" />
              </Grid>
              {auth.canManageProduct && (
                <Grid item xs>
                  <EditManualSettings
                    pair={pair}
                    tooltip={editTooltip}
                    maxSizeError={maxSizeError}
                  >
                    <Link
                      underline="none"
                      variant="body1"
                      color="textSecondary"
                    >
                      Edit
                    </Link>
                  </EditManualSettings>
                </Grid>
              )}
            </Grid>
            <Grid item container alignItems="center">
              <Grid item className={classes.left}>
                <Typography color="textSecondary">{maxSize}</Typography>
              </Grid>
              <Grid item xs>
                <PairSize
                  baseSize={pair.formattedBaseSize}
                  quoteSize={pair.formattedQuoteSize}
                />
              </Grid>
            </Grid>
            {pair.hasPriceBand && (
              <>
                <Grid item container alignItems="center">
                  <Grid item className={classes.left}>
                    <Typography>
                      Taker<span style={{ opacity: 0.5 }}> Price Band</span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography>
                      {pair.formattedTakerPriceBand}{' '}
                      {pair.priceBandTakerTotal && (
                        <span style={{ opacity: 0.5 }}>
                          ({pair.priceBandTakerTotal})
                        </span>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item className={classes.left}>
                    <Typography>
                      Maker<span style={{ opacity: 0.5 }}> Price Band</span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography>
                      {pair.formattedMakerPriceBand}{' '}
                      {pair.priceBandMakerTotal && (
                        <span style={{ opacity: 0.5 }}>
                          ({pair.priceBandMakerTotal})
                        </span>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item container alignItems="center">
              <Grid item className={classes.left}>
                <Typography color="textSecondary">Order Expires In</Typography>
              </Grid>
              <Grid item xs>
                <Typography>{pair.executionWindow} Seconds</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
      )}
    </Observer>
  );
};

export default observer(Rfq);
