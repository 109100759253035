import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Observer } from 'mobx-react-lite';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableAction from './table-action';
import authContext from '../../../stores/auth-store';
import entityContext from '../../../stores/entities-store';
import { COUNTRY_LABEL_MAP } from '../../../resources/countries';
import EditEntity from '#/pages/desk-management/components/edit-entities/edit-entity';
import ParadigmButton from '#/material/button';

const useStyles = makeStyles({
  entities: {
    textTransform: 'capitalize',
  },
  verified: {
    color: '#89c900',
  },
});

const EntitiesTable = () => {
  const entityStyles = useStyles();
  const entityStore = React.useContext(entityContext);
  const auth = React.useContext(authContext);
  const [editingEntity, setEditingEntity] = React.useState(null);
  return (
    <Observer>
      {() => (
        <Table data-testid="entities-table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '60%' }} align="left">
                Name
              </TableCell>
              <TableCell style={{ width: '30%', minWidth: 150 }} align="left">
                Address
              </TableCell>
              <TableCell style={{ width: '10%', minWidth: 150 }} align="left">
                Compliance
              </TableCell>
              {auth.canManageTradingDesk && (
                <TableCell align="right" style={{ minWidth: 106 }} />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {entityStore.entitiesArray.map((entity) => (
              <TableRow hover key={entity.id} data-testid="entity-row">
                <TableCell component="th" scope="row" data-testid="entity-name">
                  {entity.name}
                </TableCell>
                <TableCell align="left">
                  <Grid
                    item
                    xs
                    container
                    spacing={2}
                    justifyContent="space-between"
                  >
                    {entity.mailing_address && (
                      <Grid item data-testid="address-container">
                        {entity.mailing_address.address1}{' '}
                        {entity.mailing_address.address2},{' '}
                        {entity.mailing_address.city},{' '}
                        {entity.mailing_address.province}{' '}
                        {entity.mailing_address.zip_code},{' '}
                        {COUNTRY_LABEL_MAP[entity.mailing_address.country]
                          ?.label ?? 'Unknown'}
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  data-testid="entity-status"
                >
                  {entity.status === 'PENDING' ? (
                    <ParadigmButton
                      text="Verify"
                      onClick={() => setEditingEntity(entity)}
                    />
                  ) : entity.status === 'UPDATE' ? (
                    <span css="color: #F1E20D">Processing</span>
                  ) : (
                    <span
                      className={`${entityStyles.entities} ${
                        entityStyles[entity.status?.toLowerCase()]
                      }`}
                    >
                      {entity.status?.toLowerCase()}
                    </span>
                  )}
                </TableCell>
                {auth.canManageTradingDesk && entity.status === 'VERIFIED' && (
                  <TableCell align="right" data-testid="m-action">
                    <TableAction
                      source={entity}
                      setEditingEntity={setEditingEntity}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
            {editingEntity && (
              <EditEntity
                source={editingEntity}
                onClose={() => setEditingEntity(null)}
              />
            )}
          </TableBody>
        </Table>
      )}
    </Observer>
  );
};

export default EntitiesTable;
