import React from 'react';
import * as PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import SidebarLink from './sidebar-link';

const SidebarItem = ({ item, toggle }) => {
  const [expanded, setExpanded] = React.useState(true);

  const handleItemClick = React.useCallback(
    (item) => {
      if (item.items) {
        setExpanded((expanded) => !expanded);
      } else {
        toggle && toggle();
      }
    },
    [toggle],
  );

  return (
    <>
      <SidebarLink item={item} onClick={handleItemClick} expanded={expanded} />
      {item.items && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.items.map((subItem, i) => (
              <SidebarLink
                item={subItem}
                key={i}
                subItem
                onClick={handleItemClick}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    routeProps: PropTypes.shape({
      path: PropTypes.string.isRequired,
      exact: PropTypes.bool,
    }),
    expanded: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        routeProps: PropTypes.shape({
          path: PropTypes.string.isRequired,
          exact: PropTypes.bool,
        }).isRequired,
      }),
    ),
  }),
  toggle: PropTypes.func,
};

export default SidebarItem;
