export default function TelegramIcon() {
  return (
    <svg
      width="19px"
      height="16px"
      viewBox="0 0 19 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="telegram-icon-help"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="dashboard-/-help"
          transform="translate(-1258.000000, -30.000000)"
          fillRule="nonzero"
          stroke="#7FABEC"
        >
          <g id="icon-/-Help">
            <path d="M1260.16866,37.1924832 C1264.73205,35.1624821 1267.77503,33.8241811 1269.29759,33.1775802 C1273.64481,31.3314033 1274.54813,31.0107026 1275.1369,31 C1275.26639,30.9977838 1275.55593,31.0305511 1275.74348,31.1859358 C1275.90184,31.3171395 1275.94542,31.4943772 1275.96627,31.6187727 C1275.98712,31.7431683 1276.01308,32.0265446 1275.99244,32.2479666 C1275.75687,34.7752419 1274.73753,40.9082725 1274.21894,43.7388641 C1273.99951,44.9365935 1273.56745,45.3381861 1273.14915,45.3774872 C1272.24011,45.4628977 1271.54982,44.7640962 1270.66937,44.1748129 C1269.29163,43.2527008 1268.5133,42.6786832 1267.17597,41.7788722 C1265.63045,40.7389849 1266.63235,40.1674448 1267.51313,39.2333882 C1267.74364,38.9889412 1271.74891,35.2692413 1271.82643,34.9318062 C1271.83612,34.8896043 1271.84512,34.7322956 1271.75359,34.6492309 C1271.66206,34.5661661 1271.52697,34.594571 1271.42949,34.6171617 C1271.29131,34.6491833 1269.09035,36.1345173 1264.82663,39.0731636 C1264.2019,39.5111731 1263.63604,39.7245859 1263.12904,39.7134023 C1262.57012,39.7010733 1261.49498,39.3907356 1260.69573,39.1254667 C1259.71541,38.8001036 1258.93627,38.6280817 1259.00412,38.0755145 C1259.03946,37.7877033 1259.42764,37.4933595 1260.16866,37.1924832 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
