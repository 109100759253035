function useFormProps({ field, form, required }) {
  if (!form) return { required };
  const error = form.errors[field.name];
  const showError = Boolean(form && form.submitCount && error);

  return {
    error: showError, // paint label to error color
    required, // shows label asterisk
    helperText: showError && error, // display error message
    id: field.name, // binds label and input together
    name: field.name, // binds label and input together
  };
}

export default useFormProps;
