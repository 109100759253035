const setToken = (token) => {
  token && localStorage.setItem('admin_token', token);
};

const removeToken = () => {
  localStorage.removeItem('admin_token');
};

const getToken = () => localStorage.getItem('admin_token');

export { setToken, removeToken, getToken };
