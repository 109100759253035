import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router';
import RFQ from './rfq/rfq';
import DBTAccount from './market-integrity-account/account';
import deribitContext from '../../stores/deribit-store';
import { useTabQueryString } from '../../hooks/use-tab-query-string';

const useStyles = makeStyles({
  tabs: {
    paddingBottom: 20,
  },
  tab: {
    width: 220,
  },
});

const OptionsDeribit = ({ location, history }) => {
  const classes = useStyles();
  const deribit = React.useContext(deribitContext);
  const { tab, setTab } = useTabQueryString('options');

  React.useEffect(() => {
    deribit.fetch();
  }, [deribit]);

  return (
    <div data-testid="options-deribit">
      <Tabs
        value={tab}
        onChange={(_, selectedTab) => setTab(selectedTab)}
        className={classes.tabs}
      >
        <Tab value="options" className={classes.tab} label="RFQ" />
        <Tab value="account" className={classes.tab} label="Account" />
      </Tabs>
      {tab === 'options' && <RFQ source={deribit} />}
      {tab === 'account' && <DBTAccount source={deribit} />}
    </div>
  );
};

export default withRouter(OptionsDeribit);
