import { observable, computed, toJS, makeObservable } from 'mobx';

export default class ApiCredential {
  id;
  name;
  accessKey;
  isEnabled;
  allowed_cidrs;
  readOnly;

  constructor(key) {
    makeObservable(this, {
      id: observable,
      name: observable,
      accessKey: observable,
      isEnabled: observable,
      allowed_cidrs: observable,
      formattedAccessKey: computed,
      getAllowedCidrs: computed,
      readOnly: observable,
    });

    this.id = key.id;
    this.name = key.label;
    this.accessKey = key.access_key;
    this.isEnabled = key.is_enabled;
    this.prefix = key.prefix;
    this.allowed_cidrs = key.allowed_cidrs;
    this.readOnly = key.read_only;
  }

  get formattedAccessKey() {
    return `${this.prefix}•••••••••••`;
  }
  get getAllowedCidrs() {
    return toJS(this.allowed_cidrs);
  }
}
