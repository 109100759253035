import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import warningSvg from '@paradigm/design-system/src/assets/icon-warning-yellow.svg';

const useStyles = makeStyles(() => ({
  msg: { fontSize: '12px' },
  error: {
    border: '1px solid #F3E500',
    borderRadius: '7px',
    display: 'flex',
  },
  img: {
    height: '13px',
    width: '13px',
    margin: '14px 8px 0 12px',
  },
}));

const ErrorMessageRender = ({ message }) => {
  const classes = useStyles({});

  return (
    <div className={classes.error}>
      <img className={classes.img} src={warningSvg} alt="" />
      <p className={classes.msg}>{message}</p>
    </div>
  );
};

export default ErrorMessageRender;
