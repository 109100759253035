import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import * as Yup from 'yup';
import { logInApi } from '../../api/auth-api';
import authContext from '../../stores/auth-store';
import LoginForm from './login-form';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  wrapper: {
    width: 400,
    position: 'relative',
  },
  error: {
    textAlign: 'center',
    position: 'relative',
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const Login = (props) => {
  const auth = React.useContext(authContext);
  const classes = useStyles(props);

  const login = async (values, actions) => {
    auth.startAuth();
    try {
      const { token, user } = await logInApi(values);
      auth.successAuth(user, token);
    } catch (e) {
      actions.setSubmitting(false);
      if (e[0] === 'Two Factor Authentication code is required') {
        actions.setStatus({ ...actions.status, twoFaRequired: true });
        return;
      }
      auth.failedAuth(e);
    }
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    totp_token: Yup.string(),
  });

  return (
    <Observer>
      {() => (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.root}
          direction="column"
          data-testid="login-page"
        >
          <Typography variant="h5">Sign in</Typography>
          <Grid item className={classes.wrapper}>
            <Grow in={Boolean(auth.error)}>
              <FormHelperText error className={classes.error}>
                {auth.error}
              </FormHelperText>
            </Grow>
            <Formik
              initialStatus={{ twoFaRequired: false }}
              onSubmit={login}
              validationSchema={loginSchema}
              initialValues={{ email: '', password: '' }}
              component={LoginForm}
            />
          </Grid>
        </Grid>
      )}
    </Observer>
  );
};

export default Login;
