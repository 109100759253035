import React from 'react';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import ParadigmModal from '../../../../material/modal';
import EditCredentialForm from './edit-credential-form';
import { allowedIpSchema } from '../../../../utils/common';

const EditCredential = ({ source, onClose, credential }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(null);
  const keysSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    allowed_cidrs: allowedIpSchema,
  });

  const handleSubmit = async (values) => {
    const allowed_cidrs = !isEmpty(values['allowed_cidrs'])
      ? values['allowed_cidrs'].split(/\r?\n/).filter((item) => item.length > 0)
      : [];
    const read_only = values['read_only'];
    const name = values['name'];
    try {
      setIsSubmitting(true);
      await source.updateCredential({
        ...credential,
        allowed_cidrs,
        name,
        read_only,
      });
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <ParadigmModal title="Edit API Credentials" onClose={onClose}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={keysSchema}
        initialValues={{
          name: credential.name,
          read_only: credential.readOnly,
          allowed_cidrs: credential.getAllowedCidrs.join('\n'),
        }}
      >
        {(props) => (
          <EditCredentialForm
            credential={credential}
            {...props}
            isSubmitting={isSubmitting}
            onClose={onClose}
          />
        )}
      </Formik>
    </ParadigmModal>
  );
};

export default EditCredential;
