import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Field, Form } from 'formik';
import { map, omit } from 'lodash';
import { searchDeskMembersApi } from '../../../../api/auth-api';
import ParadigmButton from '../../../../material/button';
import ParadigmInput from '../../../../material/input';
import { ModalActionsWrapper } from '../../../../material/modal';
import ParadigmSelect from '../../../../material/select';
import { ROLES } from '../../../../resources/config';

const formatLabel = (member, { selectValue, inputValue }) => {
  const selected = selectValue && selectValue.length && !inputValue;
  return (
    <Grid
      container
      direction={selected ? 'row' : 'column-reverse'}
      alignItems={selected ? 'center' : 'flex-start'}
      spacing={selected ? 1 : 0}
    >
      <Grid item style={{ marginTop: -3 }}>
        <Typography component="div">
          {`${member.first_name} ${member.last_name}`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={{ opacity: 0.5 }}>({member.firm})</Typography>
      </Grid>
    </Grid>
  );
};

const AddMemberForm = ({
  isSubmitting,
  onClose,
  step,
  nextStep,
  values,
  setNotify,
}) => {
  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <Form noValidate onKeyDown={onKeyDown}>
      <Box display={step === 0 ? 'block' : 'none'}>
        <Field
          getOptionLabel={(o) => `${o.first_name} ${o.last_name}`}
          name="user"
          label="Name"
          component={ParadigmSelect}
          formatOptionLabel={formatLabel}
          data={searchDeskMembersApi}
          placeholder="Search for users"
          autoFocus
        />
        <Field
          name="role"
          label="Account level"
          component={ParadigmInput}
          select
        >
          {map(omit(ROLES, ['AD']), (role, short) => (
            <MenuItem key={short} value={short}>
              {role.name}
            </MenuItem>
          ))}
        </Field>
      </Box>

      <ModalActionsWrapper>
        <ParadigmButton
          type="button"
          secondary
          text="Cancel"
          onClick={onClose}
        />
        <ParadigmButton
          key="1"
          type="submit"
          busy={isSubmitting}
          text="Add Member"
        />
      </ModalActionsWrapper>
    </Form>
  );
};

export default AddMemberForm;
