import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router';
import BitcomAccount from './bitcom-account/bitcom-account';
import RFQ from './rfq/rfq';
import bitcomContext from '../../stores/bitcom-store';
import { useTabQueryString } from '../../hooks/use-tab-query-string';

const useStyles = makeStyles({
  tabs: {
    paddingBottom: 20,
  },
  tab: {
    width: 220,
  },
});

const BitcomGroup = ({ location, history }) => {
  const classes = useStyles();
  const bitcom = React.useContext(bitcomContext);
  const { tab, setTab } = useTabQueryString('options');

  React.useEffect(() => {
    bitcom.fetch();
  }, [bitcom]);

  return (
    <div data-testid="cme-bitcom">
      <Tabs
        value={tab}
        onChange={(_, selectedTab) => setTab(selectedTab)}
        className={classes.tabs}
      >
        <Tab value="options" className={classes.tab} label="RFQ" />
        <Tab value="account" className={classes.tab} label="Account" />
      </Tabs>
      {tab === 'options' && <RFQ source={bitcom} />}
      {tab === 'account' && <BitcomAccount source={bitcom} />}
    </div>
  );
};

export default withRouter(BitcomGroup);
