import { updateVenueAPICredentialsApi } from '#/api/venue-api-credentials-api';
import { createContext } from 'react';
import MarketIntegrityAccessKey from './classes/options/market-integrity-access-key';
import VenueAPICredentials from './venue-api-credentials-store';

class DeribitMarketIntegrityAccessKey extends MarketIntegrityAccessKey {
  venueLongName = 'deribit';

  async update(data) {
    const response = await updateVenueAPICredentialsApi(this.venueLongName, {
      id: this.id,
      ...data,
      entity: data.entity.id,
    });
    this.accessKey = response.access_key;
    this.name = response.name;
    this.subaccount = response.subaccount;
    this.entity = data.entity;
    this.isEnabled = Boolean(response.is_enabled);
    this.isPenalized = Boolean(response.is_penalized);
  }
}

export class Deribit extends VenueAPICredentials {
  AccessKeyClass = DeribitMarketIntegrityAccessKey;

  constructor() {
    super('Deribit', 'DBT', 'deribit');
  }
}

export const deribit = new Deribit();
window.deribit = deribit;

export default createContext(deribit);
