import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
  },
  404: {
    fontWeight: 500,
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <Grid item className={classes.container}>
        <Typography variant="h1" align="center" className={classes['404']}>
          404
        </Typography>
        <Typography variant="h5" align="center">
          Oops, looks like page does not exist
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
