import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';

const StyledButton = styled(({ busy, ...props }) => <Button {...props} />)(
  ({ theme, busy }) => ({
    minWidth: 100,
    '& span': {
      '& span': {
        visibility: busy ? 'hidden' : 'visible',
      },
      '& div': {
        position: 'absolute',
      },
    },
  }),
);

const ParadigmButton = (props) => {
  const { text, secondary, busy, ...rest } = props;

  if (!secondary) {
    rest.color = 'primary';
    rest.variant = 'contained';
  }
  if (busy && !rest.disabled) {
    rest.disabled = true;
  }

  return (
    <StyledButton {...rest} busy={busy} data-testid="paradigm-button">
      <span>{text}</span>
      {busy && (
        <CircularProgress
          size={22}
          color="inherit"
          data-testid="busy-indicator"
        />
      )}
    </StyledButton>
  );
};

ParadigmButton.propTypes = {
  secondary: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  busy: PropTypes.bool,
  text: PropTypes.string,
};

export default ParadigmButton;
