import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as PropTypes from 'prop-types';
import React from 'react';
import routesContext from '../../stores/routes-store';
import SidebarList from './components/sidebar-list';

const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: theme.zIndex.appBar - 1,
    width: 250,
  },
  drawerPaper: {
    marginTop: 75,
    width: 250,
    [theme.breakpoints.only('xs')]: {
      marginTop: 55,
    },
  },
}));

const SideBar = (props) => {
  const classes = useStyles(props);
  const { open, toggle } = props;
  const isDevice = useMediaQuery('(max-width:600px)');
  const routes = React.useContext(routesContext);

  return (
    <Drawer
      variant={isDevice ? 'temporary' : 'permanent'}
      open={!isDevice || open}
      onClose={toggle}
      className={classes.drawer}
      data-testid="sidebar"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <SidebarList list={routes.guarded} {...(isDevice && { toggle })} />
    </Drawer>
  );
};

SideBar.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

export default SideBar;
