import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { isEmpty, map } from 'lodash';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import ParadigmPopover from '../../../material/popover';
import ParadigmSwitch from '../../../material/switch';
import ParadigmHelp from '../../../material/help-outline';
import deskContext from '../../../stores/desk-store';
import PermissionsTable from '../permissions-table/permissions-table';
import TableAction from './table-action';
import authContext from '../../../stores/auth-store';

const MemberRow = ({ auth, member }) => (
  <TableRow hover>
    <TableCell component="th" scope="row" data-testid="m-name">
      {member.name}
    </TableCell>
    <TableCell align="right" data-testid="m-role">
      {member.role}
    </TableCell>
    <TableCell align="right" data-testid="m-toggle">
      {member.isTrader && (
        <Grid container alignItems="center">
          <Grid item>
            <ParadigmSwitch
              disabled={!auth.canManageTradingDesk}
              checked={Boolean(member.tradingEnabled)}
              onChange={member.toggleTradingEnabled}
            />
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              style={{ marginLeft: 10, fontSize: 11 }}
            >
              {member.tradingEnabled ? 'Enabled' : ''}
            </Typography>
          </Grid>
        </Grid>
      )}
    </TableCell>
    {auth.canManageTradingDesk && (
      <TableCell align="right" data-testid="m-action">
        <TableAction member={member} />
      </TableCell>
    )}
  </TableRow>
);

const RequestedUserRow = ({ requestedUser }) => (
  <TableRow hover>
    <TableCell component="th" scope="row" data-testid="m-name">
      {requestedUser.first_name} {requestedUser.last_name}
    </TableCell>
    <TableCell align="right" data-testid="m-role" css="color: #F1E20D">
      Processing
    </TableCell>
    <TableCell align="right" data-testid="m-toggle">
      {requestedUser.role === 'TR' && (
        <Grid container alignItems="center">
          <Grid item>
            <ParadigmSwitch disabled={true} checked={false} />
          </Grid>
        </Grid>
      )}
    </TableCell>
  </TableRow>
);

const MembersTable = () => {
  const desk = React.useContext(deskContext);
  const auth = React.useContext(authContext);

  return (
    <Observer>
      {() => (
        <Table data-testid="members-table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '70%' }}>Name</TableCell>
              <TableCell style={{ width: '15%', minWidth: 210 }} align="right">
                <Grid container alignItems="center" justifyContent="flex-end">
                  <span>Account type</span>&nbsp;
                  <ParadigmPopover trigger={<ParadigmHelp color="action" />}>
                    <PermissionsTable />
                  </ParadigmPopover>
                </Grid>
              </TableCell>
              <TableCell style={{ width: '15%', minWidth: 170 }} align="left">
                RFQ Trading
              </TableCell>
              {auth.canManageTradingDesk && (
                <TableCell align="right" style={{ minWidth: 106 }} />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {map(desk.members, (member) => (
              <MemberRow key={member.id} auth={auth} member={member} />
            ))}
            {map(desk.requestedUsers, (requestedUser) => (
              <RequestedUserRow
                key={requestedUser.id}
                requestedUser={requestedUser}
              />
            ))}
            {isEmpty(desk.members) && isEmpty(desk.requestedUsers) && (
              <TableRow hover={false}>
                <TableCell
                  align="center"
                  colSpan={auth.canManageTradingDesk ? 4 : 3}
                >
                  No members
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Observer>
  );
};

export default MembersTable;
