import React from 'react';
import Select from 'react-select';
import components, { selectStyles } from './styles';

const SelectSync = (props) => {
  return (
    <Select
      isClearable
      components={components}
      styles={selectStyles}
      autoFocus={props.textFieldProps.autoFocus}
      {...props}
    />
  );
};

export default SelectSync;
