import React from 'react';
import deskContext from '../../stores/desk-store';
import Profile from './profile';
import { withRouter } from 'react-router';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Entities from '../desk-management/entities';
import { makeStyles } from '@material-ui/core/styles';
import { useTabQueryString } from '../../hooks/use-tab-query-string';
import authContext from '#/stores/auth-store';

const useStyles = makeStyles({
  tabs: {
    paddingBottom: 20,
  },
  tab: {
    width: 220,
  },
});

const DeskManagement = ({ location, history }) => {
  const auth = React.useContext(authContext);
  const desk = React.useContext(deskContext);
  const classes = useStyles();
  const { tab, setTab } = useTabQueryString('profile');

  React.useEffect(() => {
    desk.fetch();
  }, [desk]);

  return (
    <div data-testid="products-management">
      <Tabs
        value={tab}
        onChange={(_, selectedTab) => setTab(selectedTab)}
        className={classes.tabs}
      >
        <Tab value="profile" className={classes.tab} label="Desk Profile" />
        <Tab
          value="entities"
          className={classes.tab}
          label={auth.isBusiness ? 'Entities' : 'Verification'}
        />
      </Tabs>
      {tab === 'profile' && <Profile />}
      {tab === 'entities' && <Entities />}
    </div>
  );
};

export default withRouter(DeskManagement);
