import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import entitiesContext from '../../../../../stores/entities-store';
import { getEntityVenueRestrictionsApi } from '#/api/desk-api';

function useEntityRestrictions(entities, venue) {
  const [restrictions, setRestrictions] = React.useState({
    loading: true,
    restrictions: {},
  });

  React.useEffect(() => {
    const promises = entities.map(async (entity) => {
      try {
        const response = await getEntityVenueRestrictionsApi(
          entity.id,
          venue.shortName,
        );
        return {
          [entity.id]: {
            isRestricted: response.restricted,
            message: response.message,
          },
        };
      } catch (e) {
        return {
          [entity.id]: { networkError: e },
        };
      }
    });

    Promise.all(promises).then((restrictions) =>
      setRestrictions({
        loading: false,
        restrictions: restrictions.reduce(Object.assign, {}),
      }),
    );
  }, [entities, venue.shortName, setRestrictions]);

  return restrictions;
}

export const useEntities = (source) => {
  const entitiesStore = React.useContext(entitiesContext);
  const entities = React.useMemo(
    () => entitiesStore.entitiesArray,
    [entitiesStore],
  );
  const entityRestrictions = useEntityRestrictions(entities, source);

  const entityOptions = React.useMemo(() => {
    return entities.map((entity) => {
      if (entityRestrictions.loading) {
        return (
          <MenuItem key={entity.id} value={entity} disabled={true}>
            {entity.name} (...)
          </MenuItem>
        );
      } else {
        const restriction = entityRestrictions.restrictions[entity.id];
        if (restriction.networkError) {
          return (
            <MenuItem key={entity.id} value={entity} disabled={true}>
              {entity.name}
              <span css="color: red; margin-left: 12px">Network Error</span>
            </MenuItem>
          );
        }
        return (
          <MenuItem
            key={entity.id}
            value={entity}
            disabled={restriction.isRestricted}
          >
            {entity.name}
            {restriction.message && (
              <span css="color: red; margin-left: 12px">
                ({restriction.message})
              </span>
            )}
          </MenuItem>
        );
      }
    });
  }, [entityRestrictions, entities]);

  return { entityOptions };
};
