import React from 'react';
import { Search } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import CheckedIcon from '@paradigm/design-system/src/assets/CheckedIcon';
import UnCheckedIcon from '@paradigm/design-system/src/assets/UnCheckedIcon';
import ParadigmButton from '../../../material/button';
import ParadigmInput from '../../../material/input';
import ParadigmModal, { ModalActionsWrapper } from '../../../material/modal';

const MemberSelectModal = ({
  children,
  value,
  onChange,
  memberOptions,
  disabled,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [members, setMembers] = React.useState([]);
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    setMembers(value);
  }, [value, setMembers]);

  const membersArray = Object.values(memberOptions);
  const filteredMembers = membersArray.filter((member) =>
    member.name.toLowerCase().includes(search.toLowerCase()),
  );

  const allSelected = membersArray.length === members.length;

  const isMemberChecked = (member) => members.includes(member);

  const toggleMember = (member) => {
    if (isMemberChecked(member)) {
      setMembers((ids) => ids.filter((m) => m !== member));
    } else {
      setMembers((members) => [...members, member]);
    }
  };

  const toggleAll = () => {
    if (allSelected) {
      setMembers([]);
    } else {
      setMembers(membersArray);
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => !disabled && setIsOpen(true),
      })}
      {isOpen && (
        <ParadigmModal
          title="Select Desk Members"
          onClose={() => setIsOpen(false)}
          noScroll
          PaperProps={{ style: { maxWidth: 400 } }}
        >
          <Box>
            <ParadigmInput
              value={search}
              margin="none"
              onChange={(e) => setSearch(e.target.value)}
              startAdornment={<Search />}
              placeholder="Search for Desk Members"
              className="rounded"
            />
            <Box display="flex" justifyContent="flex-end" my={2}>
              <Box
                display="inline-flex"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={toggleAll}
              >
                <Box mr={1}>Select All</Box>
                <Box color="primary.main" height={32}>
                  {!allSelected ? <UnCheckedIcon /> : <CheckedIcon />}
                </Box>
              </Box>
            </Box>
            <Box height={300} overflow="auto" mx={-4}>
              {filteredMembers.map((member, i) => (
                <Box
                  key={member.id}
                  onClick={() => toggleMember(member)}
                  {...(i < filteredMembers.length - 1 && {
                    borderBottom: 1,
                  })}
                  style={{ borderColor: 'rgba(255, 255, 255, 0.05)' }}
                  display="flex"
                  alignItems="center"
                  py="9px"
                  px={4}
                >
                  <Box
                    borderRadius="50%"
                    bgcolor={member.color}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={42}
                    width={42}
                    fontSize={16}
                    fontWeight="bold"
                  >
                    {member.initials}
                  </Box>
                  <Box flex={1} ml={2} fontSize={13}>
                    {member.name}
                  </Box>
                  <Box color="primary.main" height={32}>
                    {!isMemberChecked(member) ? (
                      <UnCheckedIcon />
                    ) : (
                      <CheckedIcon />
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <ModalActionsWrapper>
            <ParadigmButton
              text="Cancel"
              variant="text"
              secondary
              onClick={() => setIsOpen(false)}
            />
            <ParadigmButton
              text="Confirm"
              disabled={!members.length}
              onClick={() => {
                onChange(members);
                setIsOpen(false);
              }}
            />
          </ModalActionsWrapper>
        </ParadigmModal>
      )}
    </>
  );
};

export default MemberSelectModal;
