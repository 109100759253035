import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export default function SettingsFs() {
  return (
    <>
      <Value>
        <Typography color="textSecondary">Time Period</Typography>
        <Typography>1 Second</Typography>
      </Value>
      <Value>
        <Typography color="textSecondary">
          Maximum # of executions allowed
        </Typography>
        <Typography>2</Typography>
      </Value>
      <Value>
        <Typography color="textSecondary">Quantity</Typography>
        <Typography>5,000,000 USD</Typography>
      </Value>
    </>
  );
}

const Value = styled.div`
  display: grid;
  grid-template-columns: 170px auto;
  margin-bottom: 10px;
`;
