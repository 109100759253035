import { observable, makeObservable, override } from 'mobx';
import { updateWalletAddressApi } from '../../../api/products-api';
import AccessKey from './access-key';

export default class MarketIntegrityAccessKey extends AccessKey {
  subaccount;
  isPenalized;
  isValidCredentials;
  hasIpAllowlist;
  hasBlockTradeScope;
  hasBalancePermission;
  hasAvailableBalances;
  hasBlockTradeAprroval;
  hasTradeScope;
  venue;

  constructor(venueShortName, key) {
    super(key);
    makeObservable(this, {
      subaccount: observable,
      isPenalized: observable,
      isValidCredentials: observable,
      hasIpAllowlist: observable,
      hasBlockTradeScope: observable,
      hasBalancePermission: observable,
      hasAvailableBalances: observable,
      hasTradeScope: observable,
      venue: observable,
      update: override,
    });

    this.subaccount = key.subaccount;
    this.venue = venueShortName;
    this.isPenalized = Boolean(key.is_penalized);
    if (key.summary) {
      this.isValidCredentials = Boolean(key.summary.is_valid_credential);
      this.hasIpAllowlist = Boolean(key.summary.has_ip_allowlisting);
      this.hasBlockTradeScope = Boolean(key.summary.has_block_trade_permission);
      this.hasBalancePermission = Boolean(key.summary.has_balance_permission);
      this.hasAvailableBalances = Boolean(key.summary.has_available_balances);
      this.hasBlockTradeApproval = Boolean(
        key.summary.has_block_trade_approval,
      );
      this.hasTradeScope = Boolean(
        key.summary.has_position_readable_permission,
      );
    }
  }

  async update(data) {
    const response = await updateWalletAddressApi({
      id: this.id,
      ...data,
      entity: data.entity.id,
    });
    this.accessKey = response.access_key;
    this.name = response.name;
    this.subaccount = response.subaccount;
    this.entity = data.entity;
    this.isEnabled = Boolean(response.is_enabled);
    this.isPenalized = Boolean(response.is_penalized);
  }
}
