import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Observer } from 'mobx-react-lite';
import { Field, Form } from 'formik';
import ParadigmButton from '../../../../../material/button';
import ParadigmInput from '../../../../../material/input';
import entitiesContext from '../../../../../stores/entities-store';
import { ModalActionsWrapper } from '../../../../../material/modal';

const EditKeysForm = ({ isSubmitting, onClose, dirty, apiKey }) => {
  const entitiesStore = React.useContext(entitiesContext);

  return (
    <Observer>
      {() => (
        <Form noValidate>
          <Field
            name="name"
            label="Name"
            component={ParadigmInput}
            required
            autoFocus
          />
          <Field
            name="entity"
            label="Entity"
            component={ParadigmInput}
            select
            required
          >
            {entitiesStore.entitiesEnabledArray.map((entity) => (
              <MenuItem key={entity.id} value={entity}>
                {entity.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            name="access_key"
            label="API Key"
            component={ParadigmInput}
            placeholder={apiKey.formattedAccessKey}
          />
          <Field
            name="access_secret"
            label="API Secret"
            component={ParadigmInput}
            placeholder={apiKey.formattedAccessSecret}
          />
          <ModalActionsWrapper>
            <ParadigmButton secondary text="Cancel" onClick={onClose} />
            <ParadigmButton
              type="submit"
              busy={isSubmitting}
              disabled={!dirty}
              text="Save"
            />
          </ModalActionsWrapper>
        </Form>
      )}
    </Observer>
  );
};

export default EditKeysForm;
