import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useToggle from '../../../hooks/use-toggle';
import ParadigmModal from '../../../material/modal';
import AddKeyForm from './AddKeyForm';

const addKeySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  entity: Yup.object().required('Entity is required'),
  wallet_address: Yup.string()
    .min(42, 'Wallet Address must be at least 42 characters')
    .max(44, 'Wallet Address must be no more than 44 characters')
    .required('Wallet Address is required'),
  bidding_token_address: Yup.string()
    .min(42, 'Bidding Token Address must be at least 42 characters')
    .max(44, 'Bidding Token Address must be no more than 44 characters')
    .required('Bidding Token Address is required'),
  swap_contract_address: Yup.string()
    .min(42, 'Swap Contract Address must be at least 42 characters')
    .max(44, 'Swap Contract Address must be no more than 44 characters')
    .required('Swap Contract Address is required'),
  delegated_wallet_address: Yup.string()
    .min(42, 'Delegated Wallet Address must be at least 42 characters')
    .max(44, 'Delegated Wallet Address must be no more than 44 characters'),
});

const AddKeyRibbon = ({ children, source, defaultValues = {} }) => {
  const [open, toggle] = useToggle(false);
  const { addApiKey } = source;

  const initialValues = {
    name: '',
    entity: '',
    wallet_address: '',
    venue: source.shortName,
    ...defaultValues,
  };

  const handleSubmit = async (key) => {
    try {
      await addApiKey(key);
    } finally {
      toggle();
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: toggle,
      })}
      {open && (
        <ParadigmModal title="Add Wallet" onClose={toggle}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={addKeySchema}
          >
            {(props) => (
              <AddKeyForm
                {...props}
                source={source}
                onClose={toggle}
                venue={source.name}
              />
            )}
          </Formik>
        </ParadigmModal>
      )}
    </>
  );
};

export default React.memo(AddKeyRibbon);
