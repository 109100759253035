import { keyBy, sortBy } from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import ApiCredential from '../stores/classes/api-credential';
import {
  createApiCredentialApi,
  deleteApiCredentialApi,
  updateApiCredentialApi,
} from '../api/api-management-api';

export class ApiCredentialManagement {
  _apiCredentials = {};
  tradingDeskId = null;

  setApiCredentials = (apiCredentials, tradingDeskId) => {
    this._apiCredentials = keyBy(
      apiCredentials.map((credential) => new ApiCredential(credential)),
      'id',
    );
    this.tradingDeskId = tradingDeskId;
  };

  createCredential = async ({ name, allowed_cidrs, read_only }) => {
    const createdApiCredential = await createApiCredentialApi(
      this.tradingDeskId,
      name,
      allowed_cidrs,
      read_only,
    );
    this._apiCredentials[createdApiCredential.id] = new ApiCredential(
      createdApiCredential,
    );
    return createdApiCredential;
  };

  removeCredential = async (apiCredentialId) => {
    await deleteApiCredentialApi(this.tradingDeskId, apiCredentialId);
    delete this._apiCredentials[apiCredentialId];
  };

  updateCredential = async (payload) => {
    const response = await updateApiCredentialApi({
      apiKeyId: payload.id,
      tradingDeskId: this.tradingDeskId,
      payload: {
        label: payload.name,
        allowed_cidrs: payload.allowed_cidrs,
        ...payload,
      },
    });
    this._apiCredentials[response.id] = new ApiCredential(response);
  };

  reset = () => {
    this._apiCredentials = {};
    this.tradingDeskId = null;
  };

  constructor() {
    makeObservable(this, {
      _apiCredentials: observable,
      tradingDeskId: observable,
      setApiCredentials: action,
      createCredential: action,
      removeCredential: action,
      updateCredential: action,
      reset: action,
      apiCredentials: computed,
    });
  }

  get apiCredentials() {
    return Object.values(
      sortBy(this._apiCredentials, (api) => api.name.toLowerCase()),
    );
  }
}

export const apiCredentialManagement = new ApiCredentialManagement();
window.apiCredentialManagement = apiCredentialManagement;

export default createContext(apiCredentialManagement);
