import React from 'react';
import { Observer } from 'mobx-react-lite';
import { Field, Form } from 'formik';
import ParadigmInput from '../../../../material/input';
import ParadigmButton from '../../../../material/button';
import { ModalActionsWrapper } from '../../../../material/modal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: 5,
    opacity: 0.5,
  },
}));
const AddEmailsForm = ({ onClose, isSubmitting }) => {
  const classes = useStyles();
  return (
    <Observer>
      {() => (
        <Form noValidate>
          <Field
            name="emails"
            label="Emails"
            component={ParadigmInput}
            rows={10}
            multiline
            placeholder="Enter emails"
          />
          <p className={classes.text}>
            Enter each email address in separate line
          </p>
          <ModalActionsWrapper>
            <ParadigmButton secondary text="Cancel" onClick={onClose} />
            <ParadigmButton type="submit" text="Add" busy={isSubmitting} />
          </ModalActionsWrapper>
        </Form>
      )}
    </Observer>
  );
};

export default AddEmailsForm;
