import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles, styled } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as PropTypes from 'prop-types';
import React from 'react';
import ParadigmButton from './button';

const IconWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -22,
  padding: 10,
  borderRadius: '50%',
  background: theme.palette.background.default,
  display: 'flex',
  alignSelf: 'center',
}));

const useStyles = makeStyles({
  paper: {
    overflow: 'visible',
    textAlign: 'center',
  },
});

const ParadigmConfirmationModal = (props) => {
  const classes = useStyles();
  const [busy, setBusy] = React.useState(false);

  const handleConfirm = async () => {
    setBusy(true);
    try {
      await onConfirm();
    } catch (err) {
      onReject && onReject();
    }
  };

  const {
    title,
    message,
    note,
    onConfirm,
    onReject,
    confirmText = 'Confirm',
    icon,
    ...other
  } = props;

  const isDevice = useMediaQuery('(max-width:600px)');

  const urlify = (value) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const text = Array.isArray(value) ? value[0] ?? '' : value;

    if (urlRegex.test(text))
      return text.replace(
        urlRegex,
        (url) =>
          `<a href="${url}" style="color:#7fabec;" target="_blank">${url}</a>`,
      );

    return text;
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      fullScreen={isDevice}
      data-testid="paradigm-conform-modal"
      classes={{ paper: classes.paper }}
      {...other}
    >
      <IconWrapper>{icon}</IconWrapper>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{urlify(message)}</Typography>
      </DialogContent>
      <DialogContent>
        {onReject && (
          <ParadigmButton secondary text="Cancel" onClick={onReject} />
        )}
        <ParadigmButton
          text={confirmText}
          onClick={handleConfirm}
          busy={busy}
        />
      </DialogContent>
      {note && (
        <DialogContent>
          <Typography variant="caption" color="textSecondary">
            {note}
          </Typography>
        </DialogContent>
      )}
    </Dialog>
  );
};

ParadigmConfirmationModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func,
};

export default ParadigmConfirmationModal;
