import { find, keyBy, omitBy, some } from 'lodash';
import { action, toJS, computed, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import {
  addMemberApi,
  addRequestedUserApi,
  getDeskInfoApi,
  removeMemberApi,
  getRequestedUsersApi,
  getEmailsApi,
  addEmailsApi,
} from '../api/desk-api';
import { updateOptionsApi } from '../api/options-api';
import { updateBitcomApi } from '../api/bitcom-api';
import { apiCredentialManagement } from './api-management-store';
import { Member } from './classes/member';
import { deribit } from './deribit-store';
import { bitcom } from './bitcom-store';

class Desk {
  mailingAddress;
  members;
  firm;
  requestedUsers;
  ticker;
  emails;

  setFirm = ({ firm, ticker }) => {
    this.firm = firm;
    this.ticker = ticker;
  };

  setMailingAddress = (address) => (this.mailingAddress = address);

  setMembers = (members) => {
    this.members = keyBy(
      members.map((m) => new Member(m)),
      'id',
    );
  };
  setEmailsList = (list) => (this.emails = list);

  addMember = async (member) => {
    const createdMember = await addMemberApi(member);
    if (createdMember.role === 'AD' && desk.admin) {
      desk.admin.type = 'TR';
    }
    const newMember = new Member(createdMember);
    this.members[newMember.id] = newMember;
  };

  removeMember = async (member) => {
    await removeMemberApi(member.memberId);
    delete this.members[member.id];
  };

  addEmailsList = async (emailsList) => {
    const { emails } = await addEmailsApi(emailsList);
    this.setEmailsList(emails);
  };

  deleteEmails = async () => {
    await addEmailsApi({ emails: [] });
    this.setEmailsList([]);
  };

  setRequestedUsers = (requestedUsers) => {
    this.requestedUsers = Object.fromEntries(
      requestedUsers.map((requestedUser) => [requestedUser.id, requestedUser]),
    );
  };

  addRequestedUser = async (requestedUser) => {
    const newRequestedUser = await addRequestedUserApi(requestedUser);
    this.requestedUsers[newRequestedUser.id] = newRequestedUser;
  };

  fetch = async () => {
    const { api_keys, trading_desk, members } = await getDeskInfoApi();
    const { emails } = await getEmailsApi();
    this.setEmailsList(emails);
    this.setFirm(trading_desk);
    this.setMembers(members);
    apiCredentialManagement.setApiCredentials(api_keys || [], trading_desk.id);
    deribit.setProduct(trading_desk.deribit_product || {}, updateOptionsApi);
    bitcom.setProduct(trading_desk.bitcom_product || {}, updateBitcomApi);
    if (some(trading_desk.mailing_address)) {
      this.setMailingAddress(trading_desk.mailing_address);
    }
    const requestedUsers = await getRequestedUsersApi();
    this.setRequestedUsers(requestedUsers);
  };

  reset = () => {
    this.apiKeys = null;
    this.mailingAddress = null;
    this.members = null;
    this.firm = null;
    this.requestedUsers = null;
    this.ticker = null;
    this.emails = null;
  };

  constructor() {
    makeObservable(this, {
      mailingAddress: observable,
      members: observable,
      firm: observable,
      requestedUsers: observable,
      ticker: observable,
      setFirm: action,
      setMailingAddress: action,
      setMembers: action,
      setEmailsList: action,
      addMember: action,
      removeMember: action,
      addEmailsList: action,
      fetch: action,
      reset: action,
      admin: computed,
      traders: computed,
      mailingMembers: computed,
      getEmails: computed,
      emails: observable,
    });
  }
  get getEmails() {
    return toJS(this.emails);
  }

  get admin() {
    return find(this.members, (i) => i.isAdmin);
  }

  get traders() {
    return omitBy(this.members, (member) => !member.isTrader);
  }

  get mailingMembers() {
    return omitBy(
      this.members,
      (member) => !member.isTrader && !member.isAdmin,
    );
  }
}

export const desk = new Desk();
window.desk = desk;

export default createContext(desk);
