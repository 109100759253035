import Popover from '@material-ui/core/Popover';
import React from 'react';

const ParadigmPopover = ({ children, trigger, clickable = false }) => {
  const [anchor, setAnchor] = React.useState(null);
  return (
    <>
      {React.cloneElement(trigger, {
        onMouseEnter: ({ currentTarget }) => setAnchor(currentTarget),
        onMouseLeave: clickable ? undefined : () => setAnchor(null),
        onClick: clickable ? () => setAnchor(null) : undefined,
        style: { ...trigger.props.style, cursor: 'pointer' },
      })}
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        style={!clickable && { pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchor(null)}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </>
  );
};

export default ParadigmPopover;
