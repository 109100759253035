import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  item: {
    borderLeft: '4px solid',
    borderColor: 'transparent',
    '&.active': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paperContrast,
      '& $text > span': {
        color: theme.palette.primary.main,
      },
    },
  },
  text: {
    '& > span': {
      fontSize: '0.75rem',
    },
    '&.root': {
      paddingLeft: 0,
    },
    '&.root > span': {
      fontWeight: 500,
      fontSize: '0.85rem',
    },
  },
  chevron: {
    position: 'absolute',
    left: 0,
    color: theme.palette.primary.main,
  },
}));

const NavLinkWithRef = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink role="button" {...props} />
  </div>
));

const SidebarLink = ({ onClick, item, subItem, expanded }) => {
  const classes = useStyles({ subItem });
  const { routeProps, title, items } = item;

  const handleItemClick = () => {
    onClick(item);
  };

  return (
    <ListItem
      button
      data-testid="sidebar-item"
      onClick={handleItemClick}
      {...(routeProps && {
        to: routeProps.path,
        component: NavLinkWithRef,
        activeClassName: 'active',
        exact: true,
      })}
      className={classes.item}
    >
      {items && !expanded && <ChevronRight className={classes.chevron} />}
      {items && expanded && <ExpandMore className={classes.chevron} />}
      <ListItemText
        primary={title}
        {...(subItem && { style: { paddingLeft: 16 } })}
        className={cx(classes.text, { root: !subItem })}
      />
    </ListItem>
  );
};

SidebarLink.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    routeProps: PropTypes.shape({
      path: PropTypes.string.isRequired,
      exact: PropTypes.bool,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  subItem: PropTypes.bool,
  expanded: PropTypes.bool,
};

export default SidebarLink;
