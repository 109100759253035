import { ROLES } from '../../resources/config';

export class Roles {
  get isAdmin() {
    return this.type === 'AD';
  }

  get isTrader() {
    return this.type === 'TR';
  }

  get isAnalyst() {
    return this.type === 'AN';
  }

  get isViewer() {
    return this.type === 'VI';
  }

  get role() {
    return ROLES[this.type].name;
  }
}
