import React from 'react';
import { Observer } from 'mobx-react-lite';
import { isEmpty, map } from 'lodash';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useMarketIntegrityMarginFeatureFlag } from '@paradigm/features/src/feature-flags/context';
import AddKey from '../add-keys/add-key';
import TableAction from '../../account/components/keys-table/table-action';
import authContext from '../../../../stores/auth-store';
import EditKeys from '../edit-keys/edit-keys';
import VenueApiKeyToggle from '../../../../components/VenueApiKeyToggle';
import { Permission } from '@paradigm/features/src/permission';
import ParadigmPopover from '#/material/popover';

const KeysTable = (props) => {
  const { source } = props;
  const auth = React.useContext(authContext);
  const [disabledIds, setDisabledIds] = React.useState([]);
  const isMarketIntegrityMarginFeatureEnabled =
    useMarketIntegrityMarginFeatureFlag();

  const handleUpdateActiveKey = (key) => {
    setDisabledIds((ids) => [...ids, key.id]);
    key
      .update({ ...key, is_enabled: !key.isEnabled, venue: source.shortName })
      .finally(() => setDisabledIds((ids) => ids.filter((i) => i !== key.id)));
  };

  return (
    <Observer>
      {() => {
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: '50%', minWidth: 185 }}>
                  Name
                </TableCell>
                <TableCell align="left" style={{ width: '15%' }}>
                  Entity
                </TableCell>
                <TableCell align="left" style={{ width: '15%', minWidth: 185 }}>
                  API Key
                </TableCell>
                <TableCell align="left" style={{ width: '15%', minWidth: 160 }}>
                  API Secret
                </TableCell>
                <TableCell align="left" style={{ minWidth: 145 }}>
                  Valid Credentials
                </TableCell>
                <ParadigmPopover
                  clickable
                  trigger={
                    <TableCell align="left" style={{ minWidth: 130 }}>
                      IP Allow-list{' '}
                      <HelpOutlineRounded
                        style={{ fontSize: 10 }}
                        color="primary"
                      />
                    </TableCell>
                  }
                >
                  <div
                    style={{
                      padding: 6,
                      maxWidth: 190,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <HelpOutlineRounded
                      style={{ fontSize: 10, marginTop: 5, marginRight: 6 }}
                      color="primary"
                    />
                    <Typography variant="caption">
                      Please add the Paradigm IP Whitelists to your Venue API
                      Key's IP Whitelist.
                      <br></br>
                      <Link
                        color="primary"
                        onClick={() => {
                          window.open(
                            'https://www.paradigm.co/help/documentation/paradigm-allowlist-ips',
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                      >
                        Paradigm IP Address to Whitelist
                      </Link>
                    </Typography>
                  </div>
                </ParadigmPopover>
                <ParadigmPopover
                  trigger={
                    <TableCell align="left" style={{ minWidth: 170 }}>
                      Block Trade Scope{' '}
                      <HelpOutlineRounded
                        style={{ fontSize: 10 }}
                        color="primary"
                      />
                    </TableCell>
                  }
                >
                  <div
                    style={{
                      padding: 6,
                      maxWidth: 190,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <HelpOutlineRounded
                      style={{ fontSize: 10, marginTop: 5, marginRight: 6 }}
                      color="primary"
                    />
                    <Typography variant="caption">
                      Please enable the "Block Trade" scope permission on your
                      Venue API Key.
                    </Typography>
                  </div>
                </ParadigmPopover>
                <ParadigmPopover
                  trigger={
                    <TableCell align="left" style={{ minWidth: 170 }}>
                      Block Trade Approval{' '}
                      <HelpOutlineRounded
                        style={{ fontSize: 10 }}
                        color="primary"
                      />
                    </TableCell>
                  }
                >
                  <div
                    style={{
                      padding: 6,
                      maxWidth: 190,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <HelpOutlineRounded
                      style={{ fontSize: 10, marginTop: 5, marginRight: 6 }}
                      color="primary"
                    />
                    <Typography variant="caption">
                      Paradigm does not allow ‘Block Trade Approval’ feature.
                      Please, turn if off to use the API Key.
                    </Typography>
                  </div>
                </ParadigmPopover>
                {isMarketIntegrityMarginFeatureEnabled && (
                  <>
                    <TableCell align="left" style={{ minWidth: 130 }}>
                      Balance Scope
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 147 }}>
                      Available Balance
                    </TableCell>
                  </>
                )}
                <TableCell align="left" style={{ minWidth: 118 }}>
                  Trade Scope
                </TableCell>
                <TableCell
                  style={{
                    minWidth: 205,
                    paddingRight: 0,
                  }}
                >
                  Status
                </TableCell>
                {auth.isAdmin && (
                  <TableCell align="left" style={{ minWidth: 106 }} />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(source.accessKeys, (key) => (
                <TableRow key={key.id}>
                  <TableCell component="th" scope="row">
                    {key.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {key.entity.name}
                  </TableCell>
                  <TableCell align="left">{key.formattedAccessKey}</TableCell>
                  <TableCell align="left">
                    {key.formattedAccessSecret}
                  </TableCell>
                  <TableCell align="left">
                    <Permission
                      isGranted={key.isValidCredentials}
                      label="valid credentials"
                      showLabel={false}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Permission
                      isGranted={key.hasIpAllowlist}
                      label="ip whitelist"
                      showLabel={false}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Permission
                      isGranted={key.hasBlockTradeScope}
                      label="block trade scope"
                      showLabel={false}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Permission
                      isGranted={!key.hasBlockTradeApproval}
                      showWarningIfDenied
                      label="block trade approval"
                      showLabel={false}
                    />
                  </TableCell>
                  {isMarketIntegrityMarginFeatureEnabled && (
                    <>
                      <TableCell align="left">
                        <Permission
                          isGranted={key.hasBalancePermission}
                          label="balance scope"
                          showLabel={false}
                          showWarningIfDenied={true}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Permission
                          isGranted={key.hasAvailableBalances}
                          label="available balance"
                          showLabel={false}
                          showWarningIfDenied={true}
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell align="left">
                    <Permission
                      isGranted={key.hasTradeScope}
                      label="trade scope"
                      showWarningIfDenied
                    />
                  </TableCell>
                  <TableCell align="left" style={{ paddingRight: 0 }}>
                    <VenueApiKeyToggle
                      apiKey={key}
                      disabledIds={disabledIds}
                      onChange={() => handleUpdateActiveKey(key)}
                    />
                  </TableCell>
                  {auth.isAdmin && (
                    <TableCell align="left">
                      <TableAction
                        apiKey={key}
                        source={source}
                        EditKeys={EditKeys}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {isEmpty(source.accessKeys) && (
                <TableRow hover={false}>
                  <TableCell align="center" colSpan={auth.isAdmin ? 5 : 4}>
                    No API credentials found.&nbsp;
                    <AddKey
                      source={source}
                      children={
                        <Link variant="body1" color="primary">
                          Click here to add one
                        </Link>
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        );
      }}
    </Observer>
  );
};

export default KeysTable;
