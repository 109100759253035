import { action, observable, makeObservable } from 'mobx';
import { updateWalletAddressApi } from '../../../api/products-api';

export default class AccessKey {
  name;
  entity;
  isEnabled;
  wallet_address;
  delegated_wallet_address;
  bidding_token_address;
  swap_contract_address;

  constructor(key) {
    makeObservable(this, {
      name: observable,
      wallet_address: observable,
      delegated_wallet_address: observable,
      bidding_token_address: observable,
      swap_contract_address: observable,
      entity: observable,
      isEnabled: observable,
      update: action,
    });

    this.id = key.id;
    this.name = key.name;
    this.entity = key.entity;
    this.isEnabled = key.is_enabled;
    this.wallet_address = key.wallet_address;
    this.delegated_wallet_address = key.delegated_wallet_address;
    this.bidding_token_address = key.bidding_token_address;
    this.swap_contract_address = key.swap_contract_address;
  }

  update = async (data) => {
    const response = await updateWalletAddressApi({
      id: this.id,
      ...data,
      entity: data.entity.id,
    });

    this.name = response.name;
    this.entity = data.entity;
    this.isEnabled = response.is_enabled;
    this.wallet_address = response.wallet_address;
    this.delegated_wallet_address = response.delegated_wallet_address;
    this.bidding_token_address = response.bidding_token_address;
    this.swap_contract_address = response.swap_contract_address;
  };
}
