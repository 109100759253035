import { action, computed, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import { addEntityApi, editEntityApi, removeEntityApi } from '../api/desk-api';

export class Entities {
  entities = observable.map({});

  setEntities = (entities) => {
    entities.forEach((entity) => {
      this.entities.set(entity.id, entity);
    });
  };

  addEntity = async (entity) => {
    const newEntity = await addEntityApi(entity);
    this.entities.set(newEntity.id, newEntity);
  };

  editEntity = async (entity) => {
    const updatedEntity = await editEntityApi(entity);
    this.entities.set(updatedEntity.id, updatedEntity);
  };

  removeEntity = async (entity) => {
    await removeEntityApi(entity);
    this.entities.delete(entity.id);
  };

  reset = () => {
    this.entities.clear();
  };

  constructor() {
    makeObservable(this, {
      entities: observable,
      setEntities: action,
      addEntity: action,
      editEntity: action,
      removeEntity: action,
      reset: action,
      entitiesArray: computed,
      entitiesEnabledArray: computed,
    });
  }

  get entitiesArray() {
    return Array.from(this.entities.values());
  }

  get entitiesEnabledArray() {
    return this.entitiesArray.filter(
      (entity) => entity.is_enabled && entity.status === 'VERIFIED',
    );
  }
}

export const entities = new Entities();
window.entities = entities;

export default createContext(entities);
