import axios from 'axios';
import {
  requestOnFulfilled,
  requestOnRejected,
  responseOnFulfilled,
  responseOnRejected,
} from './_common';

const papiFs = axios.create({
  baseURL: FS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

papiFs.interceptors.request.use(requestOnFulfilled, requestOnRejected);
papiFs.interceptors.response.use(responseOnFulfilled, responseOnRejected);

export default papiFs;
