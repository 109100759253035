import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ParadigmModal from '../../../../material/modal';
import EditEmailsForm from './EditEmailsForm';
import { emailsSchema, proceessEmails } from '../../../../utils/common';

const EditEmails = ({ source, onClose, credential }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(null);
  const createEmailsSchema = Yup.object().shape({
    emails: emailsSchema,
  });

  const handleSubmit = async (values) => {
    const emails = proceessEmails(values['emails']);

    try {
      setIsSubmitting(true);
      await source.addEmailsList({
        emails,
      });
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };
  return (
    <ParadigmModal title="Edit Emails" onClose={onClose}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={createEmailsSchema}
        initialValues={{
          emails: source.getEmails.join('\n'),
        }}
      >
        {(props) => (
          <EditEmailsForm
            credential={credential}
            {...props}
            isSubmitting={isSubmitting}
            onClose={onClose}
          />
        )}
      </Formik>
    </ParadigmModal>
  );
};

export default EditEmails;
