import React, { useState, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ParadigmModal from '../../../../material/modal';
import deskContext from '../../../../stores/desk-store';
import InviteMemberForm from './invite-member-form';
import { Link } from '@material-ui/core';

function useBoolean(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  return [value, setTrue, setFalse];
}

export const INIT_MEMBER = {
  first_name: '',
  last_name: '',
  email: '',
  role: 'TR',
};

const InviteMember = () => {
  const [open, openModal, closeModal] = useBoolean(false);
  const { addRequestedUser } = React.useContext(deskContext);

  const inviteMemberSchema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().email().required(),
    role: Yup.string().required(),
  });

  const handleSubmit = async (requestedUser, actions) => {
    try {
      await addRequestedUser(requestedUser);
      closeModal();
    } catch (err) {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Link
        underline="none"
        variant="body1"
        color="primary"
        style={{ lineHeight: '44px' }}
        onClick={openModal}
      >
        Invite to Desk
      </Link>
      {open && (
        <ParadigmModal title="Invite to Desk" onClose={closeModal} noScroll>
          <Formik
            initialValues={INIT_MEMBER}
            onSubmit={handleSubmit}
            validationSchema={inviteMemberSchema}
          >
            {(props) => <InviteMemberForm {...props} onClose={closeModal} />}
          </Formik>
        </ParadigmModal>
      )}
    </>
  );
};

export default React.memo(InviteMember);
