import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import { Field, Form } from 'formik';
import React from 'react';
import ParadigmButton from '../../material/button';
import ParadigmInput from '../../material/input';

const LoginButton = styled(ParadigmButton)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const LoginForm = ({ isSubmitting, status }) => {
  return (
    <Form noValidate>
      <Field
        name="email"
        component={ParadigmInput}
        disabled={isSubmitting}
        placeholder="Enter email"
        autoComplete="email"
        type="email"
        label="Email"
      />
      <Field
        name="password"
        component={ParadigmInput}
        disabled={isSubmitting}
        autoComplete="new-password"
        placeholder="Enter password"
        type="password"
        label="Password"
      />
      {status.twoFaRequired && (
        <Field
          name="totp_token"
          component={ParadigmInput}
          helperText="Check your authentication app for a sign-in code."
          disabled={isSubmitting}
          autoComplete="one-time-code"
          placeholder="Enter Authentication Code"
          type="text"
          label="Code"
        />
      )}
      <Grid container justifyContent="flex-end">
        <LoginButton
          text="Sign in"
          aria-label="login-submit"
          busy={isSubmitting}
          type="submit"
        />
      </Grid>
    </Form>
  );
};

export default LoginForm;
