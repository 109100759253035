import React from 'react';

const UserIcon = (props) => (
  <svg width="18px" height="18px" viewBox="0 0 16 18" {...props}>
    <g stroke="#FFF" fill="none" fillRule="evenodd" opacity=".7">
      <path d="M8.174 9.219a3.86 3.86 0 1 0 0-7.719 3.86 3.86 0 0 0 0 7.719M8.174 10.624c-3.077 0-5.738 1.563-7.032 3.84-.415.73.13 1.64.97 1.64h12.124c.84 0 1.384-.91.969-1.64-1.294-2.277-3.955-3.84-7.031-3.84" />
    </g>
  </svg>
);

export default UserIcon;
