import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    width: 64,
    height: 12,
    position: 'relative',
    '& div': {
      position: 'absolute',
      top: 27,
      width: 11,
      height: 11,
      borderRadius: '50%',
      background: '#fff',
      animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',

      '&:nth-child(1)': {
        left: 6,
        animation: 'fetching-messages-ellipsis1 0.6s infinite',
      },
      '&:nth-child(2)': {
        left: 6,
        animation: 'fetching-messages-ellipsis2 0.6s infinite',
      },
      '&:nth-child(3)': {
        left: 26,
        animation: 'fetching-messages-ellipsis2 0.6s infinite',
      },
      '&:nth-child(4)': {
        left: 45,
        animation: 'fetching-messages-ellipsis3 0.6s infinite',
      },
    },
  },
});

const LoadingIndicator = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingIndicator;
