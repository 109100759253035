import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Field, Form } from 'formik';
import { map, omit } from 'lodash';
import ParadigmButton from '../../../../material/button';
import ParadigmInput from '../../../../material/input';
import { ModalActionsWrapper } from '../../../../material/modal';
import { ROLES } from '../../../../resources/config';

const InviteMemberForm = ({ isSubmitting, onClose }) => {
  const onKeyDown = (keyEvent) => {
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  return (
    <Form noValidate onKeyDown={onKeyDown}>
      <Box display="block">
        <Grid item container spacing={2}>
          <Grid item xs>
            <Field
              name="first_name"
              label="First Name"
              component={ParadigmInput}
              required
              autoFocus
            />
          </Grid>
          <Grid item xs>
            <Field
              name="last_name"
              label="Last Name"
              component={ParadigmInput}
              required
            />
          </Grid>
        </Grid>
        <Field name="email" label="Email" component={ParadigmInput} required />
        <Field
          name="role"
          label="Account level"
          component={ParadigmInput}
          select
        >
          {map(omit(ROLES, ['AD']), (role, short) => (
            <MenuItem key={short} value={short}>
              {role.name}
            </MenuItem>
          ))}
        </Field>
      </Box>
      <ModalActionsWrapper>
        <ParadigmButton
          type="button"
          secondary
          text="Cancel"
          onClick={onClose}
        />
        <ParadigmButton type="submit" busy={isSubmitting} text="Invite" />
      </ModalActionsWrapper>
    </Form>
  );
};

export default InviteMemberForm;
