import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { isEmpty, map } from 'lodash';
import React from 'react';
import NotifyTooltip from '../../../../components/notify-tooltip/notify-tooltip';
import useToggle from '../../../../hooks/use-toggle';
import ParadigmButton from '../../../../material/button';
import ParadigmInput from '../../../../material/input';
import ParadigmModal, { ModalActionsWrapper } from '../../../../material/modal';
import deskContext from '../../../../stores/desk-store';
import { getUserApi } from '../../../../api/auth-api';
import authContext from '../../../../stores/auth-store';

const EditAdmin = ({ children }) => {
  const [open, toggle] = useToggle(false);
  const [traderId, setTraderId] = React.useState('');
  const { traders } = React.useContext(deskContext);
  const auth = React.useContext(authContext);

  const handleSubmit = async () => {
    await traders[traderId].convertTo('AD');
    const freshPermissions = await getUserApi();
    await auth.setLoggedUserRole('TR', freshPermissions);
  };

  const reset = () => {
    setTraderId('');
    toggle();
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: toggle,
      })}
      {open && (
        <ParadigmModal
          title={
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              Change Desk Admin
              <NotifyTooltip
                type="warn"
                text="Warning: You will lose Admin privileges if you assign your
                      Desk Admin role to another Trader."
              />
            </span>
          }
          onClose={reset}
        >
          {!isEmpty(traders) ? (
            <ParadigmInput
              select
              label="Trader"
              placeholder="Select admin"
              value={traderId || ''}
              required
              onChange={({ target: { value } }) => setTraderId(value)}
              autoFocus
            >
              {map(traders, (member) => (
                <MenuItem key={member.id} value={member.id}>
                  {member.name}
                </MenuItem>
              ))}
            </ParadigmInput>
          ) : (
            <Typography>No traders found.</Typography>
          )}
          <ModalActionsWrapper>
            <ParadigmButton secondary text="Cancel" onClick={reset} />
            {!isEmpty(traders) && (
              <ParadigmButton
                text="Assign Admin"
                onClick={handleSubmit}
                disabled={!traderId}
              />
            )}
          </ModalActionsWrapper>
        </ParadigmModal>
      )}
    </>
  );
};

export default React.memo(EditAdmin);
