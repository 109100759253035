import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useToggle from '../../../../../hooks/use-toggle';
import ParadigmModal from '../../../../../material/modal';
import AddKeyForm from './add-keys-form';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';

export const AddKeyIcon = (props) => (
  <Tooltip title="Add new API credentials" enterDelay={300}>
    <IconButton color="primary" {...props}>
      <Add fontSize="small" />
    </IconButton>
  </Tooltip>
);

const AddKey = ({ children, source }) => {
  const [open, toggle] = useToggle(false);
  const { addApiKey } = source;

  const addKeySchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .max(64, 'Ensure this field has no more than 64 characters.'),
    access_key: Yup.string()
      .required('Client ID is required')
      .max(32, 'Ensure this field has no more than 32 characters.'),
    entity: Yup.object().required('Entity is required'),
    access_secret: Yup.string()
      .required('Access secret is required')
      .max(64, 'Ensure this field has no more than 64 characters.'),
  });

  const handleSubmit = (key) => {
    toggle();
    addApiKey(key);
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: toggle,
      })}
      {open && (
        <ParadigmModal title="Add API Credentials" onClose={toggle}>
          <Formik
            initialValues={{
              name: '',
              access_key: '',
              access_secret: '',
              entity: '',
              venue: source.shortName,
            }}
            onSubmit={handleSubmit}
            validationSchema={addKeySchema}
          >
            {(props) => (
              <AddKeyForm {...props} source={source} onClose={toggle} />
            )}
          </Formik>
        </ParadigmModal>
      )}
    </>
  );
};

export default React.memo(AddKey);
