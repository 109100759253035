import React from 'react';
import { Formik } from 'formik';
import { omitBy } from 'lodash';
import * as Yup from 'yup';
import ParadigmModal from '../../../../../material/modal';
import entitiesContext from '../../../../../stores/entities-store';
import EditKeysForm from './edit-keys-form';

const EditKeys = ({ onClose, apiKey }) => {
  const entitiesStore = React.useContext(entitiesContext);
  const keysSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    entity: Yup.object().required('Entity is required'),
  });

  const handleSubmit = (values) => {
    apiKey.update(omitBy(values, (i) => !i)).finally(onClose);
  };

  return (
    <ParadigmModal title="Edit API Credentials" onClose={onClose}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={keysSchema}
        initialValues={{
          name: apiKey.name,
          access_key: '',
          access_secret: '',
          entity: entitiesStore.entities.get(apiKey.entity.id),
        }}
      >
        {(props) => (
          <EditKeysForm apiKey={apiKey} {...props} onClose={onClose} />
        )}
      </Formik>
    </ParadigmModal>
  );
};

export default EditKeys;
