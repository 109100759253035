import React from 'react';

export default function CopyIcon({ className, handleClick }) {
  return (
    <span className={className} onClick={handleClick}>
      <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1">
        <title>3496D707-4F00-48AE-BAE0-EBAC72E7040B</title>
        <g
          id="API"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-880.000000, -393.000000)"
            stroke="#89B5F1"
            strokeWidth="2"
          >
            <g id="modal" transform="translate(555.000000, 166.000000)">
              <g
                id="app-/-chat-/-input-/-select"
                transform="translate(29.000000, 202.000000)"
              >
                <g
                  id="icon-/-copy"
                  transform="translate(297.000000, 26.000000)"
                >
                  <path
                    d="M7.00625123,8.15065233 L1.1444011,8.15065233 C0.512535776,8.15065233 0,7.63845847 0,7.00625123 L0,1.1444011 C0,0.512193858 0.512535776,-0.000341918463 1.1444011,-0.000341918463 L7.00625123,-0.000341918463 C7.63845847,-0.000341918463 8.15099424,0.512193858 8.15099424,1.1444011 L8.15099424,7.00625123 C8.15099424,7.63845847 7.63845847,8.15065233 7.00625123,8.15065233 Z"
                    id="Stroke-1"
                    fill="#89B5F1"
                    opacity="0.5"
                  ></path>
                  <path
                    d="M9.72931048,10.8655397 L3.86746035,10.8655397 C3.23559503,10.8655397 2.72305925,10.3533459 2.72305925,9.72113863 L2.72305925,3.8592885 C2.72305925,3.22708126 3.23559503,2.71454548 3.86746035,2.71454548 L9.72931048,2.71454548 C10.3615177,2.71454548 10.8740535,3.22708126 10.8740535,3.8592885 L10.8740535,9.72113863 C10.8740535,10.3533459 10.3615177,10.8655397 9.72931048,10.8655397 Z"
                    id="Stroke-1"
                    fill="#091523"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
}
