import api from './_api';

const getSpotInfoApi = () => api.get('/trading-desk/spot/info/');

const updateSpotApi = (data) => api.patch('/trading-desk/spot/update/', data);

const updateSpotRfqApi = ({ id, ...data }) =>
  api.put(`/trading-desk/spot/rfq-config/${id}/update/`, data);

export { getSpotInfoApi, updateSpotApi, updateSpotRfqApi };
