export const COLORS = {
  NV: '#003366',
  GE: '#339966',
  TL: '#68A2BF',
  RD: '#BD264A',
  GR: '#664BAD',
  OG: '#E08F39',
  LC: '#CC99FF',
  YL: '#F6597F',
};

export const ROLES = {
  AD: {
    name: 'Desk Administrator',
    permissions: {
      viewAllData: {
        name: 'View All Data',
        value: true,
      },
      manageCounterparties: {
        name: 'Manage Counterparties',
        value: true,
      },
      trade: {
        name: 'Trade',
        value: true,
      },
      manageMembers: {
        name: 'Manage Members',
        value: true,
      },
    },
  },
  TR: {
    name: 'Trader',
    permissions: {
      viewAllData: {
        name: 'View All Data',
        value: true,
      },
      manageCounterparties: {
        name: 'Manage Counterparties',
        value: true,
      },
      trade: {
        name: 'Trade',
        value: true,
      },
      manageMembers: {
        name: 'Manage Members',
        value: false,
      },
    },
  },
  AN: {
    name: 'Analyst',
    permissions: {
      viewAllData: {
        name: 'View All Data',
        value: true,
      },
      manageCounterparties: {
        name: 'Manage Counterparties',
        value: true,
      },
      trade: {
        name: 'Trade',
        value: false,
      },
      manageMembers: {
        name: 'Manage Members',
        value: false,
      },
    },
  },
  VI: {
    name: 'Viewer',
    permissions: {
      viewAllData: {
        name: 'View All Data',
        value: true,
      },
      manageCounterparties: {
        name: 'Manage Counterparties',
        value: false,
      },
      trade: {
        name: 'Trade',
        value: false,
      },
      manageMembers: {
        name: 'Manage Members',
        value: false,
      },
    },
  },
};

export const PRODUCTS_OPTIONS = [
  {
    value: 'FT',
    label: 'Futures',
  },
  {
    value: 'OP',
    label: 'Options',
  },
];
