import React from 'react';
import { Observer } from 'mobx-react-lite';
import { Field, Form } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import ParadigmInput from '../../../../material/input';
import ParadigmButton from '../../../../material/button';
import { ModalActionsWrapper } from '../../../../material/modal';
import { makeStyles } from '@material-ui/core/styles';
import { ACCESS_LEVELS } from '#/pages/api-management/constants';

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: 5,
    opacity: 0.5,
  },
}));

const CreateCredentialForm = ({ onClose }) => {
  const classes = useStyles();
  return (
    <Observer>
      {() => (
        <Form noValidate>
          <Field
            name="name"
            label="Name"
            component={ParadigmInput}
            placeholder="Name"
            required
            autoFocus
          />
          <Field
            name="allowed_cidrs"
            label="IP Whitelist"
            component={ParadigmInput}
            rows={5}
            multiline
          />
          <p className={classes.text}>
            Enter each IP address on a separate line
          </p>
          <Field
            name="read_only"
            label="Access Level"
            placeholder="Select Access Level"
            component={ParadigmInput}
            select
            required
          >
            {ACCESS_LEVELS.map((accessLevel) => (
              <MenuItem key={accessLevel.value} value={accessLevel.value}>
                {accessLevel.label}
              </MenuItem>
            ))}
          </Field>
          <ModalActionsWrapper>
            <ParadigmButton secondary text="Cancel" onClick={onClose} />
            <ParadigmButton type="submit" text="Create" />
          </ModalActionsWrapper>
        </Form>
      )}
    </Observer>
  );
};

export default CreateCredentialForm;
