import api from './_api';

const updateDeskAddressApi = (data) =>
  api.put('/trading-desk/mailing-address/update/', data);

const getDeskInfoApi = () => api.get('/trading-desk/info/');

const updateMemberApi = ({ memberId, ...data }) =>
  api.patch(`/trading-desk/member/${memberId}/update/`, data);

const addMemberApi = (member) =>
  api.post(`/trading-desk/member/create/`, member);

const removeMemberApi = (id) => api.put(`/trading-desk/member/${id}/remove/`);

const getEntitiesApi = () => api.get(`/trading-desk/entities/`);

const addEntityApi = (data) => api.post('/trading-desk/entity/create/', data);
const getEntityVenueRestrictionsApi = (entityId, venueId) =>
  api.get(`trading-desk/entity/${entityId}/venue/${venueId}/restrictions`);

const editEntityApi = (data) =>
  api.patch(`/trading-desk/entity/${data.id}/update/`, data);

const removeEntityApi = (data) =>
  api.delete(`/trading-desk/entity/${data.id}/remove/`);

const exportLogsApi = (data) => api.post(`/trading-desk/export-logs/`, data);

const updateLrfqApi = ({ id, data }) =>
  api.patch(`/trading-desk/product-configs/${id}/`, data);

const addRequestedUserApi = (data) =>
  api.post('onboarding/requested-users/', data);

const getRequestedUsersApi = () => api.get(`/onboarding/requested-users/`);

const getEmailsApi = () => api.get(`/trading-desk/trade-recap-email-list/`);

const addEmailsApi = (data) =>
  api.post('/trading-desk/trade-recap-email-list/', data);

const validateVenueKeyApi = (
  { venue, apiKey, apiSecret, subaccount },
  options = {},
) =>
  api.post(
    '/trading-desk/venue-keys/validation/',
    {
      venue,
      api_key: apiKey,
      api_secret: apiSecret,
      subaccount,
    },
    options,
  );

export {
  updateLrfqApi,
  updateDeskAddressApi,
  getDeskInfoApi,
  updateMemberApi,
  addMemberApi,
  removeMemberApi,
  getEntitiesApi,
  addEntityApi,
  getEntityVenueRestrictionsApi,
  editEntityApi,
  removeEntityApi,
  exportLogsApi,
  addRequestedUserApi,
  getRequestedUsersApi,
  getEmailsApi,
  addEmailsApi,
  validateVenueKeyApi,
};
