import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

const ParadigmMenu = ({ options, anchor, onClose }) => {
  return (
    <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={onClose}>
      {options.map(({ onClick, title }) => (
        <MenuItem
          key={title}
          disabled={!onClick}
          {...(onClick && {
            onClick: () => {
              onClick();
              onClose();
            },
          })}
        >
          {title}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ParadigmMenu;
