import api from './_api';

const getOptionsInfoApi = (venue) => api.get(`/trading-desk/${venue}/info/`);

const updateOptionsApi = (venue, data) =>
  api.patch(`/trading-desk/${venue}/update/`, data);

const updateOptionsRfqApi = (venue, { id, ...data }) =>
  api.put(`/trading-desk/${venue}/options-config/${id}/update/`, data);

const updateVenueAPICredentialsApi = (venue, { id, ...data }) =>
  api.patch(`trading-desk/${venue}/api-credential/${id}/update/`, data);

const addVenueAPICredentialsApi = (venue, data) =>
  api.post(`trading-desk/${venue}/api-credential/create/`, data);

const removeVenueAPICredentialsApi = (venue, id) =>
  api.delete(`trading-desk/${venue}/api-credential/${id}/remove/`);

export {
  getOptionsInfoApi,
  updateOptionsApi,
  updateOptionsRfqApi,
  updateVenueAPICredentialsApi,
  addVenueAPICredentialsApi,
  removeVenueAPICredentialsApi,
};
