import { HelpOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  icon: {
    cursor: 'pointer',
    marginLeft: 3,
    fontSize: 15,
  },
}));

const ParadigmHelp = (props) => {
  const classes = useStyles(props);
  return <HelpOutline {...props} className={classes.icon} />;
};

export default React.memo(ParadigmHelp);
