import axios from 'axios';
import {
  requestOnFulfilled,
  requestOnRejected,
  responseOnFulfilled,
  responseOnRejected,
} from './_common';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(requestOnFulfilled, requestOnRejected);
api.interceptors.response.use(responseOnFulfilled, responseOnRejected);

export default api;
