import { action, computed, observable, makeObservable } from 'mobx';
import { PRODUCTS_OPTIONS } from '../../resources/config';

class Products {
  activeProducts;
  primaryRole;
  exposed;
  discoverable;
  enabled;

  setProduct = (product, updateProductApi) => {
    const {
      active_financial_products,
      maker_rank_is_exposed,
      maker_rank,
      rfq_is_enabled,
      is_discoverable,
    } = product;

    if (!this.updateProductApi && updateProductApi) {
      this.updateProductApi = updateProductApi;
    }
    this.activeProducts = active_financial_products;
    this.exposed = maker_rank_is_exposed;
    this.enabled = rfq_is_enabled;
    this.discoverable = is_discoverable;
    this.rank = maker_rank;
  };

  update = async (values) => {
    const _values = { ...values };
    if (PRODUCTS_OPTIONS.length === _values.active_financial_products.length) {
      _values.active_financial_products = 'AL';
    } else {
      _values.active_financial_products =
        _values.active_financial_products[0] || null;
    }
    const response = await this.updateProductApi(_values);
    this.setProduct(response);
  };

  toggleDiscoverable = async () => {
    this.discoverable = !this.discoverable;
    try {
      await this.updateProductApi({ is_discoverable: this.discoverable });
    } catch (e) {
      this.discoverable = !this.discoverable;
    }
  };

  constructor() {
    makeObservable(this, {
      activeProducts: observable,
      primaryRole: observable,
      exposed: observable,
      discoverable: observable,
      enabled: observable,
      setProduct: action,
      update: action,
      toggleDiscoverable: action,
      productsArray: computed,
      products: computed,
    });
  }

  get productsArray() {
    if (!this.activeProducts) return [];
    if (this.activeProducts === 'AL')
      return PRODUCTS_OPTIONS.map((p) => p.value);
    const product = PRODUCTS_OPTIONS.find(
      (p) => p.value === this.activeProducts,
    );
    return product ? [product.value] : [];
  }

  get products() {
    return this.productsArray.map(
      (p) => PRODUCTS_OPTIONS.find((i) => p === i.value).label,
    );
  }
}

export default Products;
