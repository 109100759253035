import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PageSubTitle from '../../components/page-sub-title/page-sub-title';
import AdminDetails from './admin-details/admin-details';
import AddMember from './components/add-member/add-member';
import MembersTable from './members-table/members-table';
import authContext from '../../stores/auth-store';
import { Observer } from 'mobx-react-lite';
import InviteMember from './components/invite-member/invite-member';

const useStyles = makeStyles((theme) => ({
  table: {
    margin: `16px -40px 0`,
  },
  adminDetails: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(4)}px`,
  },
}));

const Profile = (props) => {
  const auth = React.useContext(authContext);
  const classes = useStyles(props);

  return (
    <Observer>
      {() => (
        <div data-testid="desk-management">
          <PageSubTitle text="DESK PROFILE" />
          <div className={classes.adminDetails}>
            <AdminDetails />
          </div>
          <Divider style={{ marginBottom: 30 }} />
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <PageSubTitle text="MEMBERS" />
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                {auth.canManageTradingDesk && (
                  <Grid item>
                    <InviteMember />
                  </Grid>
                )}
                {auth.canManageTradingDesk && (
                  <Grid item>
                    <AddMember />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.table}>
            <MembersTable />
          </div>
        </div>
      )}
    </Observer>
  );
};

export default Profile;
