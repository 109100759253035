import { isUndefined, isEmpty } from 'lodash';
import * as Yup from 'yup';

const formatCurrency = ({ amount, digits = 2, symbol = '$', short }) => {
  if (isUndefined(amount)) return '';
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: short ? 'K' : 'Thousands (K)' },
    { value: 1e6, symbol: short ? 'M' : 'Million (M)' },
    { value: 1e9, symbol: short ? 'B' : 'Billion (B)' },
    { value: 1e12, symbol: short ? 'T' : 'Trillion (T)' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (amount >= si[i].value) {
      break;
    }
  }
  return `${symbol}${(amount / si[i].value).toFixed(digits).replace(rx, '$1')}${
    si[i].symbol ? ` ${si[i].symbol}` : ''
  }`;
};

const formatCurrencyShort = ({ amount, digits, symbol }) => {
  return formatCurrency({ amount, digits, symbol, short: true });
};

const formatCurrencyFull = ({ amount, digits, symbol }) => {
  return formatCurrency({ amount, digits, symbol, short: false });
};

const CurrencyFormatter = {
  full: formatCurrencyFull,
  short: formatCurrencyShort,
};

const allowedIpSchema = Yup.string().test(
  'ipAddress',
  'Invalid IP address',
  (value) => {
    if (!isEmpty(value)) {
      let ips = value.split(/\r?\n/);
      let rx =
        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;
      for (let i = 0; i < ips.length; i++) {
        if (!rx.test(ips[i]) && !isEmpty(ips[i])) {
          return false;
        }
      }
    }
    return true;
  },
);

const emailsSchema = Yup.string().test(
  'emails',
  'Invalid email address',
  (value) => {
    if (!isEmpty(value)) {
      let email = value.split(/\r?\n/);
      let rx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      for (let i = 0; i < email.length; i++) {
        if (!rx.test(email[i]) && !isEmpty(email[i])) {
          return false;
        }
      }
    }
    return true;
  },
);
const proceessEmails = (emails) => {
  return !isEmpty(emails)
    ? emails.split(/\r?\n/).filter((item) => item.length > 0)
    : [];
};
export { CurrencyFormatter, allowedIpSchema, emailsSchema, proceessEmails };
