import React from 'react';
import * as Yup from 'yup';
import { Observer } from 'mobx-react-lite';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import CalendarIcon from '../../../assets/calendar-icon';
import UserIcon from '../../../assets/user-icon';

import { exportLogsApi } from '../../../api/desk-api';

import PageSubTitle from '../../../components/page-sub-title/page-sub-title';

import ParadigmButton from '../../../material/button';
import ParadigmInput from '../../../material/input';

import authContext from '../../../stores/auth-store';
import deskContext from '../../../stores/desk-store';

import ExportSuccessModal from './export-success-modal';
import MemberSelectModal from './select-members-modal';
import DatepickerModal from './datepicker-modal';

/**
 * ISO 8601 date and time format with timezone
 *
 * @example "2021-12-31:23:59:59.999+10:00" // (Brisbane, Austrailia)
 * @example "2021-12-31:23:59:59.999-07:00" // (San Francisco, CA, USA)
 */
const ISO_8601_WITH_TIMEZONE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.mmmxxx";

const LOG_KINDS = [
  { code: 'DT', label: 'DRFQ - Trades' },
  { code: 'GT', label: 'GRFQ - Trades' },
];

function getLogKind(code) {
  const logKind = LOG_KINDS.find((lk) => lk.code === code);
  if (logKind == null)
    throw new TypeError(`Log kind with '${code}' code does not exist.`);
  return logKind;
}

const LogExport = () => {
  const auth = React.useContext(authContext);
  const desk = React.useContext(deskContext);

  React.useEffect(() => {
    desk.fetch();
  }, [desk]);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [kind, setKind] = React.useState(getLogKind('DT'));
  const [members, setMembers] = React.useState([]);
  const [email, setEmail] = React.useState('');
  const [validEmail, setValidEmail] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setExporting(true);
    try {
      await exportLogsApi({
        from_date: format(startOfDay(startDate), ISO_8601_WITH_TIMEZONE_FORMAT),
        to_date: format(endOfDay(endDate), ISO_8601_WITH_TIMEZONE_FORMAT),
        log_type: kind.code,
        email,
        ...(auth.isAdmin && {
          trading_desk_member_ids: members.map((m) => m.memberId),
        }),
      });
    } finally {
      setExporting(false);
    }
    setStartDate(null);
    setEndDate(null);
    setMembers([]);
    setEmail('');
    setValidEmail(false);
    setShowSuccess(true);
  };

  return (
    <Observer>
      {() => (
        <div>
          <PageSubTitle text="LOGS" />
          <Box width={300} component="form" onSubmit={handleSubmit}>
            <Grid item>
              <ParadigmInput
                className="different"
                label="Type"
                select
                value={kind.code}
                onChange={({ target: { value } }) => setKind(getLogKind(value))}
              >
                {LOG_KINDS.map((logKind) => (
                  <MenuItem key={logKind.code} value={logKind.code}>
                    {logKind.label}
                  </MenuItem>
                ))}
              </ParadigmInput>
            </Grid>
            <Grid container direction="column">
              <Grid item>
                <MemberSelectModal
                  value={members}
                  onChange={setMembers}
                  memberOptions={desk.mailingMembers}
                  disabled={true}
                >
                  <ParadigmInput
                    className="different"
                    label="Desk Members"
                    placeholder="Select Members"
                    endAdornment={
                      <Box style={{ display: 'flex', marginRight: 2 }}>
                        <UserIcon />
                      </Box>
                    }
                    readOnly
                    {...(!auth.isAdmin
                      ? {
                          disabled: true,
                          defaultValue: `${auth.user.first_name} ${auth.user.last_name}`,
                        }
                      : {
                          disabled: true,
                          value: !members.length
                            ? 'All'
                            : members.map((m) => m.name).join(', '),
                          onChange: () => {},
                        })}
                  />
                </MemberSelectModal>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs>
                  <DatepickerModal
                    value={startDate}
                    onChange={setStartDate}
                    disableFuture
                    {...(endDate && { maxDate: endDate })}
                    title="Select From"
                  >
                    <ParadigmInput
                      label="From"
                      className="different"
                      placeholder="Start Date"
                      value={!startDate ? '' : format(startDate, 'dd MMM yy')}
                      onChange={() => {}}
                      readOnly
                      endAdornment={
                        <Box style={{ display: 'flex', marginRight: 2 }}>
                          <CalendarIcon />
                        </Box>
                      }
                    />
                  </DatepickerModal>
                </Grid>
                <Grid item xs>
                  <DatepickerModal
                    value={endDate}
                    onChange={setEndDate}
                    disableFuture
                    {...(startDate && { minDate: startDate })}
                    title="Select To"
                  >
                    <ParadigmInput
                      className="different"
                      label="To"
                      placeholder="End date"
                      value={!endDate ? '' : format(endDate, 'dd MMM yy')}
                      onChange={() => {}}
                      readOnly
                      endAdornment={
                        <Box style={{ display: 'flex', marginRight: 2 }}>
                          <CalendarIcon />
                        </Box>
                      }
                    />
                  </DatepickerModal>
                </Grid>
              </Grid>
              <Grid item>
                <ParadigmInput
                  className="different"
                  label="Email"
                  placeholder="Enter Email"
                  helperText={`${kind.label} logs will be sent to this email`}
                  type="email"
                  value={email}
                  onChange={(e) => {
                    Yup.string()
                      .email()
                      .validate(e.target.value)
                      .then(() => setValidEmail(true))
                      .catch(() => setValidEmail(false));
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Box height={16} />
              <Grid item>
                <ParadigmButton
                  text="Request"
                  type="submit"
                  disabled={!startDate || !endDate || !email || !validEmail}
                  busy={exporting}
                />
              </Grid>
            </Grid>
          </Box>
          {showSuccess && (
            <ExportSuccessModal
              closeModal={() => setShowSuccess(false)}
              logKind={kind.label.toLowerCase()}
            />
          )}
        </div>
      )}
    </Observer>
  );
};

export default LogExport;
