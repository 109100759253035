import { action, observable, makeObservable } from 'mobx';
import { updateMemberApi } from '../../api/desk-api';
import { COLORS } from '../../resources/config';
import { Roles } from './roles';
import { desk } from '../desk-store';

export class Member extends Roles {
  type;
  tradingEnabled;

  toggleTradingEnabled = async () => {
    try {
      this.tradingEnabled = !this.tradingEnabled;
      const data = {
        memberId: this.memberId,
        is_trading_enabled: this.tradingEnabled,
      };
      await updateMemberApi(data);
    } catch (err) {
      this.tradingEnabled = !this.tradingEnabled;
    }
  };

  remove = () => desk.removeMember(this);

  convertTo = async (role) => {
    const data = { user: { id: this.id }, role };
    await desk.addMember(data);
  };

  constructor(member) {
    super(member);

    makeObservable(this, {
      type: observable,
      tradingEnabled: observable,
      toggleTradingEnabled: action,
      remove: action,
      convertTo: action,
    });

    this.name = `${member.user.first_name} ${member.user.last_name}`;
    this.type = member.role;
    this.initials = this.name
      .split(' ')
      .map(([firstChar]) => firstChar.toUpperCase())
      .join('');
    this.color = COLORS[member.user.color];
    this.tradingEnabled = member.is_trading_enabled;
    this.memberId = member.id;
    this.id = member.user.id;
  }
}
