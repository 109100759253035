import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Observer } from 'mobx-react-lite';
import { Field, Form } from 'formik';
import ParadigmInput from '../../../material/input';
import ParadigmButton from '../../../material/button';
import { ModalActionsWrapper } from '../../../material/modal';
import entitiesContext from '../../../stores/entities-store';
import { getEntityVenueRestrictionsApi } from '#/api/desk-api';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  msg: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  error: {
    marginTop: 20,
    border: '1px solid #F3E500',
    borderRadius: '7px',
    paddingLeft: 17,
    paddingRight: 32,
    color: '#97B2BD',
  },
}));
function useEntityRestrictions(entities, venue) {
  const [restrictions, setRestrictions] = React.useState({
    loading: true,
    restrictions: {},
  });

  React.useEffect(() => {
    const promises = entities.map(async (entity) => {
      try {
        const response = await getEntityVenueRestrictionsApi(
          entity.id,
          venue.shortName,
        );
        return {
          [entity.id]: {
            isRestricted: response.restricted,
            message: response.message,
          },
        };
      } catch (e) {
        return {
          [entity.id]: { networkError: e },
        };
      }
    });

    Promise.all(promises).then((restrictions) =>
      setRestrictions({
        loading: false,
        restrictions: restrictions.reduce(Object.assign, {}),
      }),
    );
  }, [entities, venue.shortName, setRestrictions]);

  return restrictions;
}

const AddKeyForm = ({ isSubmitting, onClose, source, venue }) => {
  const entitiesStore = React.useContext(entitiesContext);
  const classes = useStyles();
  const entities = React.useMemo(
    () => entitiesStore.entitiesArray,
    [entitiesStore],
  );
  const entityRestrictions = useEntityRestrictions(entities, source);

  const entityOptions = React.useMemo(() => {
    return entities.map((entity) => {
      if (entityRestrictions.loading) {
        return (
          <MenuItem key={entity.id} value={entity} disabled={true}>
            {entity.name} (...)
          </MenuItem>
        );
      } else {
        const restriction = entityRestrictions.restrictions[entity.id];
        if (restriction.networkError) {
          return (
            <MenuItem key={entity.id} value={entity} disabled={true}>
              {entity.name}
              <span css="color: red; margin-left: 12px">Network Error</span>
            </MenuItem>
          );
        }
        return (
          <MenuItem
            key={entity.id}
            value={entity}
            disabled={restriction.isRestricted}
          >
            {entity.name}
            {restriction.message && (
              <span css="color: red; margin-left: 12px">
                ({restriction.message})
              </span>
            )}
          </MenuItem>
        );
      }
    });
  }, [entityRestrictions, entities]);

  const venueHasDelegatedWallet = !['Thetanuts'].includes(source.name);

  return (
    <Observer>
      {() => (
        <Form noValidate>
          <Field
            name="name"
            label="Name"
            component={ParadigmInput}
            placeholder="Name"
            required
            autoFocus
          />
          <Field
            name="entity"
            label="Entity"
            placeholder="Entity"
            component={ParadigmInput}
            select
            required
          >
            {entityOptions}
          </Field>
          <Field
            name="swap_contract_address"
            label="Swap Contract Address"
            component={ParadigmInput}
            required
            placeholder="Swap Contract Address"
          />
          <Field
            name="bidding_token_address"
            label="Bidding Token Address"
            component={ParadigmInput}
            required
            placeholder="Bidding Token Address"
          />
          <Field
            name="wallet_address"
            label="Wallet Address"
            component={ParadigmInput}
            required
            placeholder="Wallet Address"
          />
          {venueHasDelegatedWallet && (
            <Field
              name="delegated_wallet_address"
              label="Delegated Wallet Address"
              component={ParadigmInput}
              placeholder="Wallet used to sign quote."
            />
          )}
          <div className={classes.error}>
            <p className={classes.msg}>
              Registered wallets must be connected with {venue} and authorized
              for Vault actions.
            </p>
          </div>
          <ModalActionsWrapper>
            <ParadigmButton secondary text="Cancel" onClick={onClose} />
            <ParadigmButton type="submit" busy={isSubmitting} text="Add" />
          </ModalActionsWrapper>
        </Form>
      )}
    </Observer>
  );
};

export default AddKeyForm;
