import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Observer } from 'mobx-react-lite';
import * as PropTypes from 'prop-types';
import React from 'react';
import useToggle from '../hooks/use-toggle';
import globalContext from '../stores/global-store';
import ParadigmConfirmModal from '../material/confirmation-modal';
import { ErrorOutline } from '@material-ui/icons';
import Header from './header/header';
import SideBar from './sidebar/sidebar';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  body: {
    flex: 1,
  },
  main: {
    padding: theme.spacing(5),
    paddingBottom: 0,
    height: 'calc(100vh - 75px)',
    overflowY: 'scroll',
  },
}));

const Layout = ({ children, ...props }) => {
  const classes = useStyles(props);
  const [open, toggle] = useToggle();
  const globalStore = React.useContext(globalContext);

  return (
    <Observer>
      {() => (
        <>
          <Header toggle={toggle} />
          <Grid container className={classes.body}>
            <SideBar open={open} toggle={toggle} />
            <Content item xs>
              <main data-testid="main" className={classes.main}>
                {children}
              </main>
            </Content>
            {globalStore.error && (
              <ParadigmConfirmModal
                confirmText={'Ok'}
                icon={<ErrorOutline color="error" />}
                title="Oops!"
                message={globalStore.error}
                onConfirm={() => globalStore.setError(null)}
              />
            )}
          </Grid>
        </>
      )}
    </Observer>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

const Content = styled(Grid)`
  max-width: calc(100vw - 250px);
`;

export default Layout;
