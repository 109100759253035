import { keyBy, find } from 'lodash';
import {
  action,
  observable,
  computed,
  makeObservable,
  runInAction,
} from 'mobx';
import { createContext } from 'react';
import {
  getInfoApi,
  updateRfqApi,
  addWalletAddressApi,
  removeWalletAddressApi,
} from '../api/products-api';
import AccessKey from './classes/dov/dov-access-key';
import OptionsRfq from './classes/options/options-rfq';
import Products from './classes/product-store';

export class Thetanuts extends Products {
  name = 'Thetanuts';
  shortName = 'TTN';
  longName = 'thetanuts';
  rfq;
  accessKeys = {};
  ready;

  setRfq = (optionConfigs, enabled) => {
    this.rfq = keyBy(
      optionConfigs.map(
        (optionConfig) => new OptionsRfq(optionConfig, updateRfqApi, this.name),
      ),
      'id',
    );
  };

  setApiKeys = (keys) => {
    this.accessKeys = keyBy(
      keys.map((i) => new AccessKey(i)),
      'id',
    );
  };

  addApiKey = async (key) => {
    const createdKey = await addWalletAddressApi({
      ...key,
      entity: key.entity.id,
    });
    const newKeys = new AccessKey({ ...createdKey, entity: key.entity });
    this.accessKeys[newKeys.id] = newKeys;
  };

  removeApiKey = async (key) => {
    await removeWalletAddressApi(key.id);
    delete this.accessKeys[key.id];
  };

  fetch = async () => {
    const thetanutsInfo = await getInfoApi(this.longName);
    this.setRfq(thetanutsInfo.configs, thetanutsInfo.rfq_is_enabled);
    this.setApiKeys(thetanutsInfo.api_credentials);
    runInAction(() => (this.ready = true));
  };

  reset = () => {
    this.rfq = null;
    this.ready = null;
  };

  constructor() {
    super();

    makeObservable(this, {
      shortName: observable,
      longName: observable,
      rfq: observable,
      accessKeys: observable,
      ready: observable,
      setRfq: action,
      setApiKeys: action,
      addApiKey: action,
      removeApiKey: action,
      fetch: action,
      reset: action,
      defaultPair: computed,
    });
  }

  get defaultPair() {
    if (!this.ready) return { id: '', isEnabled: false };
    return find(
      this.rfq,
      (optionConfig) => optionConfig && optionConfig.pair.includes('BTC'),
    );
  }
}

export const thetanuts = new Thetanuts();

export default createContext(thetanuts);
