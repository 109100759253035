import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Add } from '@material-ui/icons';
import PageSubTitle from '../../components/page-sub-title/page-sub-title';
import authContext from '../../stores/auth-store';
import AddEntity from './components/add-entity/add-entity';
import EntitiesTable from './entities-table/entities-table';
import palette from '#/resources/palette';

const Entities = () => {
  const auth = React.useContext(authContext);

  const AddEntityIcon = React.useMemo(
    () => (
      <Tooltip title="Add new entity" enterDelay={300}>
        <div
          style={{
            color: palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          Add Entity
          <IconButton color="primary">
            <Add />
          </IconButton>
        </div>
      </Tooltip>
    ),
    [],
  );

  return (
    <div data-testid="entities">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageSubTitle text={auth.isBusiness ? 'ENTITIES' : 'USER PROFILE'} />
        </Grid>
        <Grid item>
          {auth.canManageTradingDesk && auth.isBusiness && (
            <Grid item>
              <AddEntity children={AddEntityIcon} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <div style={{ margin: '16px -40px 0' }}>
        <EntitiesTable />
      </div>
    </div>
  );
};

export default Entities;
