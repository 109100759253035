import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import Contrast from '../../../components/contrast/contrast';
import EditAdmin from '../components/edit-admin/edit-admin';
import deskContext from '../../../stores/desk-store';
import authContext from '../../../stores/auth-store';

const AdminDetails = () => {
  const desk = React.useContext(deskContext);
  const auth = React.useContext(authContext);

  return (
    <Observer>
      {() => (
        <Grid container spacing={2} data-testid="admin-details">
          <Grid item xs>
            <Grid container direction="column" spacing={2}>
              <Grid item container>
                <Grid item style={{ width: 120 }}>
                  <Typography color="textSecondary">Firm Name</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>{desk.firm}</Typography>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item style={{ width: 120 }}>
                  <Typography color="textSecondary">Ticker</Typography>
                </Grid>
                <Grid item>
                  <Contrast text={desk.ticker} color="secondary" />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item style={{ width: 120 }}>
                  <Typography color="textSecondary">Desk Admin</Typography>
                </Grid>
                <Grid item xs container spacing={2} alignItems="center">
                  {desk.admin && (
                    <Grid item>
                      <Typography>{desk.admin.name}</Typography>
                    </Grid>
                  )}
                  {auth.canManageTradingDesk && (
                    <Grid item>
                      <EditAdmin>
                        <Tooltip title="Set new admin" enterDelay={800}>
                          <Link
                            underline="none"
                            variant="body1"
                            color="textSecondary"
                          >
                            {desk.admin ? 'Edit' : 'Set admin'}
                          </Link>
                        </Tooltip>
                      </EditAdmin>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Observer>
  );
};

export default AdminDetails;
