import { styled } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { InfoOutlined, ReportProblemOutlined } from '@material-ui/icons';
import ParadigmHelp from '../../material/help-outline';
import React from 'react';

const ICON = {
  info: <InfoOutlined fontSize="small" color="primary" />,
  warn: <ReportProblemOutlined fontSize="small" color="error" />,
};

const TRIGGER = {
  info: <ParadigmHelp color="action" />,
  warn: (
    <ReportProblemOutlined
      fontSize="small"
      color="error"
      style={{ cursor: 'pointer' }}
    />
  ),
};

const TriggerWrapper = styled('span')(({ theme, flow }) => ({
  marginLeft: flow === 'inLine' && theme.spacing(1),
  display: 'inline-flex',
  alignItems: 'center',
}));

const NotifyTooltip = ({ text, type, flow, ...props }) => {
  const icon = ICON[type];
  const trigger = TRIGGER[type];
  return (
    <Tooltip
      {...props}
      title={
        <Grid container spacing={1}>
          <Grid item xs="auto">
            {icon}
          </Grid>
          <Grid item xs>
            {text}
          </Grid>
        </Grid>
      }
    >
      <TriggerWrapper flow={flow}>{trigger}</TriggerWrapper>
    </Tooltip>
  );
};

NotifyTooltip.propTypes = {
  type: PropTypes.oneOf(['info', 'warn']),
  flow: PropTypes.oneOf(['inLine', 'block']),
  text: PropTypes.string.isRequired,
};

NotifyTooltip.defaultProps = {
  type: 'info',
  flow: 'inLine',
};

export default NotifyTooltip;
