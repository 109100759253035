import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { COUNTRIES_BLOCKLIST } from '@paradigm/utils/src/countries';
import ParadigmModal from '../../../../material/modal';
import entitiesContext from '../../../../stores/entities-store';
import EditEntityForm from './edit-entity-form';
import authContext from '#/stores/auth-store';

const countriesBlocklist = {
  test: (countryCode, ctx) => {
    if (COUNTRIES_BLOCKLIST[countryCode] != null) {
      return ctx.createError({
        message:
          'We are currently not providing services to the selected country',
      });
    }
    return true;
  },
};

export const entitySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  mailing_address: Yup.object().shape({
    country: Yup.string().test(countriesBlocklist).required('Required'),
    address1: Yup.string().required('Required'),
    address2: Yup.string(),
    city: Yup.string().required('Required'),
    province: Yup.string().required('Required'),
    zip_code: Yup.string().required('Required'),
  }),
});

const EditEntity = ({ onClose, source }) => {
  const { editEntity } = React.useContext(entitiesContext);
  const auth = React.useContext(authContext);

  const handleSubmit = async (entity) => {
    editEntity(entity).finally(onClose);
  };

  return (
    <ParadigmModal
      title={auth.isBusiness ? 'Edit Entity' : 'Edit User Profile'}
      onClose={onClose}
      noScroll
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          name: source.name,
          id: source.id,
          mailing_address: source.mailing_address,
        }}
        validationSchema={entitySchema}
      >
        {(props) => <EditEntityForm {...props} onClose={onClose} />}
      </Formik>
    </ParadigmModal>
  );
};

export default EditEntity;
