import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PageSubTitle from '../../components/page-sub-title/page-sub-title';
import CreateCredential from './components/create-credential/create-credential';
import authContext from '../../stores/auth-store';
import apiManagementContext from '../../stores/api-management-store';
import CredentialsTable from './components/credentials-table/credentials-table';

const useStyles = makeStyles((theme) => ({
  left: {
    width: 130,
  },
  table: {
    margin: `${theme.spacing(1)}px -40px 0`,
  },
}));

const ApiManagement = (props) => {
  const classes = useStyles();
  const auth = React.useContext(authContext);
  const source = React.useContext(apiManagementContext);

  const CreateCredentialIcon = React.useMemo(
    () => (
      <Tooltip title="Create new API credentials" enterDelay={300}>
        <IconButton color="primary">
          <Add fontSize="small" />
        </IconButton>
      </Tooltip>
    ),
    [],
  );

  return (
    <Grid container direction="column">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageSubTitle text="API ACCESS" />
        </Grid>
        {auth.isAdmin && (
          <Grid item>
            <Grid container>
              <Grid item>
                <CreateCredential
                  source={source}
                  children={CreateCredentialIcon}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.table}>
        <CredentialsTable source={source} />
      </Grid>
    </Grid>
  );
};

export default ApiManagement;
