import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router';
import ThetanutsAccount from './dov-account/thetanuts-account';
import thetanutsContext from '../../stores/thetanuts-store';
import { useTabQueryString } from '../../hooks/use-tab-query-string';

const useStyles = makeStyles({
  tabs: {
    paddingBottom: 20,
  },
  tab: {
    width: 220,
  },
});

const ThetanutsGroup = () => {
  const classes = useStyles();
  const thetanuts = React.useContext(thetanutsContext);
  const { tab, setTab } = useTabQueryString('account');

  React.useEffect(() => {
    thetanuts.fetch();
  }, [thetanuts]);

  return (
    <div data-testid="thetanuts">
      <Tabs
        value={tab}
        onChange={(_, selectedTab) => setTab(selectedTab)}
        className={classes.tabs}
      >
        <Tab value="account" className={classes.tab} label="Account" />
      </Tabs>
      <div css="position: relative;">
        <div css="position: absolute; width: 100%;">
          {tab === 'account' && <ThetanutsAccount source={thetanuts} />}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ThetanutsGroup);
