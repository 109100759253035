import api from './_api';

export const createApiCredentialApi = (
  tradingDeskId,
  label,
  allowed_cidrs,
  read_only,
) =>
  api.post(`/trading-desk/${tradingDeskId}/apikeys/`, {
    label,
    allowed_cidrs,
    read_only,
  });

export const updateApiCredentialApi = ({ tradingDeskId, apiKeyId, payload }) =>
  api.patch(`/trading-desk/${tradingDeskId}/apikeys/${apiKeyId}/`, payload);

export const deleteApiCredentialApi = (tradingDeskId, apiKeyId) =>
  api.delete(`/trading-desk/${tradingDeskId}/apikeys/${apiKeyId}/`);
