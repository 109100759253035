import api from './_api';

const updateBitcomApi = (data) =>
  api.patch('/trading-desk/bitcom/update/', data);

const updateBitcomRfqApi = ({ id, ...data }) =>
  api.put(`/trading-desk/bitcom/config/${id}/update/`, data);

const updateAccessKeyApi = ({ id, ...data }) =>
  api.patch(`trading-desk/bitcom/api-credential/${id}/`, data);

const addAccessKeyApi = (data) =>
  api.post('trading-desk/bitcom/api-credential/', data);

const removeAccessKeyApi = (id) =>
  api.delete(`trading-desk/bitcom/api-credential/${id}/`);

export {
  updateBitcomApi,
  updateBitcomRfqApi,
  updateAccessKeyApi,
  addAccessKeyApi,
  removeAccessKeyApi,
};
