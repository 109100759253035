import { SVGProps } from 'react';

export default function CheckMark(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) {
  return (
    <svg viewBox="0 0 14 9" {...props}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.699999988"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-1317.000000, -346.000000)"
          stroke="#7ED321"
          strokeWidth="2"
        >
          <g transform="translate(250.000000, 192.000000)">
            <g transform="translate(50.000000, 146.000000)">
              <g transform="translate(1013.000000, 1.500000)">
                <polyline points="16.0854971 7.5 9.3648073 14.5 5.91450292 11.0492235" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
