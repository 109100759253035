import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MoreVert } from '@material-ui/icons';
import ParadigmMenu from '../../../../material/menu';
import EditCredential from '../edit-credential/edit-credential';
import iconWarning from '../../../../assets/icon-warning-yellow.png';
import ParadigmButton from '../../../../material/button';

const useStyles = makeStyles({
  img: {
    cursor: 'pointer',
    marginRight: 11,
  },
  text: {
    opacity: 0.7,
  },
});

const DeleteCredentialConfirmModal = ({ onConfirm, onReject }) => {
  const classes = useStyles();
  const [busy, setBusy] = React.useState(false);

  const handleConfirm = async () => {
    setBusy(true);
    try {
      await onConfirm();
    } catch (err) {
      onReject && onReject();
    }
  };

  const isDevice = useMediaQuery('(max-width:600px)');

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      fullScreen={isDevice}
      data-testid="paradigm-conform-modal"
      onClose={onReject}
    >
      <DialogTitle>
        <img className={classes.img} src={iconWarning} alt="" />
        Warning!
      </DialogTitle>
      <DialogContent>
        <p className={classes.text}>
          Deleting will automatically render all Paradigm API services
          inaccessible where this API Key was used.
        </p>
        <p className={classes.text}>This action cannot be undone.</p>
      </DialogContent>
      <DialogContent>
        <div style={{ float: 'right' }}>
          <ParadigmButton secondary text="Cancel" onClick={onReject} />
          <ParadigmButton
            text="Delete"
            onClick={handleConfirm}
            busy={busy}
            style={{ backgroundColor: '#CE0047' }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const TableAction = ({ credential, source }) => {
  const [anchor, setAnchor] = React.useState(null);
  const [editing, setEditing] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState(false);

  const openMenu = ({ currentTarget }) => setAnchor(currentTarget);
  const closeMenu = () => setAnchor(null);

  return (
    <>
      <IconButton align="right" style={{ color: 'white' }} onClick={openMenu}>
        <MoreVert fontSize="small" />
      </IconButton>
      <ParadigmMenu
        options={[
          {
            title: 'Edit',
            onClick: () => setEditing(true),
          },
          {
            title: 'Delete',
            onClick: () => setConfirmation(true),
          },
        ]}
        anchor={anchor}
        onClose={closeMenu}
      />
      {editing && (
        <EditCredential
          credential={credential}
          onClose={() => setEditing(false)}
          source={source}
        />
      )}
      {confirmation && (
        <DeleteCredentialConfirmModal
          onReject={() => setConfirmation(false)}
          onConfirm={() => source.removeCredential(credential.id)}
        />
      )}
    </>
  );
};

export default TableAction;
