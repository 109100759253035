import Grid from '@material-ui/core/Grid';
import { Field, Form, ErrorMessage } from 'formik';
import { isNil } from 'lodash';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import iconWarning from '../../../../../assets/icon-warning-yellow.png';
import NotifyTooltip from '../../../../../components/notify-tooltip/notify-tooltip';
import ParadigmButton from '../../../../../material/button';
import { FormattedParadigmInput } from '../../../../../material/input';
import { ModalActionsWrapper } from '../../../../../material/modal';

const useStyles = makeStyles((theme) => ({
  msg: {
    fontSize: '12px',
    lineHeight: '15px',
  },
  error: {
    border: '1px solid #F3E500',
    borderRadius: '7px',
    display: 'flex',
  },
  img: {
    height: '13px',
    width: '13px',
    margin: '14px 8px 0 12px',
  },
  field: {
    '& #executionWindow-helper-text': {
      display: 'none',
    },
    '& #executionWindow-label': {
      color: '#7fabec',
    },
  },
  description: {
    position: 'relative',
    opacity: 0.5,
    'font-weight': 500,
    'font-size': '13px',
    top: '-4px',
  },
  disabled: {
    opacity: '0.3',
  },
}));

const EditManualSettingsForm = (props) => {
  const classes = useStyles(props);
  const { isSubmitting, onClose, dirty, pair, tooltip, isValid } = props;

  return (
    <Form noValidate>
      <Grid container spacing={2}>
        <Grid item xs>
          <Field
            name="baseSize"
            label={
              <span style={{ display: 'inline-flex', height: 12 }}>
                Max Size
                <NotifyTooltip text={tooltip} />
              </span>
            }
            component={FormattedParadigmInput}
            thousandSeparator
            endAdornment={pair.baseCurrency}
            autoFocus
            required
          />
        </Grid>
        {!isNil(pair.quoteSize) && (
          <Grid item xs>
            <Field
              name="quoteSize"
              label=" "
              component={FormattedParadigmInput}
              thousandSeparator
              endAdornment={pair.quoteCurrency}
            />
          </Grid>
        )}
      </Grid>
      {pair.hasPriceBand && (
        <>
          <Field
            className={classes.field}
            name="takerPriceBand"
            component={FormattedParadigmInput}
            label="Taker Price Bands"
            endAdornment="Ticks"
          />
          <span className={classes.description}>
            1 Tick = {pair.calculatePriceBandTotal(1)}. Blank to disable Price
            Bands.
          </span>
          <Field
            className={classes.field}
            name="makerPriceBand"
            component={FormattedParadigmInput}
            label="Maker Price Bands"
            endAdornment="Ticks"
          />
          <span className={classes.description}>
            1 Tick = {pair.calculatePriceBandTotal(1)}. Blank to disable Price
            Bands.
          </span>
        </>
      )}
      <Field
        className={classes.field}
        name="executionWindow"
        component={FormattedParadigmInput}
        label="Order Expires In"
        endAdornment={'Seconds'}
      />
      <ErrorMessage
        name="executionWindow"
        render={(msg) => (
          <div className={classes.error}>
            <img className={classes.img} src={iconWarning} alt="" />
            <p className={classes.msg}>
              'Order Expires In' value should be between 60 - 300
            </p>
          </div>
        )}
      />
      <ModalActionsWrapper>
        <ParadigmButton secondary text="Cancel" onClick={onClose} />
        <ParadigmButton
          type="submit"
          className={!isValid || !dirty ? classes.disabled : null}
          busy={isSubmitting}
          disabled={!dirty}
          text="Save"
        />
      </ModalActionsWrapper>
    </Form>
  );
};

export default EditManualSettingsForm;
