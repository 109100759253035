import { Formik } from 'formik';
import { isNil } from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import useToggle from '../../../../../hooks/use-toggle';
import ParadigmModal from '../../../../../material/modal';
import EditManualSettingsForm from './edit-manual-settings-form';

const EditManualSettings = ({ pair, children, maxSizeError, tooltip }) => {
  const [open, toggle] = useToggle(false);

  const shape = {
    baseSize: Yup.number()
      .max(9223372036854775000, 'Can not be more than 19 digits')
      .required(maxSizeError),
    executionWindow: Yup.number()
      .min(60)
      .max(300)
      .integer()
      .required('Required'),
    makerPriceBand: Yup.number().integer().nullable(true).notRequired(),
    takerPriceBand: Yup.number().integer().nullable(true).notRequired(),
  };
  const initialValues = {
    baseSize: pair.baseSize,
    executionWindow: pair.executionWindow,
    makerPriceBand: pair.makerPriceBand,
    takerPriceBand: pair.takerPriceBand,
  };

  if (!isNil(pair.quoteSize)) {
    shape.quoteSize = Yup.string().required('Max quote size is required');
    initialValues.quoteSize = pair.quoteSize;
  }

  const rfqSchema = Yup.object().shape(shape);

  const handleSubmit = (values) => {
    const updater = {
      [!isNil(pair.quoteSize) ? 'base_currency_max_size' : 'currency_max_size']:
        values.baseSize,
      execution_window: values.executionWindow,
      maker_price_band: values.makerPriceBand ? values.makerPriceBand : null,
      taker_price_band: values.takerPriceBand ? values.takerPriceBand : null,
      quote_currency_max_size: values.quoteSize,
    };
    return pair.update({ config: updater }).finally(toggle);
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: toggle,
      })}
      {open && (
        <ParadigmModal title="Manual RFQ Settings" onClose={toggle}>
          <Formik
            onSubmit={handleSubmit}
            validationSchema={rfqSchema}
            initialValues={initialValues}
          >
            {(props) => (
              <EditManualSettingsForm
                {...props}
                onClose={toggle}
                pair={pair}
                tooltip={tooltip}
              />
            )}
          </Formik>
        </ParadigmModal>
      )}
    </>
  );
};

export default EditManualSettings;
