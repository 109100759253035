import api from './_api';

const getOptionsInfoApi = () => api.get('/trading-desk/deribit/info/');

const updateOptionsApi = (data) =>
  api.patch('/trading-desk/deribit/update/', data);

const updateOptionsRfqApi = ({ id, ...data }) =>
  api.put(`/trading-desk/deribit/options-config/${id}/update/`, data);

const updateAccessKeyApi = ({ id, ...data }) =>
  api.patch(`trading-desk/deribit/api-credential/${id}/update/`, data);

const addAccessKeyApi = (data) =>
  api.post('trading-desk/deribit/api-credential/create/', data);

const removeAccessKeyApi = (id) =>
  api.delete(`trading-desk/deribit/api-credential/${id}/remove/`);

export {
  getOptionsInfoApi,
  updateOptionsApi,
  updateOptionsRfqApi,
  updateAccessKeyApi,
  addAccessKeyApi,
  removeAccessKeyApi,
};
