import { useEffectOnce } from '../utils/useEffectOnce';
import { useFeatureFlagStore } from '@paradigm/features/src/feature-flags/store';
import { fetchFlags } from '../api/feature-flag-api';
import { processRawFeatureFlag } from '@paradigm/features/src/feature-flags/adapters';

function loadFlags() {
  // Note: Admin panel uses a different fetch mechanism for feature flags.
  return fetchFlags().then((flags) => processRawFeatureFlag(flags));
}

export default function FeatureFlagLoader() {
  const setFlags = useFeatureFlagStore((state) => state.setFlags);
  useEffectOnce(() => {
    loadFlags().then((flags) => {
      setFlags(flags);
    });
  });

  return null;
}
