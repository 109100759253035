import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import ParadigmButton from '#/material/button';
import IconWarning from '#/assets/icon-warning';
import { useMmpStatus, useMmpReset } from '#/stores/mmp-status-store';

export default function MmpWarning() {
  const { load: refetchMmp } = useMmpStatus();
  const { reset: resetMmp, status, error } = useMmpReset();

  const isFetching = status === 'fetching';

  return (
    <Container>
      <IconWarning css="margin-top: 4px" />
      <div>
        <Typography
          color="textSecondary"
          css="color: #97B2BD; margin-bottom: 12px; "
        >
          Market Maker Protection has been triggered.
          {<br />}
          Please enable trading to resume.
        </Typography>
        <Actions>
          <ParadigmButton
            text="Enable Quoting"
            css="height: 24px"
            disabled={isFetching}
            busy={isFetching}
            onClick={() => resetMmp({ onSuccess: refetchMmp })}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Actions>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 16px;
  height: 108px;
  border: 1px solid #f1e20d;
  border-radius: 7px;
  padding: 14px 16px;
  margin-top: 20px;
`;

const ErrorMessage = styled.span`
  color: red;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
