import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const PairSize = ({ baseSize, quoteSize }) => (
  <Grid container spacing={1} alignItems="center">
    <Grid item>
      <Typography>{baseSize}</Typography>
    </Grid>
    {quoteSize && (
      <>
        <Grid item>
          <Typography variant="caption" color="textSecondary">
            or
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{quoteSize}</Typography>
        </Grid>
      </>
    )}
  </Grid>
);

PairSize.propTypes = {
  baseSize: PropTypes.string,
  quoteSize: PropTypes.string,
};

export default PairSize;
