import React from 'react';

function useToggle(initialState = false) {
  const [state, setState] = React.useState(initialState);
  const toggle = () => setState((state) => !state);

  return [state, React.useCallback(toggle, [])];
}

export default useToggle;
