import { isNil } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import { CurrencyFormatter as formatter } from '../../../utils/common';

export default class OptionsRfq {
  baseSize;
  isEnabled;
  executionWindow;
  pair;
  makerPriceBand;
  takerPriceBand;

  constructor(productConfig, updateRfqApi, name) {
    makeObservable(this, {
      baseSize: observable,
      isEnabled: observable,
      executionWindow: observable,
      pair: observable,
      makerPriceBand: observable,
      takerPriceBand: observable,
      update: action,
      toggleEnabled: action,
    });

    this.name = name;
    if (typeof updateRfqApi === 'function') {
      this.updateRfqApi = updateRfqApi;
    }
    this.updateConfig(productConfig);
  }

  updateConfig(productConfig) {
    this.id = productConfig.id;

    this.display_units = productConfig.display_units;
    this.isEnabled = productConfig.is_enabled;
    this.pair = productConfig.product_name;
    this.currency = productConfig.currency;
    this.minTickSize = productConfig.min_tick_size;

    if (productConfig.config) {
      const config = productConfig.config;
      this.baseSize = config.currency_max_size;
      this.executionWindow = config.execution_window;
      this.makerPriceBand = config.maker_price_band;
      this.takerPriceBand = config.taker_price_band;
    }
  }

  formattedPriceBand(priceBand) {
    return isNil(priceBand) ? 'No bands set up' : `${priceBand} ticks`;
  }

  calculatePriceBandTotal(tickAmount) {
    if (!this.display_units || isNil(tickAmount)) {
      return '';
    }

    const decimalPlaces = Math.abs(Math.floor(Math.log10(this.minTickSize)));

    const total = (this.minTickSize * tickAmount).toFixed(decimalPlaces);
    return this.display_units === 'USD'
      ? `$${total}`
      : `${total} ${this.display_units}`;
  }

  update = async (data) => {
    const response = await this.updateRfqApi({ id: this.id, ...data });
    this.updateConfig(response);
  };

  toggleEnabled = async () => {
    try {
      this.isEnabled = !this.isEnabled;
      await this.update({ is_enabled: this.isEnabled });
    } catch (err) {
      this.isEnabled = !this.isEnabled;
    }
  };

  get baseCurrency() {
    return this.currency;
  }

  get formattedBaseSize() {
    return `${formatter.short({ amount: this.baseSize, symbol: '' })} ${
      this.baseCurrency
    }`;
  }

  get formattedMakerPriceBand() {
    return this.formattedPriceBand(this.makerPriceBand);
  }
  get formattedTakerPriceBand() {
    return this.formattedPriceBand(this.takerPriceBand);
  }

  get priceBandMakerTotal() {
    return this.calculatePriceBandTotal(
      this.makerPriceBand,
      this.display_units,
    );
  }
  get priceBandTakerTotal() {
    return this.calculatePriceBandTotal(
      this.takerPriceBand,
      this.display_units,
    );
  }

  get hasPriceBand() {
    const venuesWithoutPriceBand = ['CME Group'];
    return !venuesWithoutPriceBand.includes(this.name);
  }
}
