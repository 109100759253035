import { keyBy, find } from 'lodash';
import {
  action,
  observable,
  computed,
  makeObservable,
  runInAction,
} from 'mobx';
import {
  getOptionsInfoApi,
  addVenueAPICredentialsApi,
  removeVenueAPICredentialsApi,
  updateOptionsRfqApi,
} from '../api/venue-api-credentials-api';
import MarketIntegrityAccessKey from './classes/options/market-integrity-access-key';
import OptionsRfq from './classes/options/options-rfq';
import Products from './classes/product-store';

export default class VenueAPICredentials extends Products {
  name;
  shortName;
  longName;
  rfq;
  accessKeys = {};
  ready;
  AccessKeyClass = MarketIntegrityAccessKey;

  setRfq(optionConfigs, enabled) {
    this.rfq = keyBy(
      optionConfigs.map(
        (optionConfig) =>
          new OptionsRfq(
            optionConfig,
            (data) => updateOptionsRfqApi(this.longName, data),
            this.name,
          ),
      ),
      'id',
    );
  }

  setApiKeys(keys) {
    this.accessKeys = keyBy(
      keys.map((key) => new this.AccessKeyClass(this.shortName, key)),
      'id',
    );
  }

  async addApiKey(key) {
    const createdKey = await addVenueAPICredentialsApi(this.longName, {
      ...key,
      entity: key.entity.id,
    });
    const newKeys = new this.AccessKeyClass(this.shortName, {
      ...createdKey,
      entity: key.entity,
    });
    this.accessKeys[newKeys.id] = newKeys;
  }

  async removeApiKey(key) {
    await removeVenueAPICredentialsApi(this.longName, key.id);
    delete this.accessKeys[key.id];
  }

  async fetch() {
    const optionsInfo = await getOptionsInfoApi(this.longName);
    this.setRfq(optionsInfo.configs, optionsInfo.rfq_is_enabled);
    this.setApiKeys(optionsInfo.api_credentials);
    runInAction(() => (this.ready = true));
  }

  reset() {
    this.rfq = null;
    this.ready = null;
  }

  constructor(name, shortName, longName) {
    super();
    this.name = name;
    this.shortName = shortName;
    this.longName = longName;

    makeObservable(this, {
      shortName: observable,
      longName: observable,
      rfq: observable,
      accessKeys: observable,
      ready: observable,
      setRfq: action.bound,
      setApiKeys: action.bound,
      addApiKey: action.bound,
      removeApiKey: action.bound,
      fetch: action.bound,
      reset: action.bound,
      defaultPair: computed,
    });
  }

  get defaultPair() {
    if (!this.ready) return { id: '', isEnabled: false };
    return find(
      this.rfq,
      (optionConfig) => optionConfig && optionConfig.pair.includes('BTC'),
    );
  }
}
