import * as PropTypes from 'prop-types';
import React from 'react';
import AsyncSelect from 'react-select/async';
import components, { selectStyles } from './styles';

const SelectAsync = ({ promise, ...rest }) => {
  const loadOptions = async (inputValue) => promise(inputValue);

  const loadingMessage = ({ inputValue }) => `Searching for "${inputValue}"`;
  const noOptionsMessage = ({ inputValue }) =>
    inputValue ? `No matching results for "${inputValue}"` : null;

  return (
    <AsyncSelect
      isClearable
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      cacheOptions
      loadOptions={loadOptions}
      components={components}
      styles={selectStyles}
      autoFocus={rest.textFieldProps.autoFocus}
      {...rest}
    />
  );
};

SelectAsync.propTypes = {
  isMulti: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  placeholder: PropTypes.string,
  promise: PropTypes.func.isRequired,
};

SelectAsync.defaultProps = {
  placeholder: 'Search for users...',
  isOptionDisabled: () => false,
};

export default SelectAsync;
