import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import { portage10 } from '@paradigm/design-system/src/colors';

const inputProps = {
  'data-testid': 'paradigm-checkbox',
};

const useStyles = makeStyles((theme) => ({
  roundedEmptyIcon: {
    height: (props) => {
      if (props.size === 'small') return 20;
      return 24;
    },
    width: (props) => {
      if (props.size === 'small') return 20;
      return 24;
    },
    padding: 2,
    '& > div': {
      width: '100%',
      height: '100%',
      borderRadius: (props) => {
        if (props.rounded) return '50%';
        return 3;
      },
      background: portage10,
    },
  },
}));

const ParadigmCheckbox = (props) => {
  const classes = useStyles(props);
  const { rounded, labelPlacement, label, field, form, ...rest } = props;

  if (rounded) {
    rest.checkedIcon = <CheckCircle data-testid="filled-rounded" />;
  }

  rest.icon = (
    <span className={classes.roundedEmptyIcon} data-testid="empty-rounded">
      <div />
    </span>
  );
  return (
    <FormControlLabel
      control={<Checkbox {...rest} {...field} inputProps={inputProps} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

ParadigmCheckbox.propTypes = {
  rounded: PropTypes.bool,
};

ParadigmCheckbox.defaultProps = {
  rounded: false,
};

export default React.memo(ParadigmCheckbox);
