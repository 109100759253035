import * as PropTypes from 'prop-types';
import { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMarketIntegrityFeatureFlag } from '@paradigm/features/src/feature-flags/context';
import ParadigmSwitch from '../material/switch';
import authContext from '../stores/auth-store';

function VenueApiKeyToggle({ apiKey, disabledIds = [], onChange }) {
  const isMarketIntegrityFeatureEnabled = useMarketIntegrityFeatureFlag();
  const auth = useContext(authContext);

  const {
    id,
    isEnabled: isActive,
    isValidCredentials,
    hasIpAllowlist,
    hasBlockTradeScope,
  } = apiKey;

  const isPenalized = apiKey.isPenalized && isMarketIntegrityFeatureEnabled;

  const isDisabledByPermission = !(
    isValidCredentials &&
    hasIpAllowlist &&
    hasBlockTradeScope
  );

  const isDisabled =
    !(auth.isAdmin || auth.isTrader) ||
    disabledIds.includes(id) ||
    isPenalized ||
    isDisabledByPermission;

  const label = isDisabledByPermission
    ? 'Missing Permission'
    : isPenalized
    ? 'Locked Out'
    : isActive
    ? 'Active'
    : '';

  return (
    <Grid container alignItems="center">
      <Grid item>
        <ParadigmSwitch
          disabled={isDisabled}
          checked={isActive}
          onChange={onChange}
        />
      </Grid>
      <Grid item>
        <Typography
          color="textSecondary"
          style={{ marginLeft: 10 }}
          data-testid="label"
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
}

VenueApiKeyToggle.propTypes = {
  apiKey: PropTypes.shape({
    id: PropTypes.number,
    isEnabled: PropTypes.bool,
    isPenalized: PropTypes.bool,
  }).isRequired,
  disabledIds: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

export default VenueApiKeyToggle;
