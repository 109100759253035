import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useToggle from '../../../../hooks/use-toggle';
import ParadigmModal from '../../../../material/modal';
import AddEmailsForm from './AddEmailsForm';
import { emailsSchema, proceessEmails } from '../../../../utils/common';
import globalContext from '../../../../stores/global-store';

const AddEmails = ({ children, source }) => {
  const globalStore = React.useContext(globalContext);
  const [open, toggle] = useToggle(false);
  const [isSubmitting, setIsSubmitting] = React.useState(null);
  const createEmailsSchema = Yup.object().shape({
    emails: emailsSchema,
  });

  const handleSubmit = async (payload) => {
    const emails = proceessEmails(payload['emails']);
    try {
      setIsSubmitting(true);
      await source.addEmailsList({
        emails,
      });
    } catch {
      globalStore.setError('Unable to add emails list!');
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };
  const handleClose = () => {
    toggle();
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: toggle,
      })}
      {open && (
        <ParadigmModal title="Add Emails" onClose={handleClose} maxWidth="sm">
          <Formik
            initialValues={{ emails: '' }}
            onSubmit={handleSubmit}
            validationSchema={createEmailsSchema}
          >
            {(props) => (
              <AddEmailsForm
                {...props}
                onClose={handleClose}
                isSubmitting={isSubmitting}
              />
            )}
          </Formik>
        </ParadigmModal>
      )}
    </>
  );
};

export default React.memo(AddEmails);
