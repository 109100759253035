import React from 'react';
import { map, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ParadigmButton from '../../../../material/button';
import iconWarning from '../../../../assets/icon-warning-yellow.png';
import CopyIcon from '../../../../assets/copy-icon';

const useStyles = makeStyles({
  container: {
    float: 'right',
    marginTop: 24,
  },
  icon: {
    cursor: 'pointer',
    marginLeft: 11,
  },
  error: {
    border: '1px solid #F3E500',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    height: '13px',
    width: '13px',
    margin: '14px 8px 0 12px',
  },
  label: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#fff',
    opacity: 0.5,
  },
  msg: {
    fontSize: '12px',
    lineHeight: '15px',
  },
  text: {
    fontSize: 15,
  },
  ip: {
    margin: 0,
  },
  list: {
    marginTop: '10px',
    marginBottom: '10px',
  },
});

const CreatedCredential = ({ credential, handleClose }) => {
  const classes = useStyles();
  const handleCopyToClipboard = async (value) =>
    await navigator.clipboard.writeText(value);
  return (
    <>
      <label className={classes.label}>Name</label>
      <p className={classes.text}>{credential.label}</p>
      {!isEmpty(credential.allowed_cidrs) && (
        <>
          <label className={classes.label}>IP Whitelist</label>
          <div className={classes.list}>
            {map(credential.allowed_cidrs, (ip, i) => (
              <p key={i} className={classes.ip}>
                {ip}
              </p>
            ))}
          </div>
        </>
      )}
      <label className={classes.label}>Access Level</label>
      <p className={classes.text}>
        {credential.read_only ? 'Read-only' : 'Read/Write'}
      </p>
      <label className={classes.label}>Access Key</label>
      <p className={classes.text}>
        {credential.access_key}
        <CopyIcon
          className={classes.icon}
          handleClick={() => handleCopyToClipboard(credential.access_key)}
        />
      </p>
      <label className={classes.label}>Secret Key</label>
      <p className={classes.text}>
        {credential.signing_key}
        <CopyIcon
          className={classes.icon}
          handleClick={() => handleCopyToClipboard(credential.signing_key)}
        />
      </p>
      <div className={classes.error}>
        <img className={classes.img} src={iconWarning} alt="" />
        <p className={classes.msg}>
          This is the last time this Access Key and Secret Key will be visible.
          Please make sure that you copy them somewhere safe.
        </p>
      </div>
      <div className={classes.container}>
        <ParadigmButton type="submit" text="Done" onClick={handleClose} />
      </div>
    </>
  );
};

export default React.memo(CreatedCredential);
