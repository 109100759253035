import React from 'react';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import useToggle from '../../../../hooks/use-toggle';
import ParadigmModal from '../../../../material/modal';
import CreateCredentialForm from './create-credential-form';
import CreatedCredential from './created-credential';
import { allowedIpSchema } from '../../../../utils/common';

const CreateCredential = ({ children, source }) => {
  const [open, toggle] = useToggle(false);
  const [createdCredential, setCreatedCredential] = React.useState(false);

  const createCredentialSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    allowed_cidrs: allowedIpSchema,
    read_only: Yup.boolean().required('Access Level is required'),
  });

  const handleSubmit = async (payload) => {
    const name = payload['name'];
    const allowed_cidrs = !isEmpty(payload['allowed_cidrs'])
      ? payload['allowed_cidrs']
          .split(/\r?\n/)
          .filter((item) => item.length > 0)
      : [];
    const read_only = payload['read_only'];
    const createdCredential = await source.createCredential({
      name,
      allowed_cidrs,
      read_only,
    });
    setCreatedCredential(createdCredential);
  };
  const handleClose = () => {
    setCreatedCredential(false);
    toggle();
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: toggle,
      })}
      {open && (
        <ParadigmModal
          title="Create New API KEY"
          onClose={handleClose}
          maxWidth="sm"
        >
          {createdCredential ? (
            <CreatedCredential
              credential={createdCredential}
              handleClose={handleClose}
            />
          ) : (
            <Formik
              initialValues={{ name: '', allowed_cidrs: '', read_only: '' }}
              onSubmit={handleSubmit}
              validationSchema={createCredentialSchema}
            >
              {(props) => (
                <CreateCredentialForm {...props} onClose={handleClose} />
              )}
            </Formik>
          )}
        </ParadigmModal>
      )}
    </>
  );
};

export default React.memo(CreateCredential);
