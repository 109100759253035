import React from 'react';
import { Observer } from 'mobx-react-lite';
import { isEmpty, map } from 'lodash';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddKey from '../add-keys/add-keys';
import TableAction from './table-action';
import authContext from '../../../../../stores/auth-store';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ParadigmSwitch from '../../../../../material/switch';

const KeysTable = (props) => {
  const { source } = props;
  const auth = React.useContext(authContext);
  const [disabledIds, setDisabledIds] = React.useState([]);

  const handleUpdateActiveKey = (key) => {
    setDisabledIds((ids) => [...ids, key.id]);
    key
      .update({ ...key, is_enabled: !key.isEnabled, venue: source.shortName })
      .finally(() => setDisabledIds((ids) => ids.filter((i) => i !== key.id)));
  };

  return (
    <Observer>
      {() => (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: '50%', minWidth: 185 }}>
                Name
              </TableCell>
              <TableCell align="left" style={{ width: '15%' }}>
                Entity
              </TableCell>
              <TableCell align="left" style={{ width: '15%', minWidth: 185 }}>
                API Key
              </TableCell>
              <TableCell align="left" style={{ width: '15%', minWidth: 160 }}>
                API Secret
              </TableCell>
              <TableCell
                style={{ minWidth: 160, maxWith: 160, paddingRight: 0 }}
              >
                Active
              </TableCell>
              {auth.isAdmin && (
                <TableCell align="left" style={{ minWidth: 106 }} />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {map(source.accessKeys, (key) => (
              <TableRow key={key.id}>
                <TableCell component="th" scope="row">
                  {key.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {key.entity.name}
                </TableCell>
                <TableCell align="left">{key.formattedAccessKey}</TableCell>
                <TableCell align="left">{key.formattedAccessSecret}</TableCell>
                <TableCell align="left" style={{ paddingRight: 0 }}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <ParadigmSwitch
                        disabled={
                          !(auth.isAdmin || auth.isTrader) ||
                          disabledIds.includes(key.id)
                        }
                        checked={Boolean(key.isEnabled)}
                        onChange={() => handleUpdateActiveKey(key)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        style={{ marginLeft: 10 }}
                      >
                        {key.isEnabled ? 'Active' : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                {auth.isAdmin && (
                  <TableCell align="left">
                    <TableAction apiKey={key} source={source} />
                  </TableCell>
                )}
              </TableRow>
            ))}
            {isEmpty(source.accessKeys) && (
              <TableRow hover={false}>
                <TableCell align="center" colSpan={auth.isAdmin ? 5 : 4}>
                  No API credentials found.&nbsp;
                  <AddKey
                    source={source}
                    children={
                      <Link variant="body1" color="primary">
                        Click here to add one
                      </Link>
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Observer>
  );
};

export default KeysTable;
