import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import logo from '../../assets/logo.png';

const useStyles = makeStyles({
  loadingWrapper: {
    display: 'flex',
    margin: 'auto',
    width: 200,
    height: 200,
    animation: 'pulse 1s ease-in-out infinite alternate both',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
});

const LoadingScreen = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.loadingWrapper} data-testid="loading-screen">
      <img src={logo} alt="logo" />
    </div>
  );
};

export default LoadingScreen;
