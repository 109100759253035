import { keyBy, find } from 'lodash';
import {
  action,
  observable,
  computed,
  makeObservable,
  runInAction,
} from 'mobx';
import { createContext } from 'react';
import { getSpotInfoApi, updateSpotApi } from '../api/spot-api';
import SpotCredit from './classes/spot/spot-credit';
import SpotRfq from './classes/spot/spot-rfq';

export class Spot {
  rfq;
  credit = new SpotCredit({});
  ready;

  setCredit = (info) => {
    this.credit = new SpotCredit(info);
  };

  setRfq = (rfq, enabled) => {
    this.rfq = keyBy(
      rfq.map((i) => new SpotRfq(i)),
      'id',
    );
  };

  updateSpot = (data) => updateSpotApi(data);

  fetch = async () => {
    const spotInfo = await getSpotInfoApi();
    this.setRfq(spotInfo.rfq_configs, spotInfo.rfq_is_enabled);
    this.setCredit(spotInfo);
    runInAction(() => (this.ready = true));
  };

  reset = () => {
    this.rfq = null;
    this.credit = new SpotCredit({});
    this.ready = null;
  };

  constructor() {
    makeObservable(this, {
      rfq: observable,
      credit: observable,
      ready: observable,
      setCredit: action,
      setRfq: action,
      updateSpot: action,
      fetch: action,
      reset: action,
      defaultPair: computed,
    });
  }

  get defaultPair() {
    if (!this.ready) return { id: '', isEnabled: false };
    return find(this.rfq, (i) => i && i.pair.includes('BTC'));
  }
}

export const spot = new Spot();
window.spot = spot;

export default createContext(spot);
