import { action, observable, computed, makeObservable } from 'mobx';
import { updateWalletAddressApi } from '../../../api/products-api';

export default class AccessKey {
  name;
  accessKey;
  entity;
  isEnabled;

  constructor(key) {
    makeObservable(this, {
      name: observable,
      accessKey: observable,
      entity: observable,
      isEnabled: observable,
      formattedAccessKey: computed,
      update: action,
    });

    this.id = key.id;
    this.name = key.name;
    this.accessKey = key.access_key;
    this.entity = key.entity;
    this.isEnabled = key.is_enabled;
    this.isPenalized = key.is_penalized;
  }

  get formattedAccessSecret() {
    return '••••••••••••••••••••';
  }

  get formattedAccessKey() {
    return `•••••••••••${this.accessKey}`;
  }

  update = async (data) => {
    const response = await updateWalletAddressApi({
      id: this.id,
      ...data,
      entity: data.entity.id,
    });
    this.accessKey = response.access_key;
    this.name = response.name;
    this.entity = data.entity;
    this.isEnabled = response.is_enabled;
  };
}
