import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Close } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';

export const ModalActionsWrapper = ({ children }) => (
  <Grid container justifyContent="flex-end" style={{ marginTop: 24 }}>
    {children}
  </Grid>
);

const CloseWrapper = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(1),
}));

const Content = styled(({ noScroll, ...props }) => (
  <DialogContent {...props} />
))(({ noScroll }) => ({
  overflowY: noScroll ? 'visible' : 'auto',
}));

const ParadigmModal = (props) => {
  const {
    title,
    children,
    onClose,
    maxWidth = 'xs',
    noScroll,
    ...other
  } = props;

  const isDevice = useMediaQuery('(max-width:600px)');

  return (
    <Dialog
      open
      maxWidth={maxWidth}
      {...(noScroll && { PaperProps: { style: { overflowY: 'visible' } } })}
      fullWidth
      onClose={onClose}
      fullScreen={isDevice}
      data-testid="paradigm-modal"
      {...other}
    >
      <DialogTitle>
        {title}
        <CloseWrapper aria-label="Close" color="inherit" onClick={onClose}>
          <Close />
        </CloseWrapper>
      </DialogTitle>
      <Content noScroll={noScroll}>{children}</Content>
    </Dialog>
  );
};

ParadigmModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

export default ParadigmModal;
