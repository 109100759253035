import { useCallback, useState } from 'react';

import { getMmpStatus, resetMmpStatus } from '#/api/mmp-api';
import createSafeContext from '@paradigm/utils/src/createSafeContext';

const [useProduct, ProductCtxProvider] = createSafeContext('Product');

/** @type {'grfq' | 'fs'} */
const PRODUCT_INITIAL_STATE = 'grfq';

function ProductProvider({ children }) {
  const productState = useState(PRODUCT_INITIAL_STATE);

  return (
    <ProductCtxProvider value={productState}>{children}</ProductCtxProvider>
  );
}

const [useMmpStatus, MmpStatusCtxProvider] = createSafeContext('MmpStatus');

const MMP_STATUS_INITIAL_STATE = {
  /** @type {'fetching' | 'idle' | 'error'} */
  status: 'idle',
  mmpStatus: {
    grfq: {
      rate_limit_hit: false,
    },
  },
  error: '',
};

function MmpStatusProvider({ children }) {
  const [state, setState] = useState(MMP_STATUS_INITIAL_STATE);

  const load = useCallback(() => {
    if (state.status === 'fetching') return;

    setState((state) => ({ ...state, status: 'fetching' }));

    getMmpStatus('grfq')
      .then((grfq) => {
        setState((state) => ({
          ...state,
          status: 'idle',
          mmpStatus: {
            grfq,
          },
          error: '',
        }));
      })
      .catch((err) => {
        console.error('Error getting MMP status', err);
        setState((state) => ({
          ...state,
          status: 'error',
          error: typeof err === 'string' ? err : 'Failed to get Settings',
        }));
      });
  }, [state.status]);

  return (
    <MmpStatusCtxProvider value={{ ...state, load }}>
      {children}
    </MmpStatusCtxProvider>
  );
}

const MMP_RESET_INITIAL_STATE = {
  /** @type {'fetching' | 'idle' | 'error'} */
  status: 'idle',
  error: '',
};

function useMmpReset() {
  const [state, setState] = useState(MMP_RESET_INITIAL_STATE);
  const [product] = useProduct();

  const reset = useCallback(
    ({ onSuccess } = {}) => {
      if (state.status === 'fetching') return;

      setState({ status: 'fetching', error: '' });

      resetMmpStatus(product)
        .then(() => {
          if (onSuccess) onSuccess();

          setState({
            status: 'idle',
            error: '',
          });
        })
        .catch((err) => {
          console.error('Error resetting MMP', err);
          setState({
            status: 'error',
            error: 'Failed to enable',
          });
        });
    },
    [state.status, product],
  );

  return {
    ...state,
    reset,
  };
}

const productMap = {
  grfq: 'GRFQ/DRFQ',
};

function getReadableProductName(product) {
  if (productMap[product] == null) {
    throw new Error(`Unknown product: ${product}`);
  }

  return productMap[product];
}

export {
  useProduct,
  useMmpStatus,
  useMmpReset,
  MmpStatusProvider,
  ProductProvider,
  getReadableProductName,
};
