import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';

export class Global {
  error;
  redirect;

  setError = (error) => {
    if (error !== 'Token is not valid') this.error = error;
  };
  setRedirect = (redirect) => (this.redirect = redirect);

  constructor() {
    makeObservable(this, {
      error: observable,
      redirect: observable,
      setError: action,
      setRedirect: action,
    });
  }
}

export const globalStore = new Global();
window.globalStore = globalStore;

export default createContext(globalStore);
