import { find } from 'lodash';
import {
  action,
  observable,
  reaction,
  makeObservable,
  runInAction,
} from 'mobx';
import { createContext } from 'react';
import { getUserApi } from '../api/auth-api';
import { getDeskInfoApi, getEntitiesApi } from '../api/desk-api';
import { Roles } from './classes/roles';
import { getToken, removeToken, setToken } from '../utils/storage';
import { apiCredentialManagement } from './api-management-store';
import { spot } from './spot-store';
import { deribit } from './deribit-store';
import { bitcom } from './bitcom-store';
import { desk } from './desk-store';
import { routes } from './routes-store';
import { entities } from './entities-store';

export class Auth extends Roles {
  canManageProduct;
  canManageCounterparties;
  canManageTradingDesk;
  isBusiness;

  user = {};
  fetching = false;
  fetchingDesk = false;
  valid = false;
  error = false;
  type;

  constructor(args) {
    super(args);

    makeObservable(this, {
      canManageProduct: observable,
      canManageCounterparties: observable,
      canManageTradingDesk: observable,
      isBusiness: observable,
      user: observable,
      fetching: observable,
      fetchingDesk: observable,
      valid: observable,
      error: observable,
      startAuth: action,
      successAuth: action,
      failedAuth: action,
      logOut: action,
      setLoggedUserRole: action,
      type: observable,
    });

    if (getToken()) {
      this.startAuth();
      getUserApi().then(this.successAuth).catch(this.failedAuth);
    }
  }

  startAuth = () => {
    this.fetching = true;
    this.valid = false;
    this.error = false;
  };

  successAuth = (user, token) => {
    this.canManageCounterparties = user.can_manage_counterparties;
    this.canManageProduct = user.can_manage_product;
    this.canManageTradingDesk = user.can_manage_trading_desk;
    this.isBusiness = user.is_business;
    this.fetching = false;
    auth.fetchingDesk = true;
    this.valid = true;
    this.user = user;
    setToken(token);
  };

  failedAuth = (error) => {
    this.fetching = false;
    this.valid = false;
    this.type = null;
    this.canManageCounterparties = null;
    this.canManageProduct = null;
    this.canManageTradingDesk = null;
    this.isBusiness = false;
    this.error = error;
    this.user = {};
    removeToken();
  };

  logOut = () => {
    this.failedAuth();
  };

  setLoggedUserRole = (role, user) => {
    this.type = role;
    if (user) {
      this.canManageCounterparties = user.can_manage_counterparties;
      this.canManageProduct = user.can_manage_product;
      this.canManageTradingDesk = user.can_manage_trading_desk;
    }
  };
}

export const auth = new Auth({});
window.auth = auth;

reaction(
  () => auth.valid,
  async (loggedIn) => {
    if (loggedIn) {
      const { api_keys, trading_desk, members } = await getDeskInfoApi();
      apiCredentialManagement.setApiCredentials(api_keys, trading_desk.id);
      entities.setEntities(await getEntitiesApi());
      routes.setProductsSubRoutes(trading_desk);
      const { role } = find(members, (m) => m.user.id === auth.user.id) || {};
      auth.setLoggedUserRole(role);
      runInAction(() => (auth.fetchingDesk = false));
    } else {
      apiCredentialManagement.reset();
      desk.reset();
      spot.reset();
      deribit.reset();
      bitcom.reset();
      routes.reset();
      entities.reset();
    }
  },
);

export default createContext(auth);
