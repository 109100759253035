import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import PageSubTitle from '../../../components/page-sub-title/page-sub-title';
import AddKey from './AddKey';
import authContext from '../../../stores/auth-store';
import ParadigmButton from '../../../material/button';
import KeysTable from './KeysTable';

const useStyles = makeStyles((theme) => ({
  left: {
    width: 130,
  },
  table: {
    margin: `${theme.spacing(1)}px -40px 0`,
  },
}));

const RibbonAccount = (props) => {
  const classes = useStyles();
  const auth = React.useContext(authContext);
  const { source } = props;

  const AddApiKeyIcon = React.useMemo(
    () => <ParadigmButton type="submit" text="Add" endIcon={<Add />} />,
    [],
  );

  return (
    <Observer>
      {() => (
        <Grid container direction="column">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <PageSubTitle text="WALLETS" />
            </Grid>
            {auth.isAdmin && (
              <Grid item>
                <Grid container>
                  <Grid item>
                    <AddKey source={source} children={AddApiKeyIcon} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.table}>
            <KeysTable source={source} />
          </Grid>
        </Grid>
      )}
    </Observer>
  );
};

export default RibbonAccount;
