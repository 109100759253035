import React from 'react';
import { Observer } from 'mobx-react-lite';
import { Field, Form, ErrorMessage } from 'formik';
import ParadigmInput from '../../../../../material/input';
import ParadigmButton from '../../../../../material/button';
import { ModalActionsWrapper } from '../../../../../material/modal';
import { useEntities } from './use-entities';
import ErrorMessageRender from '#/components/ErrorMessage';

const AddKeyForm = ({ isSubmitting, onClose, source, errors }) => {
  const { entityOptions } = useEntities(source);
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Observer>
      {() => (
        <Form noValidate>
          <Field
            name="name"
            label="Name"
            component={ParadigmInput}
            placeholder="Name"
            required
            autoFocus
          />
          <ErrorMessage
            name="name"
            render={(message) => <ErrorMessageRender message={message} />}
          />

          <Field
            name="entity"
            label="Entity"
            placeholder="Entity"
            component={ParadigmInput}
            select
            required
          >
            {entityOptions}
          </Field>
          <ErrorMessage
            name="entity"
            render={(message) => <ErrorMessageRender message={message} />}
          />

          <Field
            name="access_key"
            label="API Key"
            component={ParadigmInput}
            required
            placeholder="API Key"
          />
          <ErrorMessage
            name="access_key"
            render={(message) => <ErrorMessageRender message={message} />}
          />

          <Field
            name="access_secret"
            label="API Secret"
            component={ParadigmInput}
            required
            placeholder="API Secret"
          />
          <ErrorMessage
            name="access_secret"
            render={(message) => <ErrorMessageRender message={message} />}
          />

          <ModalActionsWrapper>
            <ParadigmButton secondary text="Cancel" onClick={onClose} />
            <ParadigmButton
              disabled={hasErrors}
              type="submit"
              busy={isSubmitting}
              text="Add"
            />
          </ModalActionsWrapper>
        </Form>
      )}
    </Observer>
  );
};

export default AddKeyForm;
