import React, { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { MoreVert, ReportProblemOutlined } from '@material-ui/icons';
import ParadigmMenu from '../../../../../material/menu';
import ParadigmConfirmModal from '../../../../../material/confirmation-modal';
import EditKeysDefault from '../edit-keys/edit-keys';

const TableAction = ({ apiKey, source, EditKeys }) => {
  const [anchor, setAnchor] = React.useState(null);
  const [editing, setEditing] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState(false);

  const openMenu = ({ currentTarget }) => setAnchor(currentTarget);
  const closeMenu = () => setAnchor(null);
  const EditKeysComponent = useMemo(() => EditKeys ?? EditKeysDefault, []);

  return (
    <>
      <IconButton align="right" style={{ color: 'white' }} onClick={openMenu}>
        <MoreVert fontSize="small" />
      </IconButton>
      <ParadigmMenu
        options={[
          {
            title: 'Edit',
            onClick: () => setEditing(true),
          },
          {
            title: 'Delete',
            ...(!apiKey.active && { onClick: () => setConfirmation(true) }),
          },
        ]}
        anchor={anchor}
        onClose={closeMenu}
      />
      {editing && (
        <EditKeysComponent
          apiKey={apiKey}
          source={source}
          onClose={() => setEditing(false)}
        />
      )}
      {confirmation && (
        <ParadigmConfirmModal
          icon={<ReportProblemOutlined color="error" />}
          title="Removing API Credentials"
          message={`Removing API Credentials "${apiKey.name}"`}
          onReject={() => setConfirmation(false)}
          onConfirm={() => source.removeApiKey(apiKey)}
        />
      )}
    </>
  );
};

export default TableAction;
