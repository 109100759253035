import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { ExpandMore } from '@material-ui/icons';
import { isNil } from 'lodash';
import React from 'react';
import useFormProps from '../hooks/use-form-props';
import FormattedNumber from 'react-number-format';

const inputProps = {
  'data-testid': 'paradigm-input',
};

const ParadigmInput = React.memo((props) => {
  const {
    field,
    form,
    required,
    select,
    endAdornment,
    startAdornment,
    readOnly,
    ...rest
  } = props;
  const formProps = useFormProps({ field, form, required });

  if (select && rest.placeholder && !(rest.value || (field && field.value))) {
    rest.children = [
      <MenuItem value="" key="placeholder" disabled>
        <span style={{ color: 'rgba(255, 255, 255, 0.4)' }}>
          {rest.placeholder}
        </span>
      </MenuItem>,
      ...rest.children,
    ];
  }

  const InputProps = {};
  if (readOnly) InputProps.readOnly = true;
  if (endAdornment)
    InputProps.endAdornment = (
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    );
  if (startAdornment)
    InputProps.startAdornment = (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    );

  return (
    <TextField
      InputProps={InputProps}
      {...field}
      {...formProps}
      {...rest}
      select={select}
      {...(select && {
        SelectProps: {
          IconComponent: ExpandMore,
          multiple: rest.multiple,
          displayEmpty: Boolean(rest.placeholder),
        },
      })}
      inputProps={inputProps} // eslint-disable-line
      fullWidth
      autoComplete="off"
    />
  );
});

ParadigmInput.defaultProps = {
  margin: 'normal',
};

const FormattedInput = (props) => <ParadigmInput {...props} />;

export const FormattedParadigmInput = (props) => {
  const value = props.form ? props.field.value : props.value || '';
  return (
    <FormattedNumber
      {...props}
      customInput={FormattedInput}
      value={value}
      onChange={() => {}} // package dont use this one
      {...(props.prefix && isNil(value) && { startAdornment: props.prefix })}
      onValueChange={(e) => {
        props.onChange && props.onChange(e.value);
        if (props.form) {
          props.field.onChange({
            target: {
              value: e.value,
              name: props.field.name,
              id: props.field.name,
            },
          });
        }
      }}
    />
  );
};

export default ParadigmInput;
