import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const ContrastText = styled(({ mono, size, ...props }) => (
  <Typography {...props} component="div" />
))(({ color, mono, size }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.59)',
  padding: '0 0.7em',
  color: color !== 'primary' ? '#fe5c83' : 'rgba(255, 255, 255, 0.75)',
  fontFamily: mono && 'Roboto Mono',
  fontSize: size || 14,
  borderRadius: 3,
}));

const Contrast = React.memo(({ text, ...props }) => {
  return <ContrastText {...props}>{text}</ContrastText>;
});

export default Contrast;
