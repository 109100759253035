import Switch from '@material-ui/core/Switch';
import * as PropTypes from 'prop-types';
import React from 'react';

const inputProps = {
  'data-testid': 'paradigm-switch',
};

const ParadigmSwitch = (props) => {
  const { type, ...rest } = props;
  if (type === 'primary') {
    rest.color = 'primary';
  }
  return <Switch {...rest} inputProps={inputProps} />;
};

ParadigmSwitch.propTypes = {
  types: PropTypes.oneOf(['primary', 'secondary']),
};

ParadigmSwitch.defaultProps = {
  type: 'secondary',
};

export default ParadigmSwitch;
