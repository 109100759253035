import { ComponentPropsWithoutRef } from 'react';

const UnCheckedIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="25px" height="27px" viewBox="0 0 25 27" {...props}>
    <circle
      cx={11.4986519}
      cy={11.5013481}
      r={11.5013481}
      transform="translate(-864 -452) translate(520 186) translate(32 250.5) translate(313 17.5)"
      stroke="currentColor"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default UnCheckedIcon;
