import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import theme from './resources/theme';
import * as serviceWorker from './serviceWorker';

class LocalizedUtils extends DateFnsUtils {
  getWeekdays() {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
}

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <MuiPickersUtilsProvider utils={LocalizedUtils}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.register({
  onSuccess: () => window.location.reload(),
  onUpdate: () => window.location.reload(),
});

if (module.hot) {
  module.hot.accept();
}
