import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Add } from '@material-ui/icons';
import { Observer } from 'mobx-react-lite';
import PageSubTitle from '../../../components/page-sub-title/page-sub-title';
import authContext from '../../../stores/auth-store';
import EmailsTable from './emails-table/emails-table';
import AddEmails from './emails-table/AddEmails';
import palette from '#/resources/palette';
import deskContext from '../../../stores/desk-store';
import { isEmpty } from 'lodash';

const TradeRecaps = () => {
  const auth = React.useContext(authContext);
  const desk = React.useContext(deskContext);
  const AddEmailsIcon = React.useMemo(
    () => (
      <Tooltip title="Add new email" enterDelay={300}>
        <div
          style={{
            color: palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <IconButton color="primary">
            <Add />
          </IconButton>
        </div>
      </Tooltip>
    ),
    [],
  );

  return (
    <Observer>
      {() => (
        <div data-testid="recaps">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <PageSubTitle text="ADDITIONAL EMAILS FOR TRADE RECAPS" />
            </Grid>
            <Grid item>
              {auth.canManageTradingDesk && isEmpty(desk.getEmails) && (
                <Grid item>
                  <AddEmails children={AddEmailsIcon} source={desk} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <div style={{ margin: '16px -40px 0' }}>
            <EmailsTable source={desk} />
          </div>
        </div>
      )}
    </Observer>
  );
};

export default TradeRecaps;
