import { makeStyles } from '@material-ui/core/styles';
import { observer, Observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from './layout/layout';
import LoadingScreen from './layout/loading-screen/loading-screen';
import NotFound from './pages/not-found/not-found';
import routesContext, { DEPRECATED_STATS_ROUTE } from './stores/routes-store';
import authStore from './stores/auth-store';
import globalStore from './stores/global-store';
import { getToken } from './utils/storage';
import FeatureFlagLoader from './components/FeatureFlagLoader';

const useStyles = makeStyles({
  '@global': {
    '::-webkit-scrollbar-track': {
      borderRadius: 8,
    },
    '::-webkit-scrollbar': {
      width: 8,
      height: 10,
      backgroundColor: 'transparent',
      backgroundClip: 'padding-box',
      border: '8px solid transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      boxShadow: 'inset 0 0 0 8px',
      color: 'rgba(31, 71, 156, 0.356)',
    },
    '::-webkit-scrollbar-corner': {
      background: 'rgba(0,0,0,0)',
    },

    '#root': {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.5)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    '@keyframes fetching-messages-ellipsis1': {
      '0%': {
        transform: 'scale(0)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    '@keyframes fetching-messages-ellipsis3': {
      '0%': {
        transform: 'scale(1)',
      },
      '100%': {
        transform: 'scale(0)',
      },
    },
    '@keyframes fetching-messages-ellipsis2': {
      '0%': {
        transform: 'translate(0, 0)',
      },
      '100%': {
        transform: 'translate(19px, 0)',
      },
    },
  },
});

const App = observer(() => {
  useStyles();
  const auth = React.useContext(authStore);
  const global = React.useContext(globalStore);
  const routes = React.useContext(routesContext);

  React.useEffect(() => {
    if (NODE_ENV !== 'production' && !document.title.includes(NODE_ENV)) {
      document.title += ` [${NODE_ENV}]`;
    }
  }, []);

  if ((getToken() && auth.fetching) || auth.fetchingDesk)
    return <LoadingScreen />;

  const appRoutes = (
    <Switch>
      {routes.flattenedRoutes.map(({ Component, authGuard, routeProps }) => (
        <Route
          key={routeProps.path}
          {...routeProps}
          render={(props) => (
            <Observer
              render={() => {
                if (!auth.valid) {
                  if (props.location.pathname !== '/') {
                    global.setRedirect(
                      `${props.location.pathname}${props.location.search}`,
                    );
                  }
                  if (authGuard) return <Redirect to="/" />;
                  return <Component {...props} />;
                } else {
                  const next = props.location.pathname;
                  if (
                    routes.notGuarded
                      .map((i) => i.routeProps.path)
                      .includes(next)
                  ) {
                    return (
                      <Redirect to={global.redirect || '/desk-management'} />
                    );
                  }
                  return <Component {...props} />;
                }
              }}
            />
          )}
        />
      ))}
      <Route
        render={({ location: { pathname: nextRoute } }) => (
          <Observer
            render={() => {
              if (!getToken()) return <Redirect to="/" />;
              if (!routes.fetched && routes.asyncRoutes.includes(nextRoute))
                return null;

              // Should redirect to desk management page when trying to reach deprecated page
              if (nextRoute === DEPRECATED_STATS_ROUTE)
                return <Redirect to="/desk-management" />;

              return <NotFound />;
            }}
          />
        )}
      />
    </Switch>
  );

  return (
    <BrowserRouter>
      {!auth.valid ? (
        appRoutes
      ) : (
        <Layout>
          {appRoutes}
          <FeatureFlagLoader />
        </Layout>
      )}
    </BrowserRouter>
  );
});

export default App;
