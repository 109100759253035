import React from 'react';
import deskContext from '../../stores/desk-store';
import LogExport from './components/log-export';
import TradeRecaps from './components/trade-recaps';
import { withRouter } from 'react-router';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import { useTabQueryString } from '../../hooks/use-tab-query-string';

const useStyles = makeStyles({
  tabs: {
    paddingBottom: 20,
  },
  tab: {
    width: 220,
  },
});

const Compliance = ({ location, history }) => {
  const desk = React.useContext(deskContext);
  const classes = useStyles();
  const { tab, setTab } = useTabQueryString('logs');

  React.useEffect(() => {
    desk.fetch();
  }, [desk]);

  return (
    <div data-testid="compliance-page">
      <Tabs
        value={tab}
        onChange={(_, selectedTab) => setTab(selectedTab)}
        className={classes.tabs}
      >
        <Tab value="logs" className={classes.tab} label="Export Logs" />
        <Tab value="recaps" className={classes.tab} label="Trade Recaps" />
      </Tabs>
      {tab === 'logs' && <LogExport />}
      {tab === 'recaps' && <TradeRecaps />}
    </div>
  );
};

export default withRouter(Compliance);
