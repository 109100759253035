import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useTabQueryString = (defaultTab) => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tab') || defaultTab;

  const setTab = useCallback(
    (nextTab) => {
      const nextSearchParams = new URLSearchParams(history.location.search);
      nextSearchParams.set('tab', nextTab);
      history.replace({ search: nextSearchParams.toString() });
    },
    [history],
  );

  return { tab, setTab };
};
