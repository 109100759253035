import React from 'react';
import { Observer } from 'mobx-react-lite';
import { isEmpty, map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ParadigmSwitch from '../../../../material/switch';
import CreateCredential from '../create-credential/create-credential';
import TableAction from './table-action';
import authContext from '../../../../stores/auth-store';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 170px)',
  },
  tableRow: {
    verticalAlign: 'top',
  },
  new: {
    display: 'flex',
    backgroundColor: '#1C3344',
    borderRadius: 15,
    width: 167,
    height: 30,
    cursor: 'pointer',
  },
  text: {
    marginTop: 5,
    opacity: 0.7,
  },
  ip: {
    margin: 0,
  },
}));

const CredentialsTable = (props) => {
  const { source } = props;
  const auth = React.useContext(authContext);
  const classes = useStyles();
  const hasItems = !isEmpty(source.apiCredentials);
  const [disabledIds, setDisabledIds] = React.useState([]);

  const handleUpdateActiveCredential = (credential) => {
    setDisabledIds((ids) => [...ids, credential.id]);
    source
      .updateCredential({ ...credential, is_enabled: !credential.isEnabled })
      .finally(() =>
        setDisabledIds((ids) => ids.filter((i) => i !== credential.id)),
      );
  };

  return (
    <Observer>
      {() => (
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: '70%', minWidth: 185 }}>
                  Name
                </TableCell>
                <TableCell align="left" style={{ width: '15%', minWidth: 185 }}>
                  IP Whitelist
                </TableCell>
                <TableCell align="left" style={{ width: '15%', minWidth: 185 }}>
                  Access Level
                </TableCell>
                <TableCell align="left" style={{ width: '15%', minWidth: 185 }}>
                  Access Key
                </TableCell>
                <TableCell
                  style={{ minWidth: 160, maxWith: 160, paddingRight: 0 }}
                >
                  Active
                </TableCell>
                {auth.isAdmin && (
                  <TableCell align="left" style={{ minWidth: 106 }} />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(source.apiCredentials, (apiCredential) => (
                <TableRow key={apiCredential.id} className={classes.tableRow}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ paddingTop: 18 }}
                  >
                    {apiCredential.name}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ paddingTop: 18 }}
                  >
                    {map(apiCredential.getAllowedCidrs, (ip, i) => (
                      <p key={i} className={classes.ip}>
                        {ip}
                      </p>
                    ))}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ paddingTop: 18 }}
                  >
                    {apiCredential.readOnly ? 'Read-only' : 'Read/Write'}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ paddingTop: 18 }}
                  >
                    {apiCredential.formattedAccessKey}
                  </TableCell>
                  <TableCell align="left" style={{ paddingRight: 0 }}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <ParadigmSwitch
                          disabled={
                            !(auth.isAdmin || auth.isTrader) ||
                            disabledIds.includes(apiCredential.id)
                          }
                          checked={Boolean(apiCredential.isEnabled)}
                          onChange={() =>
                            handleUpdateActiveCredential(apiCredential)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textSecondary"
                          style={{ marginLeft: 10 }}
                        >
                          {apiCredential.isEnabled ? 'Active' : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  {auth.isAdmin && (
                    <TableCell align="left">
                      <TableAction credential={apiCredential} source={source} />
                    </TableCell>
                  )}
                </TableRow>
              ))}
              <TableRow
                hover={false}
                style={{ visibility: hasItems ? 'hidden' : 'visible' }}
              >
                <TableCell align="center" colSpan={auth.isAdmin ? 5 : 4}>
                  <CreateCredential
                    source={source}
                    children={
                      <div className={classes.container}>
                        <div className={classes.new}>
                          <AddIcon
                            style={{
                              color: '#8AB4EF',
                              marginRight: 5,
                              marginLeft: 11,
                              marginTop: 2,
                            }}
                          />
                          <span className={classes.text}>Create New Key</span>
                        </div>
                      </div>
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Observer>
  );
};

export default CredentialsTable;
