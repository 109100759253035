import { globalStore } from '#/stores/global-store';
import { auth } from '#/stores/auth-store';
import { getToken, setToken } from '#/utils/storage';

/**
 * Update the token if the response contains a refreshed token.
 * @param resp Fetch response
 */
function updateTokenIfAvailable(resp) {
  const refreshedToken = resp.headers['refreshed-token'];
  if (refreshedToken != null) setToken(refreshedToken);
}

export function getGlobalError(data) {
  return (
    data?.non_field_errors ||
    data?.detail ||
    data?.message ||
    data?.totp_token ||
    data?.access_secret
  );
}

export function requestOnFulfilled(config) {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }
  return config;
}

export function requestOnRejected(error) {
  return Promise.reject(error);
}

export function responseOnFulfilled(response) {
  updateTokenIfAvailable(response);
  return response.data;
}

export function responseOnRejected({
  response: { data, status, headers } = {},
  message,
}) {
  const globalError = getGlobalError(data);
  if (status !== 403 && status !== 401) {
    globalError && auth.valid && globalStore.setError(globalError);
  } else {
    auth.failedAuth('Session expired. Please log in');
  }
  return Promise.reject(globalError || message);
}
