import papi from './_papi';
import papiFs from './_papi-fs';

export const getMmpStatus = (product) => {
  switch (product) {
    case 'grfq':
      return papi.get('/v1/ob/mmp/status');
    case 'fs':
      return papiFs.get('/v1/fs/mmp/status');
    // no default
  }
};

export const resetMmpStatus = (product) => {
  switch (product) {
    case 'grfq':
      /**
       * Note:
       * `/v1/grfq/mmp` and `/v1/drfq/mmp` are interchangeable, we have two endpoints to keep API consistent
       * we chose to use `grfq` for the Front-end
       */
      return papi.patch('/v1/ob/mmp/status');
    case 'fs':
      return papiFs.patch('/v1/fs/mmp/status');
    // no default
  }
};
