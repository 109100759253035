import React from 'react';

const CalendarIcon = (props) => (
  <svg width="16px" height="18px" viewBox="0 0 16 18" {...props}>
    <defs>
      <path
        id="a"
        d="M0.373450736 0.287815706L2.80608486 0.287815706 2.80608486 2.72021327 0.373450736 2.72021327z"
      />
      <path
        id="c"
        d="M0.170166108 0.287815706L2.60280023 0.287815706 2.60280023 2.72021327 0.170166108 2.72021327z"
      />
    </defs>
    <g
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      opacity={0.699999988}
    >
      <path
        d="M13.093 17.454H2.865c-1.04 0-1.885-1.035-1.885-2.312V5.533c0-1.276.844-2.312 1.885-2.312h10.228c1.041 0 1.885 1.036 1.885 2.312v9.609c0 1.277-.844 2.312-1.885 2.312z"
        stroke="#FFF"
        strokeLinecap="round"
        transform="translate(-419 -351) translate(300 317) translate(119 34)"
      />
      <path
        d="M6.153 10.114H4.88a.563.563 0 01-.563-.564V8.278c0-.312.252-.565.563-.565h1.273c.312 0 .564.253.564.565V9.55a.563.563 0 01-.564.564M11.077 10.114H9.805a.563.563 0 01-.564-.564V8.278c0-.312.252-.565.564-.565h1.272c.312 0 .564.253.564.565V9.55a.563.563 0 01-.564.564M6.153 14.668H4.88a.564.564 0 01-.563-.565v-1.272c0-.312.252-.564.563-.564h1.273c.312 0 .564.252.564.564v1.272a.564.564 0 01-.564.565M11.077 14.668H9.805a.563.563 0 01-.564-.565v-1.272c0-.312.252-.564.564-.564h1.272c.312 0 .564.252.564.564v1.272a.564.564 0 01-.564.565M13.093 3.22H2.865C1.825 3.22.98 4.257.98 5.535h13.998c0-1.278-.844-2.313-1.885-2.313"
        fill="#FFF"
        transform="translate(-419 -351) translate(300 317) translate(119 34)"
      />
      <g transform="translate(-419 -351) translate(300 317) translate(119 34) translate(3.154 .5)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M2.305.288H.875a.5.5 0 00-.502.5V2.72h2.433V.788a.5.5 0 00-.5-.5"
          fill="#FFF"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(-419 -351) translate(300 317) translate(119 34) translate(9.828 .5)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          d="M2.102.288H.671a.5.5 0 00-.5.5V2.72h2.432V.788a.5.5 0 00-.5-.5"
          fill="#FFF"
          mask="url(#d)"
        />
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
