import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router';
import BybitAccount from './bybit-account/bybit-account';
import RFQ from './rfq/rfq';
import bybitContext from '../../stores/bybit-store';
import { useTabQueryString } from '../../hooks/use-tab-query-string';

const useStyles = makeStyles({
  tabs: {
    paddingBottom: 20,
  },
  tab: {
    width: 220,
  },
});

const BybitGroup = ({ location, history }) => {
  const classes = useStyles();
  const bybit = React.useContext(bybitContext);
  const { tab, setTab } = useTabQueryString('options');

  React.useEffect(() => {
    bybit.fetch();
  }, [bybit]);

  return (
    <div data-testid="bybit">
      <Tabs
        value={tab}
        onChange={(_, selectedTab) => setTab(selectedTab)}
        className={classes.tabs}
      >
        <Tab value="options" className={classes.tab} label="RFQ" />
        <Tab value="account" className={classes.tab} label="Account" />
      </Tabs>
      {tab === 'options' && <RFQ source={bybit} />}
      {tab === 'account' && <BybitAccount source={bybit} />}
    </div>
  );
};

export default withRouter(BybitGroup);
