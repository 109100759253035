import React from 'react';
import { noop, omitBy } from 'lodash';
import * as Yup from 'yup';
import ParadigmModal from '../../../../material/modal';
import entitiesContext from '../../../../stores/entities-store';
import EditKeyFormFields from './edit-key-form-fields';
import { KeyForm } from '../key-form';

const EditKeys = ({ onClose, apiKey, source }) => {
  const entitiesStore = React.useContext(entitiesContext);
  const keysSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    entity: Yup.object().required('Entity is required'),
  });

  const {
    isValidCredentials = false,
    hasIpAllowlist = false,
    hasBlockTradeScope = false,
    hasBalancePermission = false,
    hasAvailableBalances = false,
    hasTradeScope = false,
  } = apiKey;

  const handleSubmit = (values) => {
    apiKey
      .update(omitBy(values, (x) => !x))
      .catch(noop)
      .finally(onClose);
  };

  return (
    <ParadigmModal title="Edit API Credentials" onClose={onClose}>
      <KeyForm
        onCancel={onClose}
        onSubmit={handleSubmit}
        validationSchema={keysSchema}
        venueShortName={source.shortName}
        initialValues={{
          name: apiKey.name,
          entity: entitiesStore.entities.get(apiKey.entity.id),
          access_key: '',
          access_secret: '',
        }}
        initialPermissions={{
          isValidCredentials,
          hasIpAllowlist,
          hasBlockTradeScope,
          hasBalancePermission,
          hasAvailableBalances,
          hasTradeScope,
        }}
      >
        <EditKeyFormFields apiKey={apiKey} onClose={onClose} />
      </KeyForm>
    </ParadigmModal>
  );
};

export default EditKeys;
