import * as Yup from 'yup';
import useToggle from '../../../../hooks/use-toggle';
import AddKeyFormFields from './add-key-form';
import { AddKeyIcon } from '../../account/components/add-keys/add-keys';
import { KeyForm } from '../key-form';
import ParadigmModal from '../../../../material/modal';
import { noop } from 'lodash';

const addKeySchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(64, 'Ensure this field has no more than 64 characters.'),
  access_key: Yup.string()
    .required('Client ID is required')
    .max(32, 'Ensure this field has no more than 32 characters.'),
  entity: Yup.object().required('Entity is required'),
  access_secret: Yup.string()
    .required('Access secret is required')
    .max(64, 'Ensure this field has no more than 64 characters.'),
});

const AddKey = ({ source }) => {
  const [open, toggle] = useToggle(false);
  const { addApiKey } = source;

  const handleSubmit = (key) => addApiKey(key).catch(noop).finally(toggle);

  return (
    <>
      <AddKeyIcon onClick={toggle} />
      {open && (
        <ParadigmModal title="Add API Credentials" onClose={toggle}>
          <KeyForm
            onCancel={toggle}
            initialValues={{
              name: '',
              entity: '',
              access_key: '',
              access_secret: '',
              venue: source.shortName,
            }}
            venueShortName={source.shortName}
            onSubmit={handleSubmit}
            validationSchema={addKeySchema}
            submitButtonText="Add"
          >
            <AddKeyFormFields source={source} onClose={toggle} />
          </KeyForm>
        </ParadigmModal>
      )}
    </>
  );
};

export default AddKey;
