import { SVGProps } from 'react';

export default function Warning(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) {
  return (
    <svg viewBox="0 0 14 13" version="1.1" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1207.000000, -388.000000)">
          <g transform="translate(250.000000, 192.000000)">
            <g transform="translate(50.000000, 188.500000)">
              <g transform="translate(903.000000, 4.000000)">
                <g transform="translate(4.980000, 4.648889)">
                  <path
                    d="M4.70430147,0.750738269 L0.205905011,8.45047429 C-0.379014096,9.45145865 0.351898552,10.7022222 1.52126429,10.7022222 L10.5185297,10.7022222 C11.6878954,10.7022222 12.4188081,9.45145865 11.8343614,8.45047429 L7.3354925,0.750738269 C6.75104587,-0.25024609 5.28922057,-0.25024609 4.70430147,0.750738269 Z"
                    stroke="#F4E100"
                  />
                  <path
                    d="M6.3189,7.08555556 C6.3385,6.91568889 6.35565,6.75643889 6.37035,6.60780556 C6.38505,6.45917222 6.3973,6.31380556 6.4071,6.17170556 C6.4169,6.02960556 6.42506667,5.88832222 6.4316,5.74785556 C6.43813333,5.60738889 6.4414,5.46365556 6.4414,5.31665556 L6.4414,5.31665556 L6.4414,2.51385556 L5.6084,2.51385556 L5.6084,5.31665556 C5.6084,5.46365556 5.61166667,5.60738889 5.6182,5.74785556 C5.62473333,5.88832222 5.6329,6.02960556 5.6427,6.17170556 C5.6525,6.31380556 5.66475,6.45917222 5.67945,6.60780556 C5.69415,6.75643889 5.7113,6.91568889 5.7309,7.08555556 L5.7309,7.08555556 L6.3189,7.08555556 Z M6.0102,9.60905556 C6.09513333,9.60905556 6.17435,9.59353889 6.24785,9.56250556 C6.32135,9.53147222 6.38586667,9.48818889 6.4414,9.43265556 C6.49693333,9.37712222 6.54103333,9.31260556 6.5737,9.23910556 C6.60636667,9.16560556 6.6227,9.08475556 6.6227,8.99655556 C6.6227,8.91162222 6.60636667,8.83158889 6.5737,8.75645556 C6.54103333,8.68132222 6.49693333,8.61598889 6.4414,8.56045556 C6.38586667,8.50492222 6.32135,8.46082222 6.24785,8.42815556 C6.17435,8.39548889 6.09513333,8.37915556 6.0102,8.37915556 C5.922,8.37915556 5.84115,8.39548889 5.76765,8.42815556 C5.69415,8.46082222 5.62963333,8.50492222 5.5741,8.56045556 C5.51856667,8.61598889 5.47528333,8.68132222 5.44425,8.75645556 C5.41321667,8.83158889 5.3977,8.91162222 5.3977,8.99655556 C5.3977,9.08475556 5.41321667,9.16560556 5.44425,9.23910556 C5.47528333,9.31260556 5.51856667,9.37712222 5.5741,9.43265556 C5.62963333,9.48818889 5.69415,9.53147222 5.76765,9.56250556 C5.84115,9.59353889 5.922,9.60905556 6.0102,9.60905556 Z"
                    fill="#F4E100"
                    fillRule="nonzero"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
