import styled from 'styled-components';

export const HelpText = styled.p`
  color: #97b2bd;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0;

  svg {
    width: 13px;
    margin-right: 0.5rem;
    vertical-align: middle;
  }
`;
