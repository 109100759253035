import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { isEmpty, map } from 'lodash';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import deskContext from '../../../../stores/desk-store';
import TableAction from './table-action';
import authContext from '../../../../stores/auth-store';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AddEmails from './AddEmails';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 170px)',
  },
  tableRow: {
    verticalAlign: 'top',
  },
  new: {
    display: 'flex',
    backgroundColor: '#1C3344',
    borderRadius: 15,
    width: 130,
    height: 30,
    cursor: 'pointer',
  },
  text: {
    marginTop: 5,
    opacity: 0.7,
  },
  email: {
    margin: 0,
  },
}));

const EmailsTable = (props) => {
  const { source } = props;
  const classes = useStyles();
  const desk = React.useContext(deskContext);
  const auth = React.useContext(authContext);

  return (
    <Observer>
      {() => (
        <Table data-testid="emails-table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '70%' }}>E-mails</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell component="th" scope="row" style={{ paddingTop: 18 }}>
                {map(desk.getEmails, (email, i) => (
                  <p key={i} className={classes.email}>
                    {email}
                  </p>
                ))}
              </TableCell>
              {auth.canManageTradingDesk && !isEmpty(desk.getEmails) && (
                <TableCell align="right" data-testid="m-action">
                  <TableAction member={desk} credential={desk} source={desk} />
                </TableCell>
              )}
            </TableRow>
            {isEmpty(desk.getEmails) && (
              <TableRow hover={false}>
                <TableCell
                  align="center"
                  colSpan={auth.canManageTradingDesk ? 4 : 3}
                >
                  <AddEmails
                    source={source}
                    children={
                      <div className={classes.container}>
                        <div className={classes.new}>
                          <AddIcon
                            style={{
                              color: '#8AB4EF',
                              marginRight: 5,
                              marginLeft: 11,
                              marginTop: 2,
                            }}
                          />
                          <span className={classes.text}>Add Emails</span>
                        </div>
                      </div>
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Observer>
  );
};

export default EmailsTable;
