import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import PageSubTitle from '../../../components/page-sub-title/page-sub-title';
import AddKey from './add-keys/add-key';
import authContext from '../../../stores/auth-store';
import KeysTable from './keys-table/keys-table';

const useStyles = makeStyles((theme) => ({
  left: {
    width: 130,
  },
  table: {
    margin: `${theme.spacing(1)}px -40px 0`,
    width: 'calc(100% + 80px)',
    overflow: 'auto',
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const auth = React.useContext(authContext);
  const { source } = props;

  return (
    <Observer>
      {() => (
        <Grid container direction="column">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <PageSubTitle text="API CREDENTIALS" />
            </Grid>
            {auth.isAdmin && (
              <Grid item>
                <AddKey source={source} />
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.table}>
            <KeysTable source={source} />
          </Grid>
        </Grid>
      )}
    </Observer>
  );
};

export default Account;
