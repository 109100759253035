import { assign, first, last, split } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import { updateSpotRfqApi } from '../../../api/spot-api';
import { CurrencyFormatter as formatter } from '../../../utils/common';

export default class SpotRfq {
  baseSize;
  quoteSize;
  isEnabled;
  executionWindow;
  pair;

  constructor(config) {
    makeObservable(this, {
      baseSize: observable,
      quoteSize: observable,
      isEnabled: observable,
      executionWindow: observable,
      pair: observable,
      update: action,
      toggleEnabled: action,
    });

    this.id = config.id;
    this.baseSize = config.base_currency_max_size;
    this.quoteSize = config.quote_currency_max_size;
    this.isEnabled = config.is_enabled;
    this.executionWindow = config.execution_window;
    this.pair = config.spot_trading_pair;
  }

  update = async (data) => {
    const response = await updateSpotRfqApi({ id: this.id, ...data });
    assign(this, new SpotRfq(response));
  };

  toggleEnabled = async () => {
    try {
      this.isEnabled = !this.isEnabled;
      await this.update({ is_enabled: this.isEnabled });
    } catch (err) {
      this.isEnabled = !this.isEnabled;
    }
  };

  get quoteCurrency() {
    return last(split(this.pair, '/'));
  }

  get baseCurrency() {
    return first(split(this.pair, '/'));
  }

  get formattedBaseSize() {
    return `${formatter.short({ amount: this.baseSize, symbol: '' })} ${
      this.baseCurrency
    }`;
  }

  get formattedQuoteSize() {
    return `${formatter.short({ amount: this.quoteSize })} ${
      this.quoteCurrency
    }`;
  }
}
