import { createTheme } from '@material-ui/core';
import palette from './palette';
import { portage10 } from '@paradigm/design-system/src/colors';

const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100, 10);
  G = parseInt((G * (100 + percent)) / 100, 10);
  B = parseInt((B * (100 + percent)) / 100, 10);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  let RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

export default createTheme({
  palette,
  utils: {
    shadeColor,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTooltip: {
      placement: 'top',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
  typography: {
    fontFamily: '"IBM Plex Sans", "Roboto"',
    button: {
      textTransform: 'none',
      fontSize: 13,
    },
  },
  overrides: {
    MuiTypography: {
      colorTextSecondary: {
        fontSize: '0.8rem',
        'a&': {
          color: 'rgba(255, 255, 255, 0.25)',
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginRight: 4,
      },
      positionStart: {
        marginLeft: 8,
        marginRight: 0,
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          '& svg': {
            fill: '#5877a5',
          },
        },
        '&$error': {
          '& svg': {
            fill: palette.error.main,
          },
        },
      },
      formControl: {
        top: -4,
      },
    },
    MuiTabs: {
      root: {
        marginBottom: 16,
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        backgroundColor: palette.background.paper,
        '&$selected': {
          backgroundColor: palette.primary.main,
        },
      },
      textColorInherit: {
        color: palette.text.primary,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(24,35,50,0.9)',
      },
    },
    MuiFormControlLabel: {
      label: {
        color: 'white',
        opacity: 0.5,
        fontSize: 13,
        '&$disabled': {
          color: 'white',
          opacity: 0.3,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#182332',
        margin: '0 -40px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 7,
      },
    },
    MuiTableCell: {
      stickyHeader: {
        borderTop: `1px solid rgba(255, 255, 255, 0.05)`,
        borderBottom: `1px solid rgba(255, 255, 255, 0.05)`,
        paddingTop: 4,
        paddingBottom: 4,
      },
      root: {
        borderBottom: 'none',
        paddingLeft: 38,
        paddingTop: 8,
        paddingBottom: 8,
      },
      body: {
        color: 'rgba(255, 255, 255, 0.7)',
      },
      head: {
        color: palette.primary.main,
        fontSize: '0.75rem',
      },
    },
    MuiTableRow: {
      head: {
        height: 48,
        borderTop: `1px solid rgba(255, 255, 255, 0.05)`,
        borderBottom: `1px solid rgba(255, 255, 255, 0.05)`,
      },
      root: {
        height: 48,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.background.paper,
      },
    },
    MuiSvgIcon: {
      colorAction: {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
    MuiFormLabel: {
      root: {
        '&$disabled': {
          color: palette.text.dark,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: palette.background.paperContrast,
        '&$disabled': {
          color: palette.text.secondary,
        },
      },
      input: {
        textIndent: 8,
        fontSize: '0.94rem',
        paddingBottom: 9,
      },
      inputMultiline: {
        textIndent: 0,
        padding: '0 8px',
      },
    },
    MuiInput: {
      input: {
        paddingTop: 9,
      },
    },
    MuiSelect: {
      icon: {
        fill: 'white',
      },
    },
    MuiCheckbox: {
      root: {
        color: 'transparent',
      },
      colorPrimary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: '#0F2038',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: 'white',
        backgroundColor: '#0F2038',
        '&[disabled]': {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      transitionContainer: {
        opacity: 0.7,
        fontWeight: 'bold',
      },
      dayLabel: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
        opacity: 0.7,
        margin: '0 5px',
      },
    },
    MuiPickersDay: {
      day: {
        margin: '0 5px',
        '& p': {
          fontSize: 12,
          fontWeight: 'bold',
        },
      },
      daySelected: {
        border: '3px solid #182332',
      },
      dayDisabled: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
    },
    MuiSwitch: {
      root: {
        marginLeft: -11,
      },
      switchBase: {
        height: 'none',
        '&:not($checked)': {
          transform: 'translateX(-8px)',
        },
        '&$checked': {
          transform: 'translateX(18px)',
        },
        width: 58,
      },
      colorSecondary: {
        '&$checked + $track': {
          opacity: 1,
        },
        '&$disabled&$switchBase + $track': {
          backgroundColor: palette.background.paper,
          opacity: 1,
        },
      },
      colorPrimary: {
        '&$checked + $track': {
          opacity: 1,
        },
        '&$disabled&$switchBase + $track': {
          backgroundColor: palette.background.paper,
          opacity: 1,
        },
      },
      thumb: {
        backgroundColor: 'white',
        height: 22,
        width: 22,
      },
      track: {
        backgroundColor: palette.error.main,
        opacity: 1,
        height: 22,
        minWidth: 45,
        borderRadius: 100,
        marginTop: -3,
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: palette.background.default,
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 32px 32px',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '12px 24px 24px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '32px 32px 24px',
      },
    },
    MuiTextField: {
      root: {
        '&.different': {
          '& > div': {
            borderRadius: 3,
            height: 40,
            '& input::placeholder': {
              opacity: 1,
            },
            backgroundColor: '#27364C',
          },
        },
        '&.rounded': {
          '& > div': {
            borderRadius: 20,
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 100,
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: shadeColor(palette.primary.main, -15),
          '&:disabled': {
            backgroundColor: palette.background.paper,
          },
        },
        '&:active': {
          backgroundColor: shadeColor(palette.primary.main, -25),
        },
        '&:disabled': {
          color: 'white',
          opacity: 0.2,
          backgroundColor: '#27364c',
        },
      },
      text: {
        fontSize: 14,
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.9rem',
        paddingTop: 6,
        paddingBottom: 6,
        '&$selected, &$selected:focus': {
          backgroundColor: palette.primary.main,
        },
        '&$selected:hover': {
          backgroundColor: palette.primary.main,
        },
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 25,
      },
      root: {
        '&$selected, &$selected:focus, &$selected:hover': {
          backgroundColor: portage10,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          bottom: '-0.9rem',
          fontSize: '0.6rem',
          position: 'absolute',
        },
      },
    },
    MuiLink: {
      root: {
        fontSize: 14,
        cursor: 'pointer',
        '&:hover': {
          color: palette.primary.main,
        },
      },
    },
  },
});
