const IconWarning = (props) => (
  <svg width="23px" height="22px" viewBox="0 0 23 22" version="1.1" {...props}>
    <title>84D1935F-5CAC-43D1-96D9-505BFBBF2CF3</title>
    <g
      id="Derivatives-/-Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="derivatives-/-settings-/-2"
        transform="translate(-315.000000, -381.000000)"
        stroke="#F1E20D"
      >
        <g id="icon-warning-red" transform="translate(316.000000, 382.666667)">
          <path
            d="M8.20517698,1.30942721 L0.359136647,14.7391993 C-0.661071097,16.4851023 0.613776544,18.6666667 2.65336795,18.6666667 L18.3462727,18.6666667 C20.3858641,18.6666667 21.6607117,16.4851023 20.6413281,14.7391993 L12.7944637,1.30942721 C11.77508,-0.436475738 9.22538472,-0.436475738 8.20517698,1.30942721 Z"
            id="Stroke-1"
            strokeWidth="2"
          ></path>
          <path
            d="M11.1230969,5.18139319 L11.1230969,9.23152295 C11.1230969,9.44394341 11.1183765,9.65164029 11.1089356,9.85461984 C11.0994947,10.0575994 11.0876937,10.261756 11.0735324,10.4670958 L11.0496352,10.7786442 L11.0496352,10.7786442 L11.0204275,11.0972733 C10.9991855,11.312054 10.9744035,11.5421727 10.9460807,11.7876363 L10.0964032,11.7876363 L10.0444784,11.3171669 L10.0444784,11.3171669 L9.99284869,10.7786442 L9.99284869,10.7786442 L9.96895152,10.4670958 C9.95479015,10.261756 9.94298919,10.0575994 9.93354829,9.85461984 C9.92410738,9.65164029 9.91938699,9.44394341 9.91938699,9.23152295 L9.91938699,5.18139319 L11.1230969,5.18139319 Z M9.6149192,14.5490884 C9.6149192,14.4263566 9.63734102,14.3107072 9.68218534,14.2021367 C9.72702966,14.0935663 9.78957474,13.9991586 9.86982247,13.9189109 C9.9500702,13.8386632 10.0432978,13.774938 10.149508,13.7277334 C10.2557182,13.6805289 10.3725477,13.656927 10.5,13.656927 C10.6227318,13.656927 10.7372011,13.6805289 10.8434114,13.7277334 C10.9496216,13.774938 11.0428492,13.8386632 11.1230969,13.9189109 C11.2033446,13.9991586 11.2670698,14.0935663 11.3142743,14.2021367 C11.3614789,14.3107072 11.3850808,14.4263566 11.3850808,14.5490884 C11.3850808,14.6765407 11.3614789,14.7933702 11.3142743,14.8995804 C11.2670698,15.0057906 11.2033446,15.0990182 11.1230969,15.179266 C11.0428492,15.2595137 10.9496216,15.3220588 10.8434114,15.3669031 C10.7372011,15.4117474 10.6227318,15.4341692 10.5,15.4341692 C10.3725477,15.4341692 10.2557182,15.4117474 10.149508,15.3669031 C10.0432978,15.3220588 9.9500702,15.2595137 9.86982247,15.179266 C9.78957474,15.0990182 9.72702966,15.0057906 9.68218534,14.8995804 C9.63734102,14.7933702 9.6149192,14.6765407 9.6149192,14.5490884 Z"
            id="!"
            fill="#F1E20D"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default IconWarning;
