import { isFunction } from 'lodash';
import * as PropTypes from 'prop-types';
import React from 'react';
import useFormProps from '../hooks/use-form-props';
import SelectAsync from './select/select-async';
import SelectSync from './select/select-sync';

const ParadigmSelect = ({
  data,
  autoFocus,
  label,
  field,
  form,
  required,
  ...rest
}) => {
  const formProps = useFormProps({ field, form, required });
  const props = { textFieldProps: { ...formProps, autoFocus, label } };

  if (form) {
    const getTarget = (value) => ({ name: field.name, id: field.name, value });
    if (!rest.onChange && form) {
      props.value = field.value;
      props.onChange = (data) => {
        field.onChange({ target: getTarget(data) });
      };
    }
    props.onBlur = () => {
      field.onBlur({ target: getTarget(field.value) });
    };
  }

  return isFunction(data) ? (
    <SelectAsync promise={data} {...rest} {...props} />
  ) : (
    <SelectSync options={data} {...rest} {...props} />
  );
};

ParadigmSelect.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    PropTypes.func,
  ]).isRequired,
};

export default ParadigmSelect;
