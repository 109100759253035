import IconButton from '@material-ui/core/IconButton';
import { MoreVert } from '@material-ui/icons';
import { map, omit } from 'lodash';
import React from 'react';
import NotifyTooltip from '../../../components/notify-tooltip/notify-tooltip';
import ParadigmMenu from '../../../material/menu';
import ParadigmConfirmModal from '../../../material/confirmation-modal';
import { ROLES } from '../../../resources/config';
import { ReportProblemOutlined } from '@material-ui/icons';

const TableAction = ({ member }) => {
  const [anchor, setAnchor] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState(false);

  const openMenu = ({ currentTarget }) => setAnchor(currentTarget);
  const closeMenu = () => setAnchor(null);

  const convertTo = (type) => () => member.convertTo(type);

  return (
    <>
      {member.isAdmin ? (
        <IconButton align="right" color="inherit">
          <NotifyTooltip
            placement="left"
            flow="block"
            text="To convert Admin to a User or Analyst, you must first assign a
                  new Desk Admin above. Existing Desk Admin will automatically
                  be converted to a Trader account."
          />
        </IconButton>
      ) : (
        <IconButton align="right" style={{ color: 'white' }} onClick={openMenu}>
          <MoreVert fontSize="small" />
        </IconButton>
      )}
      <ParadigmMenu
        options={[
          {
            title: 'Remove member',
            onClick: () => setConfirmation(true),
          },
          { title: 'Convert to' },
          ...map(omit(ROLES, [member.type, 'AD']), (role, short) => ({
            title: role.name,
            onClick: convertTo(short),
          })),
        ]}
        anchor={anchor}
        onClose={closeMenu}
      />
      {confirmation && (
        <ParadigmConfirmModal
          icon={<ReportProblemOutlined color="error" />}
          title="Warning!"
          message="This member will be removed from the Trading Desk and lose access to all of their existing Trade Channels."
          onReject={() => setConfirmation(false)}
          onConfirm={member.remove}
        />
      )}
    </>
  );
};

export default TableAction;
