import styled from 'styled-components';
import {
  firefly,
  pickledBluewood,
  razzmatazz,
} from '@paradigm/design-system/src/colors';
import CheckMark from './check-mark';
import Warning from './warning';

interface Props {
  readonly isGranted?: boolean;
  readonly label: string;
  readonly showLabel: boolean;
  readonly showWarningIfDenied?: boolean; // will show a warning symbol instead of a cross if permission is denied
}

function makeTestId({ label, isGranted }: Props) {
  const state =
    isGranted === true ? 'granted' : isGranted === false ? 'denied' : 'unknown';
  const formattedLabel = label.toLowerCase().trim().replaceAll(' ', '-');
  return `${formattedLabel}-permission-${state}`;
}

export const Permission = ({
  showWarningIfDenied = false,
  ...props
}: Props) => {
  const isGranted = props.isGranted === true;
  const isDenied = props.isGranted === false;
  const testId = makeTestId(props);
  const label = props.showLabel && <Label {...props}>{props.label}</Label>;
  const icon = isGranted ? (
    <CheckIcon />
  ) : isDenied ? (
    showWarningIfDenied ? (
      <WarningIcon />
    ) : (
      <CrossIcon />
    )
  ) : (
    <DefaultIcon />
  );
  return (
    <Container data-testid={testId} {...props}>
      {icon}
      {label}
    </Container>
  );
};

const CheckIcon = () => (
  <CheckContainer>
    <CheckMark />
  </CheckContainer>
);

const CrossIcon = () => {
  const cross = require('./cross.png') as string;
  return (
    <CrossContainer>
      <img src={cross} alt="Cross" />
    </CrossContainer>
  );
};

const WarningIcon = () => (
  <WarningContainer>
    <Warning />
  </WarningContainer>
);

const Container = styled.div`
  display: flex;
`;

const Icon = styled.span`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span`
  line-height: 22px;
  text-transform: capitalize;
`;

const DefaultIcon = styled(Icon)`
  ::after {
    content: '...';
    font-weight: bolder;
    color: #3b679c;
  }
`;

const CheckContainer = styled(Icon)`
  border-color: ${pickledBluewood};
  background-color: ${firefly};
  svg {
    width: 14px;
    height: 9px;
  }
`;

const CrossContainer = styled(Icon)`
  border-color: ${razzmatazz};
  background-color: rgba(234, 19, 94, 0.33);
`;

const WarningContainer = styled(Icon)`
  border-color: #f4e10050;
  background-color: rgba(243, 229, 0, 0.16);
  svg {
    width: 13px;
    height: 10px;
  }
`;
