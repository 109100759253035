import React from 'react';
import Day from '@material-ui/pickers/views/Calendar/Day';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ParadigmButton from '../../../material/button';
import ParadigmModal, { ModalActionsWrapper } from '../../../material/modal';

const DatepickerModal = ({ children, title, value, onChange, ...rest }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [date, setDate] = React.useState(new Date());

  React.useEffect(() => {
    value && setDate(value);
  }, [value, setDate]);

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => setIsOpen(true),
      })}
      {isOpen && (
        <ParadigmModal
          title={title}
          onClose={() => setIsOpen(false)}
          noScroll
          PaperProps={{ style: { maxWidth: 400 } }}
        >
          <KeyboardDatePicker
            disableToolbar
            format="MM/dd/yy"
            placeholder="MM/dd/yy"
            variant="static"
            value={date}
            onChange={setDate}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              if (!isInCurrentMonth) {
                return (
                  <Day hidden={false} disabled>
                    {day.getDate()}
                  </Day>
                );
              }
              return dayComponent;
            }}
            {...rest}
          />
          <ModalActionsWrapper>
            <ParadigmButton
              text="Cancel"
              variant="text"
              secondary
              onClick={() => setIsOpen(false)}
            />
            <ParadigmButton
              text="Confirm"
              onClick={() => {
                onChange(date);
                setIsOpen(false);
              }}
            />
          </ModalActionsWrapper>
        </ParadigmModal>
      )}
    </>
  );
};

export default DatepickerModal;
