import { keyBy, find } from 'lodash';
import {
  action,
  observable,
  computed,
  makeObservable,
  runInAction,
} from 'mobx';
import { createContext } from 'react';
import { getInfoApi } from '../api/products-api';
import {
  updateBitcomRfqApi,
  addAccessKeyApi,
  removeAccessKeyApi,
} from '../api/bitcom-api';

import AccessKey from './classes/bitcom/bitcom-access-key';
import OptionsRfq from './classes/options/options-rfq';
import Products from './classes/product-store';

export class Bitcom extends Products {
  name = 'Bitcom';
  shortName = 'BIT';
  longName = 'bitcom';
  rfq;
  accessKeys = {};
  ready;

  setRfq = (optionConfigs, enabled) => {
    this.rfq = keyBy(
      optionConfigs.map(
        (optionConfig) =>
          new OptionsRfq(optionConfig, updateBitcomRfqApi, this.name),
      ),
      'id',
    );
  };

  setApiKeys = (keys) => {
    this.accessKeys = keyBy(
      keys.map((i) => new AccessKey(i)),
      'id',
    );
  };

  addApiKey = async (key) => {
    const createdKey = await addAccessKeyApi({
      ...key,
      entity: key.entity.id,
    });
    const newKeys = new AccessKey({ ...createdKey, entity: key.entity });
    this.accessKeys[newKeys.id] = newKeys;
  };

  removeApiKey = async (key) => {
    await removeAccessKeyApi(key.id);
    delete this.accessKeys[key.id];
  };

  fetch = async () => {
    const bitcomInfo = await getInfoApi(this.longName);
    this.setRfq(bitcomInfo.configs, bitcomInfo.rfq_is_enabled);
    this.setApiKeys(bitcomInfo.api_credentials);
    runInAction(() => (this.ready = true));
  };

  reset = () => {
    this.rfq = null;
    this.ready = null;
  };

  constructor() {
    super();

    makeObservable(this, {
      shortName: observable,
      longName: observable,
      rfq: observable,
      accessKeys: observable,
      ready: observable,
      setRfq: action,
      setApiKeys: action,
      addApiKey: action,
      removeApiKey: action,
      fetch: action,
      reset: action,
      defaultPair: computed,
    });
  }

  get defaultPair() {
    if (!this.ready) return { id: '', isEnabled: false };
    return find(
      this.rfq,
      (optionConfig) => optionConfig && optionConfig.pair.includes('BTC'),
    );
  }
}

export const bitcom = new Bitcom();
window.bitcom = bitcom;

export default createContext(bitcom);
