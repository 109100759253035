import { Formik, Form } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ModalActionsWrapper } from '../../../material/modal';
import { useMarketIntegrityMarginFeatureFlag } from '@paradigm/features/src/feature-flags/context';
import ParadigmButton from '../../../material/button';
import { validateVenueKeyApi } from '../../../api/desk-api';
import styled from 'styled-components';
import { Permission as BasePermission } from '@paradigm/features/src/permission';
import WarningIcon from '@paradigm/features/src/permission/warning';
import { HelpText } from './help-text';
import entitiesContext from '../../../stores/entities-store';

function FormContainer({
  children,
  isSubmitDisabled,
  isSubmitting,
  onCancel,
  disableSubmit,
  submitButtonText,
  venueShortName,
  values,
  dirty,
  initialPermissions,
  setFieldValue,
  errors,
}) {
  const hasErrors = Object.keys(errors).length > 0;
  const isMarginFeatureEnabled = useMarketIntegrityMarginFeatureFlag();
  const { access_key: apiKey, access_secret: apiSecret } = values;
  const entitiesStore = React.useContext(entitiesContext);

  const [permissions, setPermissions] = useState(initialPermissions);
  const controller = useRef(null);

  const { isValidCredentials, hasIpAllowlist, hasBlockTradeScope } =
    permissions;

  useEffect(() => {
    if (entitiesStore.entities?.size == 1) {
      const value = entitiesStore.entities.values().next().value;
      setFieldValue('entity', value);
    }
  }, [entitiesStore.entities, setFieldValue]);

  useEffect(() => {
    disableSubmit(
      isValidCredentials === false &&
        hasIpAllowlist === false &&
        hasBlockTradeScope === false,
    );
  }, [disableSubmit, hasBlockTradeScope, hasIpAllowlist, isValidCredentials]);

  const fetchPermissions = useCallback(() => {
    if (apiKey === '' || apiSecret === '') return;
    controller.current = new AbortController();
    return validateVenueKeyApi(
      {
        venue: venueShortName,
        apiKey,
        apiSecret,
      },
      { signal: controller.current.signal },
    );
  }, [apiKey, apiSecret, venueShortName]);

  useEffect(() => {
    if (!isMarginFeatureEnabled) return;

    const updatePermissions = () => {
      controller.current?.abort();
      fetchPermissions()
        ?.then((res) => {
          setPermissions({
            isValidCredentials: res.is_valid_credential ?? false,
            hasIpAllowlist: res.has_ip_allowlisting ?? false,
            hasBlockTradeScope: res.has_block_trade_permission ?? false,
            hasBalancePermission: res.has_balance_permission ?? false,
            hasAvailableBalances: res.has_available_balances ?? false,
            hasTradeScope: res.has_position_readable_permission ?? false,
          });
        })
        ?.catch((error) => {
          if (error === 'canceled') return;
          setPermissions({
            isValidCredentials: false,
            hasIpAllowlist: false,
            hasBlockTradeScope: false,
            hasBalancePermission: false,
            hasAvailableBalances: false,
            hasTradeScope: false,
          });
        });
    };
    updatePermissions();
    const intervalID = setInterval(updatePermissions, 1000);
    return () => {
      clearInterval(intervalID);
      controller.current?.abort();
    };
  }, [fetchPermissions, isMarginFeatureEnabled]);

  return (
    <Form noValidate>
      {children}
      <HealthStatus {...permissions} />
      {isMarginFeatureEnabled && (
        <HelpText css="display: flex;">
          <span>
            <WarningIcon />
          </span>
          <span>
            If the Balance Scope permission is not enabled, margin-related Trade
            Rejections you are responsible for will result in a Trade Rejection
            Lockout from trading on that venue.
          </span>
        </HelpText>
      )}
      <ModalActionsWrapper>
        <ParadigmButton secondary text="Cancel" onClick={onCancel} />
        <ParadigmButton
          type="submit"
          busy={isSubmitting}
          text={submitButtonText}
          disabled={isSubmitDisabled || !dirty || hasErrors}
        />
      </ModalActionsWrapper>
    </Form>
  );
}

export function KeyForm({
  children,
  initialPermissions = {},
  initialValues,
  onCancel,
  onSubmit,
  validationSchema,
  venueShortName,
  submitButtonText = 'Save',
}) {
  const [isSubmitDisabled, disableSubmit] = useState(false);

  const handleSubmit = (key) => {
    if (isSubmitDisabled) return;
    onSubmit(key);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <FormContainer
          {...props}
          onCancel={onCancel}
          isSubmitDisabled={isSubmitDisabled}
          disableSubmit={disableSubmit}
          submitButtonText={submitButtonText}
          initialPermissions={initialPermissions}
          venueShortName={venueShortName}
        >
          {children}
        </FormContainer>
      )}
    </Formik>
  );
}

function HealthStatus({
  isValidCredentials,
  hasIpAllowlist,
  hasBlockTradeScope,
  hasBalancePermission,
  hasAvailableBalances,
  hasTradeScope,
}) {
  const isMarginFeatureEnabled = useMarketIntegrityMarginFeatureFlag();

  if (!isMarginFeatureEnabled) return null;

  return (
    <PermissionContainer data-testid="permissions">
      <Permission
        isGranted={isValidCredentials}
        label="Valid Credentials"
        showLabel={true}
      />
      <Permission
        isGranted={hasIpAllowlist}
        label="IP Whitelist"
        showLabel={true}
      />
      <Permission
        isGranted={hasBlockTradeScope}
        label="Block Trade Scope"
        showLabel={true}
      />
      {isMarginFeatureEnabled && (
        <>
          <Permission
            isGranted={hasBalancePermission}
            label="Balance Scope"
            showLabel={true}
            showWarningIfDenied={true}
          />
          <Permission
            isGranted={hasAvailableBalances}
            label="Available Balance"
            showLabel={true}
            showWarningIfDenied={true}
          />
        </>
      )}
      <Permission
        isGranted={hasTradeScope}
        label="Trade Scope"
        showLabel
        showWarningIfDenied
      />
    </PermissionContainer>
  );
}

const PermissionContainer = styled.div`
  border: 1px solid #2c3e57;
  border-radius: 3px;
  background: rgb(12, 36, 65, 0.5);
  padding: 8px;
  margin-top: 16px;
`;

const Permission = styled(BasePermission)`
  // icon
  span:first-child {
    border: none;
    border-radius: 0;
    background: none;
  }
  // label
  span {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.7;
  }
`;
