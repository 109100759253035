import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { MoreVert, ReportProblemOutlined } from '@material-ui/icons';
import ParadigmMenu from '../../../material/menu';
import ParadigmConfirmModal from '../../../material/confirmation-modal';

const TableAction = ({ apiKey, source }) => {
  const [anchor, setAnchor] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState(false);

  const openMenu = ({ currentTarget }) => setAnchor(currentTarget);
  const closeMenu = () => setAnchor(null);
  const options = [
    {
      title: 'Delete',
      ...(!apiKey.active && { onClick: () => setConfirmation(true) }),
    },
  ];
  return (
    <>
      <IconButton align="right" style={{ color: 'white' }} onClick={openMenu}>
        <MoreVert fontSize="small" />
      </IconButton>
      <ParadigmMenu options={options} anchor={anchor} onClose={closeMenu} />
      {confirmation && (
        <ParadigmConfirmModal
          icon={<ReportProblemOutlined color="error" />}
          title="Removing Wallet Address"
          message={`Removing  Wallet Address ${apiKey.name}`}
          onReject={() => setConfirmation(false)}
          onConfirm={() => source.removeApiKey(apiKey)}
        />
      )}
    </>
  );
};

export default TableAction;
