import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import useToggle from '../../../../hooks/use-toggle';
import ParadigmModal from '../../../../material/modal';
import deskContext from '../../../../stores/desk-store';
import AddMemberForm from './add-member-form';
import { Link } from '@material-ui/core';

const TITLES = ['Add Member', 'Select Trade Channels'];

const AddMember = ({ children }) => {
  const [open, toggle] = useToggle(false);
  const [step, setStep] = React.useState(0);
  const { addMember } = React.useContext(deskContext);

  const addMemberSchema = Yup.object().shape({
    user: Yup.object().required().nullable(),
    role: Yup.string().required(),
  });

  const closeModal = () => {
    setStep(0);
    toggle();
  };

  const handleSubmit = (member) => addMember(member).finally(closeModal);

  return (
    <>
      <Link
        underline="none"
        variant="body1"
        color="primary"
        style={{ lineHeight: '44px' }}
        onClick={toggle}
      >
        Add Existing User to Desk
      </Link>
      {open && (
        <ParadigmModal title={TITLES[step]} onClose={closeModal} noScroll>
          <Formik
            initialValues={{ user: null, role: 'TR' }}
            onSubmit={handleSubmit}
            validationSchema={addMemberSchema}
          >
            {(props) => (
              <AddMemberForm
                {...props}
                onClose={closeModal}
                step={step}
                nextStep={() => setStep((s) => s + 1)}
              />
            )}
          </Formik>
        </ParadigmModal>
      )}
    </>
  );
};

export default React.memo(AddMember);
