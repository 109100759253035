import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ParadigmButton from '../../../material/button';

const ExportSuccessModal = ({ closeModal, logType }) => {
  const isDevice = useMediaQuery('(max-width:600px)');

  return (
    <Dialog
      open
      fullScreen={isDevice}
      PaperProps={{ style: { maxWidth: 450 } }}
    >
      <Box
        height={40}
        bgcolor="rgba(0, 0, 0, 0.2)"
        color="#89D900"
        position="absolute"
        width="100%"
      >
        <Box
          bgcolor="#0F2038"
          width={40}
          height={40}
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          left="calc(50% - 20px)"
          top="calc(100% - 20px)"
        >
          <CheckIcon />
        </Box>
      </Box>
      <Box bgcolor="#0F2038" px="15%" pb={3} pt="40px">
        <Box mt={4} textAlign="center" fontSize={20} fontWeight="bold">
          We've received your request
        </Box>
        <Box my={2} textAlign="center" fontSize={14}>
          Selected {logType} logs will be emailed to the specified address once
          they're ready.
        </Box>
        <Grid container justifyContent="center">
          <Grid item>
            <ParadigmButton text="Close" onClick={closeModal} />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ExportSuccessModal;
