import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { map, sample } from 'lodash';
import React from 'react';
import ParadigmCheckbox from '../../../material/checkbox';
import { ROLES } from '../../../resources/config';

const useStyles = makeStyles((theme) => ({
  permissionTable: {
    width: 760,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    '& > div': {
      padding: '4px 4px 16px',
    },
  },
  left: {
    width: 150,
    backgroundColor: theme.palette.background.paperContrast,
  },
  headerRow: {
    height: 70,
  },
}));

const PermissionsTable = (props) => {
  const classes = useStyles(props);
  const center = {
    alignItems: 'center',
    justifyContent: 'center',
    container: true,
  };
  return (
    <Grid container className={classes.permissionTable}>
      <Grid item container direction="column" className={classes.left}>
        <Grid item {...center} className={classes.headerRow}>
          <Typography color="textSecondary">User Management</Typography>
        </Grid>
        {map(ROLES, (role) => (
          <Grid item xs {...center} key={role.name}>
            <Typography>{role.name}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item container direction="column" xs>
        <Grid item container className={classes.headerRow}>
          {map(sample(ROLES).permissions, (permission) => (
            <Grid item xs {...center} key={permission.name}>
              <Typography color="textSecondary">{permission.name}</Typography>
            </Grid>
          ))}
        </Grid>
        {map(ROLES, (role) => (
          <Grid item xs container key={role.name}>
            {map(role.permissions, (permission) => (
              <Grid item xs {...center} key={permission.name}>
                <ParadigmCheckbox
                  color="primary"
                  rounded
                  checked={Boolean(permission.value)}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default PermissionsTable;
