import React from 'react';
import { Formik } from 'formik';
import useToggle from '../../../../hooks/use-toggle';
import ParadigmModal from '../../../../material/modal';
import entityContext from '../../../../stores/entities-store';
import EditEntityForm from '../edit-entities/edit-entity-form';
import { entitySchema } from '../edit-entities/edit-entity';

export const INIT_ENTITY = {
  name: '',
  mailing_address: {
    country: 'SG',
    address1: '',
    address2: '',
    city: '',
    province: '',
    zip_code: '',
  },
};

const AddEntity = ({ children }) => {
  const [open, toggle] = useToggle(false);
  const { addEntity } = React.useContext(entityContext);

  const closeModal = () => {
    toggle();
  };

  const handleSubmit = (entity) => {
    return addEntity(entity).finally(closeModal);
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: toggle,
      })}
      {open && (
        <ParadigmModal title="Add Entity" onClose={closeModal} noScroll>
          <Formik
            initialValues={INIT_ENTITY}
            onSubmit={handleSubmit}
            validationSchema={entitySchema}
          >
            {(props) => <EditEntityForm {...props} onClose={closeModal} />}
          </Formik>
        </ParadigmModal>
      )}
    </>
  );
};

export default React.memo(AddEntity);
