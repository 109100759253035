import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Observer } from 'mobx-react-lite';
import { Field } from 'formik';
import ParadigmInput from '../../../../material/input';
import entitiesContext from '../../../../stores/entities-store';

const EditKeyFormFields = ({ apiKey }) => {
  const entitiesStore = React.useContext(entitiesContext);

  return (
    <Observer>
      {() => (
        <>
          <Field
            name="name"
            label="Name"
            component={ParadigmInput}
            required
            autoFocus
          />
          <Field
            name="entity"
            label="Entity"
            component={ParadigmInput}
            select
            required
          >
            {entitiesStore.entitiesEnabledArray.map((entity) => (
              <MenuItem key={entity.id} value={entity}>
                {entity.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            name="access_key"
            label="API Key"
            component={ParadigmInput}
            placeholder={apiKey.formattedAccessKey}
          />
          <Field
            name="access_secret"
            label="API Secret"
            component={ParadigmInput}
            placeholder={apiKey.formattedAccessSecret}
          />
        </>
      )}
    </Observer>
  );
};

export default EditKeyFormFields;
