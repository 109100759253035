import { action, observable, makeObservable } from 'mobx';
import { CurrencyFormatter as formatter } from '../../../utils/common';

export default class SpotCredit {
  creditLimit;
  tradingLimit;

  constructor(config) {
    makeObservable(this, {
      creditLimit: observable,
      tradingLimit: observable,
      setLimits: action,
    });

    this.creditLimit = config.credit_limit;
    this.tradingLimit = config.trading_limit;
  }

  setLimits = (limits) => {
    this.creditLimit = limits.credit_limit;
    this.tradingLimit = limits.trading_limit;
  };

  get formattedCreditLimit() {
    return formatter.full({ amount: this.creditLimit });
  }

  get formattedTradingLimit() {
    return formatter.full({ amount: this.tradingLimit });
  }
}
