import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import PageSubTitle from '../../../components/page-sub-title/page-sub-title';
import AddKey from '../account/components/add-keys/add-keys';
import authContext from '../../../stores/auth-store';
import KeysTable from '../account/components/keys-table/keys-table';

const useStyles = makeStyles((theme) => ({
  left: {
    width: 130,
  },
  table: {
    margin: `${theme.spacing(1)}px -40px 0`,
  },
}));

const BybitAccount = (props) => {
  const classes = useStyles();
  const auth = React.useContext(authContext);
  const { source } = props;

  const AddApiKeyIcon = React.useMemo(
    () => (
      <Tooltip title="Add new API credentials" enterDelay={300}>
        <IconButton color="primary">
          <Add fontSize="small" />
        </IconButton>
      </Tooltip>
    ),
    [],
  );

  return (
    <Observer>
      {() => (
        <Grid container direction="column">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <PageSubTitle text="API CREDENTIALS" />
            </Grid>
            {auth.isAdmin && (
              <Grid item>
                <Grid container>
                  <Grid item>
                    <AddKey source={source} children={AddApiKeyIcon} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.table}>
            <KeysTable source={source} />
          </Grid>
        </Grid>
      )}
    </Observer>
  );
};

export default BybitAccount;
