import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Menu } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import logo from '../../assets/logo.png';
import TelegramIcon from '../../assets/TelegramIcon.js';
import authContext from '../../stores/auth-store';

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: 226, // 24 header padding
    },
  },
  toolbar: {
    height: 75,
    [theme.breakpoints.only('xs')]: {
      height: 55,
    },
  },
  input: {
    maxWidth: 400,
  },
  logoText: {
    fontSize: '1rem',
    marginLeft: 12,
    fontWeight: 500,
  },
  telegramIcon: {
    position: 'relative',
    top: 3,
    right: 4,
  },
}));

const Header = (props) => {
  const classes = useStyles(props);
  const auth = React.useContext(authContext);
  const { toggle } = props;
  return (
    <AppBar position="relative" color="inherit" data-testid="header">
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto" className={classes.logoWrapper}>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <Hidden smUp implementation="css">
                  <IconButton
                    color="primary"
                    onClick={toggle}
                    data-testid="menu"
                  >
                    <Menu />
                  </IconButton>
                </Hidden>
              </Grid>
              <Grid item container alignItems="center">
                <img src={logo} alt="logo" width={40} height={40} />
                <Typography variant="h1" className={classes.logoText}>
                  Paradigm
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <span className={classes.telegramIcon}>
                  <TelegramIcon />
                </span>
                Help? Reach us on{' '}
                <Link
                  href="https://t.me/+KItTM8HI4O1kMWEx"
                  color="primary"
                  underline="none"
                  variant="body1"
                  data-testid="help"
                  target="_blank"
                >
                  Telegram
                </Link>
              </Grid>
              <Grid item onClick={() => auth.logOut()}>
                <Link
                  underline="none"
                  variant="body1"
                  color="textPrimary"
                  data-testid="logout"
                >
                  Logout
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default Header;
