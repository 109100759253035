import api from './_api';

const getInfoApi = (venue) => api.get(`/trading-desk/${venue}/info/`);

const updateRfqApi = ({ id, ...data }) =>
  api.put(`trading-desk/product-configs/${id}/`, data);

const updateWalletAddressApi = ({ id, ...data }) =>
  api.patch(`trading-desk/api-credentials/${id}/`, data);

const addWalletAddressApi = (data) =>
  api.post('trading-desk/api-credentials/', data);

const removeWalletAddressApi = (id) =>
  api.delete(`trading-desk/api-credentials/${id}/`);

export {
  getInfoApi,
  updateRfqApi,
  updateWalletAddressApi,
  addWalletAddressApi,
  removeWalletAddressApi,
};
